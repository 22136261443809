import * as React from "react";
import { Timeline as VisTimeline } from "vis-timeline/standalone";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";

const CustomeTimeline = (props) => {
  const { items, groups, options } = props;
  const [element, setElement] = React.useState(null);
  const [timeline, setTimeline] = React.useState(null);
  React.useEffect(() => {
    if (element) {
      if (timeline) {
        timeline.destroy();
      }
      const tl = new VisTimeline(element, items, groups, options);
      props.saveTimeline(tl);
  
      let isTimeChange = false;
      tl.on("timechanged", (properties) => {
        isTimeChange = true
        props.timechangeHandler(properties);
        setTimeout(() => {
          isTimeChange = false;
        }, 0);
      });
  
      tl.on("click", (properties) => {
        if (isTimeChange) {
          return;
        }
        props.clickHandler(properties);
      });
  
      tl.on("select", () => {
        if (isTimeChange) {
          return;
        }
      
      });

      setTimeline(tl);
  
      // return () => {
      //   tl.destroy();
      // };
    }
  }, [element, items, groups, options]);
  
  


  React.useEffect(() => {
    if (timeline && groups) {
      timeline.setGroups(groups);
    }
  }, [timeline, groups]);

  React.useEffect(() => {
    if (timeline && items) {
      timeline.setItems(items);
    }
  }, [timeline, items]);

  React.useEffect(() => {
    if (timeline && options) {
      timeline.setOptions(options);
    }
  }, [timeline, options]);

  return <div ref={(r) => setElement(r)}/>;
};

export default CustomeTimeline;
