import React from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import {
  markerEnable,
  applyDevicesFilter,
  setTrackId,
  resetBounds,
  updateDeviceVisible,
} from "./../../Actions/Devices";
import Icon from "@mui/material/Icon";
import "./MapFilters.scss";
import sheet from "./icons/sheet.svg";
import { ReactComponent as Moving } from "./icons/moving.svg";
import { ReactComponent as Towing } from "./icons/towing.svg";
import { ReactComponent as Overspeed } from "./icons/overspeed.svg";
import { ReactComponent as Idling } from "./icons/idling.svg";
import { ReactComponent as LowBattery } from "./icons/low-battery.svg";
import { ReactComponent as Geofence } from "./icons/geofence.svg";
import { ReactComponent as Parking } from "./icons/parking.svg";
import { ReactComponent as HarshBreak } from "./icons/harsh-break.svg";
import { ReactComponent as Online } from "./icons/online.svg";
import { ReactComponent as Offline } from "./icons/offline.svg";
import { Callout, CalloutPOI } from "./../Features/Callout";
import OptionMenu from "./MapOptionMenu";
import OptionGroupMenu from "./MapOptionGroupMenu";
import { checkPrivileges } from "../../Helpers";
import Tooltip from "@mui/material/Tooltip";
import Style from "style-it";
import PaginationMenu from "./PaginationMenu";
import TrafficMenu from "./TrafficMenu";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { ReactComponent as PowerCut } from "../../assets/monitoring/powerCut.svg";
import { Trans as Translate, withTranslation } from "react-i18next";
import AreaMenu from "./AreaMenu";
import Scrollbar from "react-scrollbars-custom";
import withTranslationWrapper from "../../HOC/withTranslation";

const mapStateToProps = (state, ownProps) => {
  return {
    notificationSettings: state.notificationSettings,
    trackId: state.trackId,
  };
};

let notificationSettings = [];

class MapFilter1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openCallout = this.openCallout.bind(this);
    this.markerEnabled = this.markerEnabled.bind(this);
    this.markerDisabled = this.markerDisabled.bind(this);
    this.tiggerEvent = this.tiggerEvent.bind(this);
    this.setCallout = this.setCallout.bind(this);
    this.state = {
      callout: 0,
      setCallout: this.setCallout.bind(this),
    };
  }

  markerEnabled() {
    this.props.dispatch(markerEnable({ enable: true }));
  }

  markerDisabled() {
    this.props.dispatch(markerEnable({ enable: false }));
  }

  componentWillReceiveProps(n) {
    if (n.poiForm && n.poiForm.description && this.state.callout !== 2) {
      if (!isEqual(n.poiForm.description, this.props.poiForm.description)) {
        this.setState({
          callout: 2,
        });
      }
    }
  }

  tiggerEvent(event) {
    return event || false;
  }

  setCallout(id) {
    this.setState({
      callout: id !== this.state.callout ? id : 0,
    });
  }

  openCallout(id) {
    this.setCallout(id);
  }

  addShape = (type) => {
    let element;
    if (type === "polygon") {
      element = document.getElementById("addMeasurePolygon");
    } else if (type === "polyline") {
      element = document.getElementById("addMeasurePolyline");
    }
    if (element) {
      element.click();
    }
  };

  render() {
    const emptyLinks = null;
    const buttons = (
      <>
        <li className="has-menu">
          <OptionGroupMenu themecolors={this.props.themecolors} />
        </li>
        <li className="has-menu">
          <PaginationMenu
            translate={this.props.translate}
            updateLayer={this.props.updateLayer}
            themecolors={this.props.themecolors}
          />
        </li>
        {checkPrivileges("trafficLayer") ? (
          <TrafficMenu
            themecolors={this.props.themecolors}
            selectedMap={
              this.props && this.props.mapLayer && this.props.mapLayer.id
            }
          />
        ) : null}
      </>
    );
    return (
      <ul className="map-filters-bar">
        {!this.props.disableBottomLeftFilters && (
          <>
            {buttons}
            <li className="has-menu">
              <AreaMenu addShape={this.addShape} />
            </li>
          </>
        )}

        {!this.props.disableBottomRightFilters && (
          <>
            <li className="has-callout">
              <a
                href={emptyLinks}
                title={this.props.translate("sharedGeofence")}
                onClick={this.openCallout.bind(this, 1)}
              >
                <span>
                  <svg fill="currentColor" width={24} height={24}>
                    <use xlinkHref={`${sheet}#icon-geofence`} />
                  </svg>
                </span>
              </a>
              {this.state.callout === 1 ? (
                <Callout
                  key={1}
                  className={this.state.callout === 1 ? "callout-open" : ""}
                  {...this.props}
                  {...this.state}
                  title={this.props.translate("sharedGeofence")}
                  tiggerEvent={this.tiggerEvent}
                />
              ) : null}
            </li>
            {!this.props.disablePOIFilters && (
              <li className="has-callout">
                <a
                  href={emptyLinks}
                  title={this.props.translate("POI")}
                  onClick={this.openCallout.bind(this, 2)}
                >
                  <PinDropIcon />
                </a>
                {this.state.callout === 2 ? (
                  <CalloutPOI
                    key={2}
                    className={this.state.callout === 2 ? "callout-open" : ""}
                    {...this.props}
                    {...this.state}
                    title={this.props.translate("POI")}
                    tiggerEvent={this.tiggerEvent}
                  />
                ) : null}
              </li>
            )}
          </>
        )}
      </ul>
    );
  }
}

class MapFilter2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: true };
    this.showSelectedUnits = this.showSelectedUnits.bind(this);
  }

  showSelectedUnits(data) {
    if (this.props.trackId) {
      this.props.dispatch(
        updateDeviceVisible({ checked: false, id: this.props.trackId })
      );
      this.props.dispatch(resetBounds());
      this.props.dispatch(setTrackId(0));

      setTimeout(() => {
        this.applyBound(data);
      }, 0);
    } else {
      this.applyBound(data);
    }
  }

  applyBound = (data) => {
    const list = [...this.props.filterList];
    let newList = [];
    if (list.includes(data)) {
      list.map((l) => (l !== data ? newList.push(l) : null));
    } else {
      newList = [...list].concat(data);
    }
    this.props.dispatch(applyDevicesFilter(newList));
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.logInUser &&
      nextProps.logInUser.attributes &&
      nextProps.logInUser.attributes.notificationSettings
    ) {
      notificationSettings = [];
      nextProps.notificationSettings.map((o) => {
        if (nextProps.logInUser.attributes.notificationSettings) {
          nextProps.logInUser.attributes.notificationSettings.map((n) => {
            if (o.id === n.id) {
              o.checked = n.checked;
              notificationSettings.push(o);
            }
            return null;
          });
        }

        return null;
      });
    } else {
      notificationSettings = nextProps.notificationSettings;
    }
  }

  render() {
    const emptyLinks = null;

    return (
      <Style>
        {`
          .material-icons {vertical-align: middle; color: inherit}
          .map-filters-bar-vertical .filter-actions-list li.active > a {
            background-color: ${this.props.themecolors.menuActiveBackground}55;
            color: ${this.props.themecolors.menuActiveColor}
          }
          .map-filters-bar-vertical .filter-actions-list li > a {
            background-color: ${this.props.themecolors.menuActiveBackground}1A;
            color: ${this.props.themecolors.menuActiveColor}
          }
          .setting-filter-menu {
            color: ${this.props.themecolors.themeInverse};
            background-color: ${this.props.themecolors.themeDarkColor};
          }
					.sub-menu .active a {
						color: ${this.props.themecolors.themeDarkColor}
					},
					.sub-menu label:hover{
						color: ${this.props.themecolors.themeDarkColor}
					},
					.sub-menu a:hover {
						color: ${this.props.themecolors.themeDarkColor}
          }
          .map-filters-bar a {
            color: ${this.props.themecolors.menuActiveColor}
          }
          .map-filters-bar {
            background-color: ${this.props.themecolors.menuActiveBackground};
          }
				`}

        <Scrollbar disableTracksWidthCompensation>
          <div
            className={
              "map-filters-bar-vertical clearfix " +
              (this.state.open ? "collapse-open" : "")
            }
          >
            {checkPrivileges("device") && (
              <div className="map-actions-left">
                <span className="action-heading">
                  <Translate i18nKey="Filters" />
                </span>
                <ul className="filter-actions-list">
                  {notificationSettings.map((item, index) => {
                    if (item.checked) {
                      const key = item.notificationKey;
                      return (
                        <DrawIcon
                          key={index}
                          item={item}
                          showSelectedUnits={this.showSelectedUnits}
                          emptyLinks={emptyLinks}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          isActive={this.props.filterList.includes(key)}
                          count={
                            this.props.allNotifications[key] &&
                            this.props.allNotifications[key].length
                              ? this.props.allNotifications[key].length
                              : 0
                          }
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
                <MapFilters {...this.props} />
                <OptionMenu themecolors={this.props.themecolors} />
              </div>
            )}
          </div>
        </Scrollbar>
      </Style>
    );
  }
}

class DrawIcon extends React.PureComponent {
  render() {
    const { item, themecolors, emptyLinks, count, isActive } = this.props;
    const customIcons = {
      deviceMoving: <Moving width={20} height={20} />,
      overspeed: <Overspeed width={20} height={20} />,
      towing: <Towing width={20} height={20} />,
      lowBattery: <LowBattery width={20} height={20} />,
      geofence: <Geofence width={20} height={20} />,
      idling: <Idling width={20} height={20} />,
      parking: <Parking width={20} height={20} />,
      harshbreak: <HarshBreak width={20} height={20} />,
      statusOnline: <Online width={20} height={20} />,
      offline: <Offline width={20} height={20} />,
    };
    return (
      <li className={isActive ? "active" : ""}>
        <a
          href={emptyLinks}
          title=""
          className={item.class}
          onClick={() => this.props.showSelectedUnits(item.notificationKey)}
        >
          <span style={{ fontSize: 10, fontWeight: "normal" }}>
            {this.props.translate(item.label)}
          </span>
          <span className={"rel-icons " + item.notificationKey}>
            {!customIcons[item.icon] ? (
              <svg fill="currentColor" width={2} height={2}>
                <use xlinkHref={`${sheet}#icon-${item.icon}`} />
              </svg>
            ) : (
              customIcons[item.icon]
            )}
          </span>
          <span
            className="counter"
            style={{
              color: themecolors.themeInverse,
            }}
          >
            {count}
          </span>
        </a>
      </li>
    );
  }
}

export const MapFilters = MapFilter1;
export const MapFiltersVerticle = connect(mapStateToProps)(MapFilter2);
