import React from 'react';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment-timezone';
import withTranslationWrapper from '../../HOC/withTranslation';
import { useSelector } from 'react-redux';

const StyledPicker = styled(({ component: Component, disabled, ...other }) => (
  <Component {...other} disabled={disabled} />
))(({ themecolors, disabled }) => ({
  '& .MuiInputLabel-root': {
    marginTop: '-8px',
    fontSize: '12px',
    color: disabled ? themecolors?.textColor : themecolors?.textColor || 'black',
    '&.Mui-focused': {
      color: disabled ? themecolors?.textColor : themecolors?.textColor || 'black',
      marginTop: '0',
    },
    '&.Mui-error': {
      color: disabled ? themecolors?.textColor : themecolors?.textColor || '#999',
    },
    '&.MuiInputLabel-shrink': {
      marginTop: '0',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    cursor: disabled ? 'not-allowed' : 'text',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: disabled ? themecolors?.textColor : themecolors?.textColor || '#999',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: disabled ? themecolors?.textColor : themecolors?.textColor || 'black',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '12px',
    padding: '5px 14px',
    minHeight: '26px',
    height: 'auto',
    fontWeight: 'normal',
    color: 'black',
  },
  '& .MuiOutlinedInput-multiline': {
    padding: '0',
    fontSize: '14px',
  },
  '& .MuiSelect-icon': {
    color: '#999',
  },
  '& .MuiPaper-root': {
    // backgroundColor: themecolors['500'],
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    color: disabled ? themecolors?.textColor : themecolors?.textColor || '#999',
  },
  // Icon
  '& .MuiIconButton-root': {
    color: disabled ? themecolors?.textColor : themecolors?.textColor || '#999',
  },
  '& .MuiSvgIcon-root': {
    color: disabled ? themecolors?.textColor : themecolors?.textColor || '#999',
  },
}));

function CustomDatePicker(props) {
  const { dateAndTime, format, error, views, label, value, onChange, fullWidth, helperText, minDate, maxDate, disabled } = props;
  const themecolors = useSelector((state) => state.themeColors);

  const PickerComponent = dateAndTime ? DateTimePicker : DatePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledPicker
        component={PickerComponent}
        inputFormat={format}
        error={error}
        disabled={disabled}
        margin="dense"
        views={views}
        label={props.required ? `${label} *` : label}
        themecolors={themecolors}
        variant="outlined"
        value={moment(value)}
        onChange={onChange}
        sx={{ width: fullWidth ? '100%' : '', marginTop: '8px' }}
        helperText={helperText}
        minDate={minDate ? moment(minDate) : null}
        maxDate={maxDate ? moment(maxDate) : null}
      />
    </LocalizationProvider>
  );
}

export default withTranslationWrapper(CustomDatePicker);
