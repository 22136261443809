import React, { Component } from 'react'
import Button1 from './Button'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

export default class ConfirmDialoag extends Component {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.state = {
      open: true
    }
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
      this.setState({ open: false })
    }
  }

  handleOk() {
    if (this.props.onOk) {
      this.props.onOk()
      this.setState({ open: false })
    }
  }
  handleDownload() {
    if (this.props?.onDownload) {
      this.props.onDownload()
      this.setState({ open: false })
    }
  }
  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby='confirmation-dialog-title'
        open={this.state.open}
      >
        {this.props.title && (
          <DialogTitle id='confirmation-dialog-title'>
            {this.props.title}
          </DialogTitle>
        )}
        <DialogContent>{this.props.children}</DialogContent>
        <DialogActions>
          <Button1 onClick={this.handleCancel} className="button-white">
            {this.props.CancelText || 'Cancel'}
          </Button1>
          {
            this.props.isDownload && <Button1 onClick={this.handleDownload} style={{ backgroundColor: "#00C179" }}>
              Download QR
            </Button1>
          }

          <Button1
            disabled={this.props.disabled}
            onClick={this.handleOk}
            color='primary'
            style={{ minWidth: 60 }}
          >
            {this.props.OkText || 'Ok'}
          </Button1>
        </DialogActions>
      </Dialog>
    )
  }
}
