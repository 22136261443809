import React, { Component } from 'react'
import './index.scss'
import isEqual from 'react-fast-compare'
import { DevicesList, ListRowAlter as MonitoringListRow } from './../../Components/Devices'
import { ListRow as DriverListRow, DriversShortList } from './../../Components/Drivers/shortList'
import { ListRow as TrailerListRow, TrailerShortList } from './../../Components/Trailer/shortList'
import { ReactComponent as VehicleIcon } from './../../assets/nav/vehicle.svg'
import { ReactComponent as DriverIcon } from './../../assets/monitoring/driver.svg'
import { ReactComponent as IgnitionIcon } from '../../assets/monitoring/idling.svg'
import { JobsShortList } from './../../Components/Jobs/shortList'
import { Link } from 'react-router-dom'
import {
  UsersShortList,
  UsersShortList2
} from './../../Components/Users/shortList'
import { ListRow as VehicleListRow, VehicleShortList } from './../../Components/Vehicles/shortList'
import GroupsShortList from './../../Components/Groups/shortList'
import { searchvehicle } from '../../Actions/Vehicles'
import Loader from './../Loader'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SortMenu from './Components/SortMenu'
import { ReactComponent as CollapseIcon } from '../../assets/monitoring/collapse-icon.svg';
import {
  toggleAllDevices,
  sortDevices,
  searchDevices,
  searchcalendar,
  sortcalendars,
  fetchMoreDevices,
  //applyGroupByFilter,
  resetFilters,
  sortDeviceTypes
} from './../../Actions/Devices'
import { sortDrivers, searchDriver } from './../../Actions/Drivers'
import { sortJobs, searchJob } from './../../Actions/Jobs'
import { sortUsers, searchUser } from './../../Actions/Users'
import Icon from '@mui/material/Icon'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Button from './../../Components/common/Button'
import TextField from './../../Components/common/TextField'
import GeofenceSidebar from './Components/Geofences'
import TemplateGeneratorSidebar from './Components/TemplateGenerator'
import ReportsSidebar from './Components/Reports'
import ReportsHistorySidebar from './Components/ReportsHistory'
import MessagesSidebar from './Components/Messages'
import CommandsSidebar from './Components/Commands'
import InvoicesSidebar from './Components/Invoices'
import DeliverNotesSidebar from "./Components/DeliverNotes"
import MissedOpportunitySidebar from "./Components/MissedOpportunity"
import GarageSidebar from './Components/Garage'
import AreaSidebar from './Components/Areas'
import ServicesSidebar from './Components/Services'
import ModelSidebar from './Components/ModelSidebar'
import CostingSidebar from './Components/CostingSidebar'
import MaintenanceSidebar from './Components/Maintenance'
import ParametersSidebar from './Components/Parameters'
import TracksSidebar from './Components/Tracks'
import TimemachineSidebar from './Components/Timemachine'
import EventsSidebar from './Components/Events'
import NotificationSidebar from './Components/Notification'
import UnitsSidebar from './Components/Units'
import GroupsSidebar from './Components/Groups'
import InfiniteScroll from 'react-infinite-scroll-component'
import AppsIcon from '@mui/icons-material/Apps'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { checkPrivileges } from '../../Helpers'
import { ReactComponent as ResetIcon } from '../../assets/monitoring/reset.svg'
import { ReactComponent as PowerCutIcon } from '../../assets/monitoring/powerCut.svg'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
} from '@fortawesome/free-solid-svg-icons'
import SearchField from '../../Components/common/SearchField'
import PositionMenu from '../../Components/Maps/PositionMenu'
// import CloseIcon from '../../assets/nav/close.svg'
import Scrollbar from 'react-scrollbars-custom'
// import Draggable from 'react-draggable'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu'
import RecourcesList from './Components/RecourcesList'
import { Trans as Translate} from 'react-i18next';
import ItemGroupMenu from '../../Components/Maps/ItemGroupMenu'
import withTranslationWrapper from '../../HOC/withTranslation'

library.add([
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle
])

function Wrapper (props) {
  // if (props.type === true) {
    return props.children
  // } else {
  //   return (<>
  //     <Draggable axis='x' handle='.sidebar-dragicon' bounds='parent'>
  //       {props.children}
  //     </Draggable>
  //   </>
  //   )
  // }
}

class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapse: false,
      view: true
    }
    this.collapseSidebar = this.collapseSidebar.bind(this)
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this)
  }

  collapseSidebar () {
    this.props.sidebarClose()
  }

  collapseSidebarWithButton () {
    this.props.collapseSidebarWithButton()
  }

  componentWillUnmount () {
    if (this.props.location.pathname === '/drivers') {
      this.props.dispatch(searchDriver({ driver: '' }))
    } else if (this.props.location.pathname === '/schedules') {
      this.props.dispatch(searchJob({ job: '' }))
    } else if (this.props.location.pathname === '/calendars') {
      this.props.dispatch(searchcalendar({ calendar: '' }))
    } else if (this.props.location.pathname === '/vehicles' || 
      this.props.location.pathname === '/vehicle') {
      this.props.dispatch(searchvehicle({ vehicle: '' }))
    } else if (
      this.props.location.pathname.indexOf('/users') !== -1 ||
      this.props.location.pathname.indexOf('/users2') !== -1
    ) {
      this.props.dispatch(searchUser({ user: '' }))
    }

    this.setState({ view: false })
  }

  toggleListView = () => {
    if (this.props.location.pathname.indexOf('/monitoring') !== -1) {
      this.setState({
        view: !this.state.view
      })
    }
  }

  render () {
    const emptyLinks = null

    let cls = 'other-page'
    if (this.props.location&&this.props.location.pathname&&this.props.location.pathname.indexOf('/monitoring') !== -1) {
      cls = 'monitoring-page'
    }

    if (this.props.noSidebar) {
      return null
    } else {
      return (<>
        <Wrapper type={this.props.collapse} collapseSidebarWithButton={this.collapseSidebarWithButton}>
          <div
            className={
              'fms-sidebar-wrapper ' +
              cls +
              ' ' +
              this.props.view +
              ' ' +
              (this.state.view ? 'main-list-view' : 'main-thumb-view')
            }
          >
            <SidebarInner
              collapseSidebar={this.collapseSidebar}
              toggleListView={this.toggleListView}
              ListViewSwitch={this.state.view}
              {...this.props}
            />
          </div>
        </Wrapper>
        <div className='sidebar-collapse-button'>
          <a
            href={null}
            onClick={e => this.collapseSidebarWithButton()}
          >
            <CollapseIcon style={{fill:this.props.themecolors.menuActiveBackground}}/>
          </a>
        </div>
        </>
      )
    }
  }
}
export class SidebarInner extends Component {
  constructor (props) {
    super(props)
    this.state = {
      switchView: 'units',
      isPopupVisable: false,
      is_gm_Visable: false,
      newPage: 0,
      isChecked: true,
      menuSettings: [],
      search: '',
      items: Array.from({ length: 20 }),
      hasMore: true
    }

    this.isChecked = this.isChecked.bind(this)
    this.toggleAllDevices = this.toggleAllDevices.bind(this)
    this.searchItem = this.searchItem.bind(this)
    this.addDevices = this.addDevices.bind(this)
    this.switchPageView = this.switchPageView.bind(this)
    this.sortByName = this.sortByName.bind(this)
    this.addGroup = this.addGroup.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
  }

  onCloseModal () {
    this.setState({
      is_gm_Visable: false
    })
  }

  addGroup () {
    this.setState({
      is_gm_Visable: true
    })
  }

  switchView = type => {
    this.props.dispatch(searchDevices({ device: '' }))
    if (type === 'groups') {
      this.props.dispatch(fetchMoreDevices(true, null, 'abc'))
      this.props.fetchMoreItems2()
    } else {
      this.props.dispatch(fetchMoreDevices(true, null))
    }
    this.setState({
      switchView: type,
      isChecked: true,
      sort: 'ASC',
      search: ''
    })
  }

  componentWillMount () {
    if (this.props.viewScreen) {
      this.setState({ newPage: this.props.viewScreen === 'groups' ? 1 : 0 })
    }
    this.setState({
      menuSettings: [
        {
          label: this.props.translate('moving'),
          value: 'Moving',
          icon: (
            <span className='action-item online'>
              <PlayArrowIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: this.props.translate('idling'),
          value: 'Idling',
          icon: (
            <span className='action-item unknown'>
              <IgnitionIcon style={{ width: 18, height: 18 }} fill="currentColor" />
            </span>
          )
        },
        {
          label: this.props.translate('stop'),
          value: 'stop',
          icon: (
            <span className='action-item online'>
              <PauseIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: this.props.translate('towing'),
          value: 'Towing',
          icon: (
            <span className='action-item online'>
              <RvHookupIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: this.props.translate('parking'),
          value: 'statusParking',
          icon: (
            <span className='action-item online'>
              <LocalParkingIcon style={{ fontSize: 18 }}/>
            </span>
          )
        },
        {
          label: this.props.translate('tracker.online'),
          value: 'statusOnline',
          icon: (
            <span className='action-item online'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: this.props.translate('tracker.offline'),
          value: 'statusOffline',
          icon: (
            <span
              className='action-item offline'
              style={{ display: 'block', margin: '0 auto' }}
            >
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: this.props.translate('tracker.gpsNotUpdated'),
          value: 'gpsNotUpdated',
          icon: (
            <span className='action-item not-updated'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: this.props.translate('tracker.notRegisteredYet'),
          value: 'notRegistered',
          icon: (
            <span className='action-item not-registered'>
              <i className='circle'></i>
            </span>
          )
        },
        {
          label: this.props.translate('notification.powerCut'),
          value: 'powerCut',
          icon: (
            <span className='action-item not-registered'>
              <PowerCutIcon fill="#28a745" width={16} height={16} />
            </span>
          )
        }
      ]
    })
  }

  switchPageView (e, newValue) {
    if (newValue === 1) {
      this.props.fetchMoreItems2(1, '')
    }
    this.setState(
      {
        newPage: newValue,
        search: ''
      },
      () => {
        this.props.dispatch(searchDevices({ device: '' }))
      }
    )
  }

  isChecked (value) {
    this.setState({
      isChecked: value
    })
  }

  toggleAllDevices (event) {
    this.props.dispatch(toggleAllDevices({ checked: event.target.checked }))
    this.setState({
      isChecked: event.target.checked
    })
  }

  sortByDeviceType = () => {
    this.props.dispatch(sortDeviceTypes({ sort: this.state.sort || 'DESC' }))
    this.setState({
      sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
    })
  }

  sortByName () {
    if (this.props.location.pathname === '/drivers') {
      this.props.dispatch(sortDrivers({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else if (this.props.location.pathname === '/schedules') {
      this.props.dispatch(sortJobs({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else if (this.props.location.pathname === '/calendars') {
      this.props.dispatch(sortcalendars({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else if (
      this.props.location.pathname === '/users' ||
      this.props.location.pathname === '/addUser'
    ) {
      this.props.dispatch(sortUsers({ sort: this.state.sort || 'DESC' }))
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      this.props.dispatch(sortDevices({ sort: this.state.sort || 'DESC' }))
      this.resetFilters()
      this.setState({
        sort: this.state.sort === 'ASC' ? 'DESC' : 'ASC'
      })
    }
  }

  searchItem (event) {
    if(this.props.resourceList) {
      this.props.searchNestedResources(this.props.loadingItem,event.target.value)
      this.setState({
        search: event.target.value
      })
    }
    else if (this.props.location.pathname.indexOf('/drivers') !== -1) { 
      this.props.searchItems(event)
    }else if (this.props.location.pathname.indexOf('/binders') !== -1) {
      this.props.searchItems(event.target.value)
    } else if (this.props.location.pathname.indexOf('/schedules') !== -1) {
      this.props.searchItems(event.target.value)
    } else if (this.props.location.pathname.indexOf('/units') !== -1) {
      if (this.state.newPage === 0) {
        //this.props.searchItems(event.target.value)
        this.props.dispatch(searchDevices({ device: event.target.value }))
        this.setState({
          search: event.target.value
        })
      } else {
        this.props.searchItems2(event.target.value)
      }
    } else if (
      this.props.location.pathname.indexOf('/calendars') !== -1 ||
      this.props.location.pathname.indexOf('/addUser') !== -1 ||
      this.props.location.pathname.indexOf('/users') !== -1 ||
      this.props.location.pathname.indexOf('/users2') !== -1 ||
      this.props.location.pathname.indexOf('/vehicles') !== -1 ||
      this.props.location.pathname.indexOf('/vehicle') !== -1
    ) {
      this.props.searchItems(event.target.value)
      this.setState({
        search: event.target.value
      })
      //this.props.dispatch(searchUser({ user: event.target.value }));
    } else if (this.props.location.pathname.indexOf('/monitoring') !== -1) {
      if (this.state.switchView === 'groups') {
        this.props.searchItems2(event.target.value, this.state.switchView)
      } else {
        this.props.dispatch(searchDevices({ device: event.target.value }))
      }
      this.setState({
        search: event.target.value
      })
    } else {
      this.props.dispatch(searchDevices({ device: event.target.value }))
      this.setState({
        search: event.target.value
      })
    }
  }
  addDevices () {
    this.setState({
      isPopupVisable: !this.state.isPopupVisable
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) // equals() is your implementation
  }

  selecteItem = data => {
    this.props.collapseSidebar()
    this.props.selecteItem(data)
  }

  editItem = data => {
    this.props.collapseSidebar()
    this.props.editItem(data)
  }

  showWaslDetails =(data)=>{
    this.props.showWaslDetails(data)
  }

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  resetFilters = () => {
    this.props.dispatch(resetFilters())
    this.setState({
      search: ''
    })
  }

  render () {
    const emptyLinks = null
    if (this.props.location&&this.props.location.pathname === '/geofences') {
      return (
        <GeofenceSidebar
          {...this.props}
          translate={this.props.translate}
          collapseSidebar={this.props.collapseSidebar}
        />
      )
    }
    if (this.props.location&&this.props.location.pathname === '/templateGenerator') {
      return (
        <TemplateGeneratorSidebar
          {...this.props}
          collapseSidebar={this.props.collapseSidebar}
        />
      )
    } else if (this.props.location&&this.props.location.pathname === '/monitoring') {
      const endMessage =
        this.props.devices && this.props.devices.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )

      return (
        <aside className='fms-sidebar scrollable-sidebar'>
          {checkPrivileges('device') && (
            <div className='fms-sidebar-monitoring'>
              <div className='sidebar-bg-layer'>
                <div
                  className='section-head section-head-filter'
                  style={{ padding: 0, paddingLeft: 5, paddingLeft: 10 }}
                >
                  <ul className='filter-row'>
                    <li style={{ flex: 1 }} className="sidebar-icon-gap">
                        <SearchField
                          label={this.props.translate('searchUnits')}
                          type='search'
                          placeholder='exp: ID, Name'
                          value={this.state.search || ''}
                          onChange={this.searchItem}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </li>
                  </ul>
                </div>
                <div
                  className='section-head section-head-filter'
                  style={{ padding: 5 }}
                >
                  <ul className='filter-row'>
                    <li
                      style={{
                        paddingRight: 0,
                        paddingLeft: 0
                      }}
                    >
                      <label
                        className='checkbox'
                        style={{ display: 'flex' }}
                      >
                        <input
                          type='checkbox'
                          name='isChecked'
                          checked={this.state.isChecked}
                          onChange={this.toggleAllDevices}
                        />
                        <span className='theme-input-checkbox'>
                          <CheckBoxIcon
                            className='on-active material-icons'
                            style={{fontSize:22, color:'inherit'}}
                          />
                          <CheckBoxOutlineBlankIcon
                            className='on-inactive material-icons'
                            style={{fontSize:22, color:'inherit'}}
                          />
                        </span>
                      </label>
                    </li>
                    <li>{this.props.resourceList ? <Translate i18nKey='groups' /> : <Translate i18nKey='trackers' />}</li>
                    <li style={{ padding: 0, margin: 0, marginRight: 'auto' }}>
                      <ItemGroupMenu
                        individualTitle={<Translate i18nKey='trackers' />}
                        groupTitle={<Translate i18nKey='groups' />}
                        showResources={this.props.showResources}
                        themecolors = {this.props.themecolors}
                      />
                    </li>
                    {this.props.filterList.length ||
                    this.props.filterTagsList.length ||
                    this.props.filterDriversList.length ? (
                      <li style={{ padding: '8px 0 5px' }}>
                        <Button onClick={this.resetFilters}>
                          <ResetIcon
                            width='14'
                            height='14'
                            fill='currentColor'
                            style={{ marginRight: 5 }}
                          />
                          {this.props.translate('resetButton')}
                        </Button>
                      </li>
                    ) : (
                      <>
                        <li style={{ paddingLeft: 0 }}>
                          <Link
                            to={'/monitoring'}
                            title=''
                            onClick={e => this.props.openVehicleTagsModal(e)}
                          >
                            <VehicleIcon
                              fill="currentColor"
                              style={{ width: 20, height: 20 }}
                            />
                          </Link>
                        </li>
                        <li style={{ paddingLeft: 0 }}>
                          <Link
                            to={'/monitoring'}
                            title=''
                            onClick={e => this.props.openDriverTagsModal(e)}
                          >
                            <DriverIcon
                              style={{ width: 20, height: 20 }}
                            />
                          </Link>
                        </li>
                        {this.state.switchView === 'units' && (
                          <li style={{ padding: 0 }}>
                            <SortMenu
                              themecolors={this.props.themecolors}
                              sortByName={this.sortByName}
                              sortByDeviceType={this.sortByDeviceType}
                              translate={this.props.translate}
                            />
                          </li>
                        )}
                        <li
                          style={{
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingRight: 0
                          }}
                        >
                          <a
                            href={emptyLinks}
                            onClick={e => this.props.toggleListView()}
                          >
                            {this.props.ListViewSwitch ? (
                              <AppsIcon className='sidebar-thumbs-icon' />
                            ) : (
                              <FormatListBulletedIcon className='sidebar-thumbs-icon' />
                            )}
                          </a>
                        </li>
                        <li style={{ padding: 0, margin: 0 }}>
                          <PositionMenu
                            themecolors={this.props.themecolors}
                            menuSettings={this.state.menuSettings}
                          />
                        </li>
                      </>
                    )}
                    
                  </ul>
                </div>
                <div className='sidebar-devices-list'>
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    scrollerProps={{
                      renderer: props => {
                        const { elementRef, ...restProps } = props
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id='scrollableDivDevices'
                          />
                        )
                      }
                    }}
                  >
                  {checkPrivileges('device') && this.props.resourceList ? 
                      <RecourcesList
                        disableEdit 
                        ItemChild={MonitoringListRow}
                        ItemChildProps={{collapseSidebar: this.props.collapseSidebar, 
                        deviceId: this.props.deviceId, 
                        monitoringMap: this.props.monitoringMap, 
                        openDeviceSettings: this.props.openDeviceSettings, 
                        showBox: this.props.showBox, 
                        themecolors: this.props.themecolors, 
                        trackId: this.props.trackId, 
                        dispatch: this.props.dispatch,
                        translate: this.props.translate }}
                        exact
                        fromArray={this.props.devices && this.props.devices.data || []}
                        {...{fetchNestedItems: this.props.fetchNestedItems, 
                        resources: this.props.resources, 
                        nestedResources: this.props.nestedResources, 
                        onLinkResource: this.props.onLinkResource, 
                        deleteResource: this.props.deleteResource, 
                        itemType: this.props.itemType, 
                        nextResources: this.props.nextResources, 
                        dispatch: this.props.dispatch, 
                        translate: this.props.translate
                        }}
                        /> : checkPrivileges('device') &&
                      !this.props.resourceList ? (
                      this.props.devices.isFetching ? (
                        <Loader defaultStyle />
                      ) : (
                        <InfiniteScroll
                          dataLength={this.props.devices.data.length}
                          next={() => this.props.dispatch(fetchMoreDevices())}
                          hasMore={this.props.devices.hasNext}
                          loader={<Loader defaultStyle={true} />}
                          scrollableTarget='scrollableDivDevices'
                          endMessage={endMessage}
                        >
                          <DevicesList
                            {...this.state}
                            openDeviceSettings={this.props.openDeviceSettings}
                            isCheckedFn={this.isChecked}
                            collapseSidebar={this.props.collapseSidebar}
                            trackId={this.props.trackId}
                            deviceId={this.props.deviceId}
                            showBox={this.props.showBox}
                            monitoringMap={this.props.monitoringMap}
                            devices={this.props.devices.data}
                            translate={this.props.translate}
                            dispatch={this.props.dispatch}
                            themecolors={this.props.themecolors}
                          />
                        </InfiniteScroll>
                      )
                    ) : null }
                  </Scrollbar>
                </div>
              </div>
            </div>
          )}
        </aside>
      )
    } else if (this.props.location.pathname === '/reports') {
      return <ReportsSidebar {...this.props} />
    }  else if (this.props.location.pathname === '/reportsHistory') {
      return <ReportsHistorySidebar {...this.props} />
    } else if (this.props.location.pathname === '/Dlogs') {
      return <MessagesSidebar {...this.props} />
    }  else if (this.props.location.pathname === '/commands') {
      return <CommandsSidebar {...this.props} />
    } 
     else if (this.props.location.pathname === '/invoice') {
      return <InvoicesSidebar {...this.props} />
    } 
    else if (this.props.location.pathname === '/deliveryNote') {
      return <DeliverNotesSidebar {...this.props} />
    } 
    else if (this.props.location.pathname === '/missed-opportunities') {
      return <MissedOpportunitySidebar {...this.props} />
    }
     else if (this.props.location.pathname === '/garage') {
      return <GarageSidebar {...this.props} />
    }  else if (this.props.location.pathname === '/area') {
      return <AreaSidebar {...this.props} />
    } else if (this.props.location.pathname === '/services') {
      return <ServicesSidebar {...this.props} />
    }  else if (this.props.location.pathname === '/model') {
      return <ModelSidebar  translate={this.props.translate}{...this.props} />
    } else if (this.props.location.pathname === '/costing') {
      return <CostingSidebar {...this.props} />
    } else if (this.props.location.pathname === '/maintenance' || 
      this.props.location.pathname === '/maintenanceCreate' ||
      this.props.location.pathname.indexOf('/maintenanceUpdate') !== -1) {
      return <MaintenanceSidebar {...this.props} />
    } else if (
      this.props.location.pathname === '/notifications' ||
      this.props.location.pathname === '/notificationCreate' ||
      this.props.location.pathname.indexOf('/notificationUpdate') !== -1
    ) {
      return <NotificationSidebar {...this.props} />
    } else if (this.props.location.pathname === '/parameters') {
      return <ParametersSidebar {...this.props} />
    } else if (this.props.location.pathname.indexOf('/tracks') !== -1) {
      return this.state.trackLoading ? (
        <Loader />
      ) : (
        <TracksSidebar {...this.props} />
      )
    } else if (this.props.location.pathname === '/timemachine') {
      return this.state.trackLoading ? (
        <Loader />
      ) : (
        <TimemachineSidebar {...this.props} />
      )
    } else if (
      this.props.location.pathname.indexOf('/users') !== -1 ||
      this.props.location.pathname.indexOf('/addUser') !== -1
    ) {
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <div className='section-head section-head-filter' style={{ paddingTop: 0}}>
            <ul className='filter-row'>
              <li style={{ flex: 1 }} className="sidebar-icon-gap">
                <SearchField
                  label={this.props.translate('searchUsers')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search || ''} 
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {this.props.resourceList ?
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                {this.props.logInUser.administrator &&
                  checkPrivileges('itemgroupCreate') && (
                    <Button
                      size='small'
                      disabled={!this.props.userLimit}
                      onClick={() => this.props.addResource()}
                    >
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
              </li>:
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
              {this.props.logInUser.administrator &&
                checkPrivileges('userCreate') && (
                  <Button
                    size='small'
                    disabled={!this.props.userLimit}
                    onClick={() => this.props.addUser()}
                  >
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}
            </li>}
              {/* <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                {this.props.logInUser.administrator &&
                  checkPrivileges('userCreate') && (
                    <Button
                      size='small'
                      disabled={!this.props.userLimit}
                      onClick={() => this.props.showResources()}
                    >
                      {this.props.translate('Resources')}
                    </Button>
                  )}
              </li> */}
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                <ExportImportMenu
                    fileName = 'users'
                    checkPrivilege = 'userCreate'
                    data = {this.props.users}
                    radius={10}
                    themecolors = {this.props.themecolors}
                  />
              </li>
            </ul>
          </div>
          {this.props.resourceList ? 
          <RecourcesList {...this.props}/> :
          <div className='sidebar-users-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv' />
                  )
                }
              }}
            >
              <UsersShortList2
                {...this.state}
                match={this.props.match}
                editItem={this.editItem}
                removedItem={this.props.removedItem}
                //selecteItem={this.selecteItem}
                collapseSidebar={this.props.collapseSidebar}
                logInUser={this.props.logInUser}
                //selectedItemId={this.props.selectedItemId}
                users={this.props.users}
                translate={this.props.translate}
              />
            </Scrollbar>
          </div>}
        </aside>
      )
    } else if (
      this.props.location.pathname.indexOf('/resources') !== -1 
    ) {
      const endMessage =
        this.props.itemPagination2 && this.props.itemPagination2.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <div className='fms-sidebar-monitoring'>
            <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
              <ul className='filter-row'>
                <li style={{ flex: 1 }} className="sidebar-icon-gap">
                <TextField
                  label={this.props.translate('sharedSearch')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {checkPrivileges('groupCreate') && ( <li 
                  style={{
                    paddingRight: 0,
                    paddingTop: 3,
                    paddingLeft: 0
                  }}>
                <Button
                  size='small'
                  onClick={() => this.props.goAddGroup()}
                >
                  {this.props.translate('sharedCreate')}
                </Button>
              </li>)}
            </ul>
            </div>
            <div className='sidebar-users-list' id='scrollableDiv2'>
              {this.props.itemPagination2 ? (
                <Scrollbar disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                  )
                }
              }}>
                  <GroupsShortList
                    {...this.state}
                    {...this.props}
                    editItem={this.editItem}
                    removedItem={this.props.removedItem}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                  />
                  </Scrollbar>
              ) : null}
            </div>
          </div>
        </aside>
      )
    } else if (this.props.location.pathname.indexOf('/units') !== -1) {
      return (
        <aside className='fms-sidebar'>
          <div className='fms-sidebar-monitoring'>
          {/* {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
              <li className='filter-nav'>
                <a
                  className={
                    'filter-nav-link ' +
                    (!this.props.resourceList ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={e => this.props.showResources(false)}
                >
                  {this.props.translate('trackers')}
                </a>
                <a
                  className={
                    'filter-nav-link ' +
                    (this.props.resourceList ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={e => this.props.showResources(true)}
                >
                  {this.props.translate('groups')}
                </a>
              </li>
            </ul> : null} */}
            <div className='sidebar-bg-layer'>
              {checkPrivileges('device') ? (
                <UnitsSidebar
                  {...this.props}
                  devices={this.props.devices}
                  searchItem={this.searchItem}
                  sortByName={this.sortByName}
                  isChecked={this.isChecked}
                />
              ) : null}

              {/* checkPrivileges('group') && this.state.newPage === 1 && (
                <GroupsSidebar
                  {...this.props}
                  searchItem={this.searchItem}
                  sortByName={this.sortByName}
                  isChecked={this.isChecked}
                />
              ) */}
            </div>
          </div>
        </aside>
      )
    } else if (this.props.location.pathname.indexOf('/drivers') !== -1) {
      
      const drivers =
        this.props.itemPagination && this.props.itemPagination.items
          ? this.props.itemPagination.items.map(row => {
              const attr = Object.keys(row.attributes)
              const others = {}
              attr.map(key => {
                others[key] = row.attributes[key]
                return null
              })
              return {
                name: row.name,
                uniqueId: row.uniqueId,
                ...others
              }
            })
          : []

      const fields = []

      drivers.map(veh => {
        fields.push(...Object.keys(veh))
        return null
      })

      return (
        <aside className='fms-sidebar'>
         <div className='fms-sidebar-monitoring'>
        {/*{checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
          <li className='filter-nav'>
            <a
              className={
                'filter-nav-link ' +
                (!this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(false)}
            >
              {this.props.translate('sharedDrivers')}
            </a>
            <a
              className={
                'filter-nav-link ' +
                (this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(true)}
            >
              {this.props.translate('groups')}
            </a>
          </li>
        </ul> : null} */}



        <aside className='sidebar-bg-layer'>
          {checkPrivileges('driver') && (
            <div
                className='section-head section-head-filter'
                style={{ padding: 0, paddingRight: 5, paddingLeft: 10 }}
              >
              <ul className='filter-row'>
                <li style={{ flex: 1 }} className="sidebar-icon-gap">
                  <SearchField
                    label={this.props.translate('searchDrivers')}
                    type='search'
                    placeholder='exp: ID, Name'
                    // value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
              </ul>
              <ul className='filter-row'>
                <li>{this.props.resourceList ? <Translate i18nKey='groups' /> : <Translate i18nKey='sharedDrivers' />}</li>
                <li style={{ padding: 0, margin: 0, marginRight: 'auto' }}>
                  <ItemGroupMenu
                    individualTitle={<Translate i18nKey='sharedDrivers' />}
                    groupTitle={<Translate i18nKey='groups' />}
                    showResources={this.props.showResources}
                    themecolors = {this.props.themecolors}
                  />
                </li>
                
                {this.props.resourceList ?
                <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
                  {checkPrivileges('itemgroupCreate') && (
                    <Button size='small' fullWidth onClick={() => this.props.addResource()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>:
                <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
                {checkPrivileges('driverCreate') && (
                  <Button size='small' fullWidth onClick={() => this.props.addDriver()}>
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}
                </li>}
                <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  <ExportImportMenu
                    fileName = 'drivers'
                    checkPrivilege = 'driverCreate'
                    data = {drivers}
                    radius={10}
                    themecolors = {this.props.themecolors}
                  />
                </li>
              </ul>
            </div>
          )}
          {this.props.resourceList ? 
          <RecourcesList 
            ItemChild={DriverListRow}
            ItemChildProps={this.props}
            fromArray={this.props.itemPagination.items}
            {...this.props} /> :
          <div className='sidebar-drivers-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                  )
                }
              }}
            >
              {!this.props.isDriverLoad ? checkPrivileges('driver') && this.props.itemPagination 
                && this.props.itemPagination.items && this.props.itemPagination.items.length ? (
                <DriversShortList
                  items={this.props.itemPagination.items}
                  allDriverLocation={this.props.allDriverLocation}
                  match={this.props.match}
                  editItem={this.editItem}
                  ServerSetting={this.props.ServerSetting}
                  removedItem={this.props.removedItem}
                  selecteItem={this.selecteItem}
                  translate={this.props.translate}
                />
              ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}>
                    {this.props.translate('notFound')}
                  </p>:
                  <Loader/>}
            </Scrollbar>
          </div>}
        </aside>
        </div>
      </aside>
      )
    }//start binder
    else if (this.props.location.pathname.indexOf('/binders') !== -1) {
      const trailers =
        this.props.itemPagination && this.props.itemPagination.items
          ? this.props.itemPagination.items.map(row => {
              const attr = Object.keys(row.attributes)
              const others = {}
              attr.map(key => {
                others[key] = row.attributes[key]
                return null
              })
              return {
                name: row.name,
                uniqueId: row.uniqueId,
                ...others
              }
            })
          : []

      const fields = []

      trailers.map(veh => {
        fields.push(...Object.keys(veh))
        return null
      })


      return (
        <aside className='fms-sidebar'>
        <div className='fms-sidebar-monitoring'>
        {/* {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
          <li className='filter-nav'>
            <a
              className={
                'filter-nav-link ' +
                (!this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(false)}
            >
              {this.props.translate('sharedBinder')}
            </a>
            <a
              className={
                'filter-nav-link ' +
                (this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(true)}
            >
              {this.props.translate('groups')}
            </a>
          </li>
        </ul> : null} */}
        <aside className='sidebar-bg-layer'>
          {checkPrivileges('binder') && (
            <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>

              <ul className='filter-row'>
                <li style={{ flex: 1 }} className="sidebar-icon-gap">
                  <SearchField
                    label={this.props.translate('searchBinders')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
              </ul>
              <ul className='filter-row'>
                <li>{this.props.resourceList ? <Translate i18nKey='groups' /> : <Translate i18nKey='sharedBinder' />}</li>
                <li style={{ padding: 0, margin: 0, marginRight: 'auto' }}>
                  <ItemGroupMenu
                    individualTitle={<Translate i18nKey='sharedBinder' />}
                    groupTitle={<Translate i18nKey='groups' />}
                    showResources={this.props.showResources}
                    themecolors = {this.props.themecolors}
                  />
                </li>
                
                
                {this.props.resourceList ? 
                <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                  {checkPrivileges('itemgroupCreate') && (
                    <Button size='small' fullWidth onClick={() => this.props.addResource()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>:
                <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                  {checkPrivileges('binderCreate') && (
                    <Button size='small' fullWidth onClick={() => this.props.addTrailer()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>}
                <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  <ExportImportMenu
                    fileName = 'binders'
                    checkPrivilege = 'binderCreate'
                    data = {trailers}
                    themecolors = {this.props.themecolors}
                    radius={10}
                  />
                </li>
              </ul>

            </div>
          )}
          {this.props.resourceList ? 
          <RecourcesList 
            ItemChild={TrailerListRow}
            ItemChildProps={this.props}
            fromArray={this.props.itemPagination.items}
          {...this.props} /> :
          <div className='sidebar-drivers-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv2' />
                  )
                }
              }}
              
            >{!this.props.isTrailerLoad ? 
              checkPrivileges('binder') && this.props.itemPagination 
              && this.props.itemPagination.items && this.props.itemPagination.items.length? (
              <TrailerShortList
                items={this.props.itemPagination.items}
                allTrailerLocation={this.props.allTrailerLocation}
                match={this.props.match}
                editItem={this.editItem}
                removedItem={this.props.removedItem}
                selecteItem={this.selecteItem}
                translate={this.props.translate}
              />
            ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold' }}>
                  {this.props.translate('notFound')}
                </p>
             : <Loader/>}
            </Scrollbar>
          </div>}
        </aside>
        </div>
        </aside>
      )
    } //end triler
    else if (this.props.location.pathname.indexOf('/schedules') !== -1) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('job') && (
            <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
              <ul className='filter-row'>
                <li style={{ flex: 1 }} className="sidebar-icon-gap">
                  <SearchField
                    label={this.props.translate('searchSchedule')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
                <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  {checkPrivileges('jobCreate') && (
                    <Button size='small' onClick={() => this.props.addJob()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>
              </ul>
            </div>
          )}
          <div className='sidebar-drivers-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableJob' />
                  )
                }
              }}
            >
              {checkPrivileges('job') && this.props.itemPagination ? (
                <InfiniteScroll
                  dataLength={this.props.itemPagination.items.length}
                  next={this.props.fetchMoreItems}
                  hasMore={this.props.itemPagination.hasNext}
                  loader={<Loader defaultStyle={true} />}
                  scrollableTarget='scrollableJob'
                  endMessage={endMessage}
                >
                  <JobsShortList
                    match={this.props.match}
                    editItem={this.editItem}
                    removedItem={this.props.removedItem}
                    selecteItem={this.selecteItem}
                    translate={this.props.translate}
                  />
                </InfiniteScroll>
              ) : null}
            </Scrollbar>
          </div>
        </aside>
      )
    } else if (this.props.location.pathname.indexOf('/users') !== -1) {
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <div className='fms-sidebar-monitoring'>
            <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
              <ul className='filter-row'>
                <li className='sidebar-icon-gap'>
                  <SearchField
                    label={this.props.translate('searchUsers')}
                    type='search'
                    placeholder='exp: ID, Name'
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </li>
                <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  {this.props.logInUser.administrator && (
                    <Button size='small' onClick={() => this.props.addUser()}>
                      {this.props.translate('sharedCreate')}
                    </Button>
                  )}
                </li>
              </ul>
            </div>
            <div className='sidebar-devices-list'>
              <UsersShortList
                {...this.state}
                match={this.props.match}
                editItem={this.editItem}
                removedItem={this.props.removedItem}
                selecteItem={this.selecteItem}
                users={this.props.users}
                collapseSidebar={this.props.collapseSidebar}
                translate={this.props.translate}
              />
            </div>
          </div>
        </aside>
      )
    } else if (this.props.location.pathname.indexOf('/events') !== -1) {
      return (
        <EventsSidebar
          userEvents={this.props.userEvents}
          showNotification={this.props.showNotification}
          showFilterForm={this.props.showFilterForm}
          toggleFilterForm={this.props.toggleFilterForm}
          addRules={this.props.addRules}
          selectedItem={this.props.selectedItem}
          searchItem={this.searchItem}
          sortByName={this.sortByName}
          selecteItem={this.selecteItem}
          onCloseModal={this.onCloseModal}
          collapseSidebar={this.props.collapseSidebar}
          {...this.state}
          match={this.props.match}
          history={this.props.history}
        />
      )
    } else if (this.props.location.pathname.indexOf('/vehicles') !== -1 ||
                this.props.location.pathname.indexOf('/vehicle') !== -1) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
        
      const vehicles =
      this.props.vehicles
          ? this.props.vehicles.map(row => {
              const attr = Object.keys(row.attributes)
              const others = {}
              attr.map(key => {
                others[key] = row.attributes[key]
                return null
              })
              return {
                label: row.label,
                model: row.model,
                vehicleType: row.vehicleType,
                ...others,
                garage: row.garage
              }
            })
          : []

      const fields = []

      vehicles.map(veh => {
        fields.push(...Object.keys(veh))
        return null
      })
      return (






      <aside className='fms-sidebar'>
        <div className='fms-sidebar-monitoring'>
        {/* {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
          <li className='filter-nav'>
            <a
              className={
                'filter-nav-link ' +
                (!this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(false)}
            >
              {this.props.translate('sharedVehicle')}
            </a>
            <a
              className={
                'filter-nav-link ' +
                (this.props.resourceList ? 'active' : '')
              }
              href={emptyLinks}
              onClick={e => this.props.showResources(true)}
            >
              {this.props.translate('groups')}
            </a>
          </li>
        </ul> : null} */}



        <aside className='sidebar-bg-layer'>
          <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
            <ul className='filter-row'>
              <li style={{ flex: 1 }} className="sidebar-icon-gap">
                <SearchField
                  label={this.props.translate('searchVehicles')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
            </ul>
            <ul className='filter-row'>
              <li>{this.props.resourceList ? <Translate i18nKey='groups' /> : <Translate i18nKey='sharedVehicle' />}</li>
              <li style={{ padding: 0, margin: 0, marginRight: 'auto' }}>
                <ItemGroupMenu
                  individualTitle={<Translate i18nKey='sharedVehicle' />}
                  groupTitle={<Translate i18nKey='groups' />}
                  showResources={this.props.showResources}
                  themecolors = {this.props.themecolors}
                />
              </li>
              
              {this.props.resourceList ? 
              <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
                {checkPrivileges('itemgroupCreate') && (
                  <Button
                    fullWidth
                    size='small'
                    onClick={() => this.props.addResource()}
                  >
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}
              </li>: 
              <li style={{ paddingTop: 8, paddingBottom: 4}}> {/*, paddingRight: 0 }}> */}
              {checkPrivileges('vehicleCreate') && (
                <Button
                  fullWidth
                  size='small'
                  onClick={() => this.props.addItem()}
                >
                  {this.props.translate('sharedCreate')}
                </Button>
              )}
            </li>}
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                  <ExportImportMenu
                    fileName = 'vehicles'
                    checkPrivilege = 'vehicleCreate'
                    data = {vehicles}
                    radius={10}
                    themecolors = {this.props.themecolors}
                    fetchMoreItems={this.props.fetchMoreItems}
                    openImportTable={this.props.openImportTable}
                    logInUser={this.props.logInUser}
                    />
              </li>
            </ul>
          </div>
          {this.props.resourceList ? 
          <RecourcesList
          ItemChild={VehicleListRow}
          ItemChildProps={this.props}
          fromArray={this.props.vehicles}
           {...this.props} /> : 
          <div className='sidebar-users-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableVehicle' />
                  )
                }
              }}
            >
              {checkPrivileges('vehicle') && this.props.itemPagination ? (
                <InfiniteScroll
                  dataLength={this.props.itemPagination&&this.props.itemPagination.items&&this.props.itemPagination.items.length}
                  next={this.props.fetchMoreItems}
                  hasMore={this.props.itemPagination.hasNext}
                  loader={<Loader defaultStyle={false} />}
                  scrollableTarget='scrollableVehicle'
                  endMessage={endMessage}
                >
                  <VehicleShortList
                    {...this.state}
                    itemPagination={this.props.itemPagination}
                    allVehicleLocation={this.props.allVehicleLocation}
                    match={this.props.match}
                    editItem={this.editItem}
                    showWaslDetails={this.showWaslDetails}
                    removedItem={this.props.removedItem}
                    selecteItem={this.selecteItem}
                    ServerSetting={this.props.ServerSetting}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                    qrCodeGenerator={this.props.qrCodeGenerator}
                  />
                </InfiniteScroll>
               ) : null}
            </Scrollbar>
          </div>}
        </aside>
        </div>
      </aside>
      )
    } else {
      return ''
    }
  }
}
export default withTranslationWrapper(Sidebar)
