import React from "react";
import moment from "moment";
// import ical from 'ical-generator'
import {
  driverDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  dashboardDeviceWiseSelectedList,
  vehicleDeviceWiseSelectedList,
  geofenceDeviceWiseSelectedList,
  notificationDeviceWiseSelectedList,
  groupDeviceWiseSelectedList,
  deviceUserWiseSelectedList,
  userDeviceWiseSelectedList,
  templateUserWiseSelectedList,
  widgetDeviceWiseSelectedList,
} from "../Actions/Notifications";
import { computedAttributeDeviceWiseSelectedList } from "../Actions/ComputedAttributes";
import { commandsDeviceWiseSelectedList } from "../Actions/Commands";
import { maintenanceDeviceWiseSelectedList } from "../Actions/Maintenance";
import { garageUsersWiseSelectedList } from "../Actions/Garages";
import { areaUsersWiseSelectedList } from "../Actions/Areas";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { Tooltip } from "@mui/material";
import { Trans as Translate } from "react-i18next";

import instance from "../axios";
import { serviceUsersWiseSelectedList } from "../Actions/Services";
import { notifyError, notifySuccess } from "../Utils/CustomNotifcations";
export const defaultDevicesLength = 300;
export const storeTime = 30;
export const storeTime2 = 10;
export function checkPrivileges(type) {
  const userPrivileges = JSON.parse(localStorage.getItem("frontPrivileges"));
  const info = JSON.parse(localStorage.getItem("userInfoFront"));
  JSON.parse(localStorage.getItem("userInfoFront"));
  if (userPrivileges) {
    if (info && info.id) {
      return Object.values(userPrivileges).indexOf(type) > -1;
    } else {
      // window.location.assign('/logout')
      return false;
    }
  } else {
    window.location.assign("/login");
  }
}
export function getValue(o, k) {
  return k.split(".").reduce((r, e) => {
    if (!r) return r;
    else return r[e] || undefined;
  }, o);
}

export const plateLetters = [
  { label: "ا", id: "1" },
  { label: "ب", id: "2" },
  { label: "ح", id: "3" },
  { label: "د", id: "4" },
  { label: "ر", id: "5" },
  { label: "س", id: "6" },
  { label: "ص", id: "7" },
  { label: "ط", id: "8" },
  { label: "ع", id: "9" },
  { label: "ق", id: "10" },
  { label: "ك", id: "11" },
  { label: "ل", id: "12" },
  { label: "م", id: "13" },
  { label: "ن", id: "14" },
  { label: "هـ", id: "15" },
  { label: "و", id: "16" },
  { label: "ى", id: "17" },
];

export const plateTypes = [
  { id: "1", label: <Translate i18nKey="privateCar" /> },
  { id: "2", label: <Translate i18nKey="publicTransport" /> },
  { id: "3", label: <Translate i18nKey="privateTransport" /> },
  { id: "4", label: <Translate i18nKey="publicMinibus" /> },
  { id: "5", label: <Translate i18nKey="privateMinibus" /> },
  { id: "6", label: <Translate i18nKey="taxi" /> },
  { id: "7", label: <Translate i18nKey="heavyEquipment" /> },
  { id: "8", label: <Translate i18nKey="export" /> },
  { id: "9", label: <Translate i18nKey="diplomatic" /> },
  { id: "10", label: <Translate i18nKey="motorcycle" /> },
  { id: "11", label: <Translate i18nKey="temporary" /> },
];
export const getTimeFormat = () => {
  const cookiee = localStorage.getItem("userInfoFront");
  let timeFormat = "HH:mm";
  if (cookiee) {
    const userInfo = JSON.parse(cookiee);
    timeFormat = userInfo && userInfo.twelveHourFormat ? "hh:mm A" : "HH:mm";
  }
  return timeFormat;
};

export const getDateFormat = () => {
  return "YYYY-MM-DD";
};

export const prepareStreeView = (lat, lng) => {
  return "http://maps.google.com/maps?q=&layer=c&cbll=" + lat + "," + lng;
};

export const getDateTimeFormat = () => {
  const cookiee = localStorage.getItem("userInfoFront");
  let timeFormat = "YYYY-MM-DD HH:mm:ss";
  if (cookiee) {
    const userInfo = JSON.parse(cookiee);
    timeFormat =
      userInfo && userInfo.twelveHourFormat
        ? "YYYY-MM-DD hh:mm:ss A"
        : "YYYY-MM-DD HH:mm:ss";
  }
  return timeFormat;
};
export const intervals = (startString, endString) => {
  const start = moment(startString);
  const end = moment(endString);

  // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
  // note that 59 will round up to 60, and moment.js handles that correctly
  start.minutes(Math.ceil(start.minutes() / 15) * 15);

  const result = [];

  const current = moment(start);

  while (current <= end) {
    result.push({
      key: current.format("YYYY-MM-DD HH:mm"),
      value: current.format("hh:mm a"),
    });
    current.add(15, "minutes");
  }

  return result;
};

export function isOwner(logInUser) {
  let query = "";
  if (logInUser.userType === -1) {
    query = "all=true";
  } else {
    query = "userId=" + logInUser.id;
  }
  return query;
}
export function displayErrorMessages(err) {
  let errormgs = "";
  if (err.includes("Admin")) {
    errormgs = "youRequiredAdministrationAccess";
  } else if (err.includes("Unauthorized")) {
    errormgs = "yourEmailOrPasswordIsWrong";
  } else if (err.includes("Account has expired")) {
    errormgs = "yourAccountHasExpired";
  } else if (err.includes("Account is disabled")) {
    errormgs = "accountIsDisabled";
  } else if (err.includes("Invalid Current Password")) {
    errormgs = "wrongCurrentPassword";
  } else if (err.includes("Invalid User. Please try again with other user")) {
    errormgs = "invalidUserPleaseTryAgainWithOtherUser";
  } else if (err.includes("UnAuthorize User")) {
    errormgs = "UnAuthorizeUserPleaseTryAgainWithOtherUser";
  } else if (err.includes("Unknown account")) {
    errormgs = "Account doesnot exists";
  } else if (err.includes("Not enough privileges")) {
    errormgs = "somthingWentWrongMessage1";
  } else if (
    err.includes(
      "bad request (HINT: Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.)"
    )
  ) {
    errormgs =
      "Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.";
  } else if (err.includes("Request failed with status code 500")) {
    errormgs = "serverIsDown";
  } else {
    errormgs = "somthingWentWrongMessage1";
  }
  return errormgs;
}

export const arrDiff = (a1, a2) => {
  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (const k in a) {
    diff.push(k);
  }

  return diff;
};
export const findDuplicates = (arr) => {
  const sortedArr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  const results = [];
  for (let i = 0; i < sortedArr.length - 1; i++) {
    if (parseInt(sortedArr[i + 1]) === parseInt(sortedArr[i])) {
      results.push(sortedArr[i]);
    }
  }
  return results;
};

export function isAuthenticated(props) {
  const info = JSON.parse(localStorage.getItem("userInfoFront"));
  if (info && info.id) {
    return info.id;
  } else {
    if (props && props.history) {
      return props.history.push("/logout");
    }
  }
}
export function isOwnerOrDealer(type) {
  if (type === -1 || type === 5) {
    return true;
  } else {
    return false;
  }
}

export function findObj(col, val, array) {
  return array.find((row) => row[col] === val);
}

export function DistanceUnits(value, unit) {
  const data = [
    {
      key: "km",
      name: "km",
      factor: 0.001,
    },
    {
      key: "mi",
      name: "mi",
      factor: 0.000621371,
    },
    {
      key: "nmi",
      name: "nmi",
      factor: 0.000539957,
    },
  ];

  if (!unit) {
    unit = "km";
  }
  const model = findObj("key", unit, data);
  return model && model.key
    ? parseFloat(value * model.factor).toFixed(2) + " " + model.name
    : parseFloat(value || 0).toFixed(2) + " " + unit;
}

export function convertSpeedUnits(value, fromUnit, toUnit, notToFixed) {
  const data = [
    {
      key: "kn",
      name: "kn",
      factor: 1,
    },
    {
      key: "kmh",
      name: "km/h",
      factor: 1.852,
    },
    {
      key: "mph",
      name: "mph",
      factor: 1.15078,
    },
  ];
  const model = findObj("key", fromUnit, data);
  if (model && model.key) {
    const resetValue = value / model.factor;
    const model2 = findObj("key", toUnit, data);
    if (model2 && model2.key) {
      if (notToFixed) {
        return toUnit !== "kn"
          ? resetValue * model2.factor
          : resetValue * model2.factor;
      } else {
        return toUnit !== "kn"
          ? parseFloat(resetValue * model2.factor).toFixed(0)
          : resetValue * model2.factor;
      }
    } else {
      return parseFloat(value || 0).toFixed(0);
    }
  } else return parseFloat(value || 0).toFixed(0);
}

export function SpeedUnits(value, unit) {
  const data = [
    {
      key: "kn",
      name: "kn",
      factor: 1,
    },
    {
      key: "kmh",
      name: "km/h",
      factor: 1.852,
    },
    {
      key: "mph",
      name: "mph",
      factor: 1.15078,
    },
  ];

  if (!unit) {
    unit = "kn";
  }
  const model = findObj("key", unit, data);
  return model && model.key
    ? parseFloat(value * model.factor).toFixed(0) + " " + model.name
    : parseFloat(value || 0).toFixed(0) + " " + unit;
}

export function VolumeUnits(value, unit) {
  const data = [
    {
      key: "ltr",
      name: "l",
      factor: 1,
    },
    {
      key: "impGal",
      name: "ukGal",
      factor: 4.546,
    },
    {
      key: "usGal",
      name: "usGal",
      factor: 3.785,
    },
    {
      key: "ukGal",
      name: "ukGal",
      factor: 4.546,
    }, //uk gallon added, Jawad
  ];

  if (!unit) {
    unit = "ltr";
  }
  const model = findObj("key", unit, data);
  return model && model.key
    ? parseFloat(value / model.factor || 0).toFixed(1) + " " + model.name
    : parseFloat(value || 0).toFixed(1) + " " + unit;
}

export function HoursUnits(miliseconds) {
  moment.locale("en");
  return moment
    .duration(miliseconds)
    .format("Y[yrs] M[mo] D[d] h[h] m[m] s[s]");
}

export function CoordinateFormats(key, value, unit) {
  if (value) {
    let hemisphere, degrees, minutes, seconds;

    if (key === "latitude") {
      hemisphere = value >= 0 ? "N" : "S";
    } else {
      hemisphere = value >= 0 ? "E" : "W";
    }

    switch (unit) {
      case "ddm":
        value = Math.abs(value);
        degrees = Math.floor(value);
        minutes = (value - degrees) * 60;
        return (
          degrees + "° " + parseFloat(minutes).toFixed(8) + "' " + hemisphere
        );
      case "dms":
        value = Math.abs(value);
        degrees = Math.floor(value);
        minutes = Math.floor((value - degrees) * 60);
        seconds = Math.round((value - degrees - minutes / 60) * 3600);
        return degrees + "° " + minutes + "' " + seconds + '" ' + hemisphere;
      default:
        return parseFloat(value || 0).toFixed(8) + "°";
    }
  } else {
    return value;
  }
}
export function CourseFormats(value) {
  const data = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  return data[Math.floor(value / 45)];
}

export function numberFormatter(value, precision, suffix) {
  if (value !== undefined) {
    return Number(parseFloat(value || 0).toFixed(precision)) + " " + suffix;
  }
  return null;
}

export function defaultFormatter(value, unit) {
  if (typeof value === "number") {
    return Number(parseFloat(value || 0).toFixed(2));
  } else if (typeof value === "boolean") {
    return value ? "YES" : "NO";
  } else if (value instanceof Date) {
    return moment(value).format(getDateTimeFormat());
  } else {
    return value + " " + (unit || "");
  }
}

export function setAttributeFormat(key, value, unit, unknown, category) {
  const cookiee = localStorage.getItem("userInfoFront");
  let userInfo = {};
  let coordinateFormat = "";
  let distanceUnit = "";
  let speedUnit = "";
  let volumeUnit = "";
  if (cookiee) {
    userInfo = JSON.parse(cookiee);
    coordinateFormat = (userInfo && userInfo.coordinateFormat) || "";
    distanceUnit =
      (userInfo && userInfo.attributes && userInfo.attributes.distanceUnit) ||
      "";
    speedUnit =
      (userInfo && userInfo.attributes && userInfo.attributes.speedUnit) || "";
    volumeUnit =
      (userInfo && userInfo.attributes && userInfo.attributes.volumeUnit) || "";
  }

  if (unknown && unknown.length && unknown.indexOf(key) !== -1) {
    return "unknown";
  } else {
    switch (key) {
      case "totalDistance":
      case "mileage":
      case "distance":
        return DistanceUnits(value, distanceUnit);
      case "speed":
        return SpeedUnits(value, speedUnit);
      /* case 'driverUniqueId':
              return this.driverUniqueIdFormatter; */
      case "longitude":
      case "latitude":
        return CoordinateFormats(key, value, coordinateFormat);
      case "power":
      case "battery":
      case "voltage":
      case "boardPower":
        return numberFormatter(value, 2, "V");
      case "batteryLevel":
      case "percentage":
        return numberFormatter(value, 0, "%");
      case "temperature":
        return numberFormatter(value, 2, unit || "°C");
      case "fuel":
      case "volume":
        return VolumeUnits(value, volumeUnit);
      case "idlTime":
      case "stopTime":
      case "hours":
        return HoursUnits(value || 0);
      case "doors":
      case "carAlarm":
      case "enginStatus":
      case "externalPower":
      case "panicButton":
      case "customDiscrete":
      case "ignition":
      case "devicePowerCut":
        return value ? "On" : "Off";
      case "custom":
        if (category === 1) {
          return value ? "On" : "Off";
        } else {
          return defaultFormatter(value, unit);
        }
      case "altitude":
        return value ? value + "m" : "0m";
      case "course":
        return CourseFormats(value);
      case "instantFuelConsumption":
      case "averagFuelConsumption":
      case "consumption":
        return numberFormatter(value, 2, unit || "l/h");
      default:
        return defaultFormatter(value, unit);
    }
  }
}

export function convertToBinary(str, protocol) {
  if (str === undefined) {
    return null;
  }

  const raw = "0000000000000000" + str.toString(2);
  const last16 = raw.substr(raw.length - 16);
  const arr = last16.split("");

  if (protocol === "galileo") {
    const p = { binaryValue: last16, decimalValue: str };

    if (parseInt(arr["15"]) === 1) {
      p["Driving (set by AccSens command)."] = parseInt(arr["15"]);
    } else {
      p["Vibration level corresponds parking"] = parseInt(arr["15"]);
    }

    if (parseInt(arr["14"]) === 1) {
      p["Incline level exceeds the allowable one"] = parseInt(arr["14"]);
    } else {
      p["Incline angle does not exceed the allowable one"] = parseInt(
        arr["14"]
      );
    }

    if (parseInt(arr["12"]) === 1) {
      p["GSM modem cant determine SIM-card."] = parseInt(arr["12"]);
    } else {
      p["No SIM card"] = parseInt(arr["12"]);
    }

    if (parseInt(arr["10"]) === 1) {
      p["Lower than 3.7 V"] = parseInt(arr["10"]);
    } else {
      p["Voltage of internal source is normal"] = parseInt(arr["10"]);
    }

    if (parseInt(arr["9"]) === 1) {
      p.Disconnected = parseInt(arr["9"]);
    } else {
      p["GPS aerial is connected"] = parseInt(arr["9"]);
    }

    if (parseInt(arr["8"]) === 1) {
      p["Declined from normal."] = parseInt(arr["8"]);
    } else {
      p["Voltage of internal terminal supply bus is normal"] = parseInt(
        arr["8"]
      );
    }

    if (parseInt(arr["7"]) === 1) {
      p["Declined from normal."] = parseInt(arr["7"]);
    } else {
      p["External supply voltage is normal"] = parseInt(arr["7"]);
    }

    if (parseInt(arr["6"]) === 1) {
      p["Vehicle is started (adjusted by mhours command)"] = parseInt(arr["6"]);
    } else {
      p["Vehicle is stopped"] = parseInt(arr["6"]);
    }

    if (parseInt(arr["5"]) === 1) {
      p["Vibration level corresponds normal movement"] = parseInt(arr["5"]);
    } else {
      p["Vibration level corresponds shock."] = parseInt(arr["5"]);
    }

    p[
      "Signal quality, range: [0-3]. The less value, the worse communication."
    ] = parseInt(arr["3"] + arr["2"], 2);

    return p;
  } else if (protocol === "bce") {
    return {
      binaryValue: last16,
      decimalValue: str,
      Motion: parseInt(arr["15"]),
      "Physical input 1": parseInt(arr["14"]),
      "Physical input 2": parseInt(arr["13"]),
      "Physical input 3": parseInt(arr["12"]),
      "Physical input 4": parseInt(arr["11"]),
      "Voltage threshold on any ADC input": parseInt(arr["10"]),
      "Status of engine": parseInt(arr["9"]),
      "Main power supply of device status": parseInt(arr["8"]),
      "GSM jamming": parseInt(arr["7"]),
      "Activity status of physical Out1": parseInt(arr["6"]),
      "Activity status of physical Out2": parseInt(arr["5"]),
      "Activity status of virtual Out3": parseInt(arr["4"]),
      "Overturn fixed by accelerometer": parseInt(arr["3"]),
      "Shock detection": parseInt(arr["2"]),
      "Accident fixed by crash sensor": parseInt(arr["1"]),
      "Status of GPS antenna connection": parseInt(arr["0"]),
    };
  }
}
// convertStatus(14849);
export function setCookie(cname, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "= ;" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const toggleEntity = async (type, method, entity, property, param1, param2) => {
  let url, requestData;

  if (type === 3) {
    url = method
      ? `/api/vehicles/assignall?assignFrom=${property}&assignTo=${param1}&all=true&unlink=false`
      : `/api/vehicles/assignall?assignFrom=${property}&assignTo=${param1}&all=true&unlink=true`;
    requestData = null;
  } else {
    url =
      type === 1
        ? `/api/permissions/multiproperty`
        : `/api/permissions/multientity`;
    requestData = {
      [entity]: type === 2 ? param2 : param1,
      [property]: type === 2 ? param1 : param2,
    };
  }

  const requestConfig = {
    url,
    method: type === 3 ? "GET" : method,
    data: requestData,
  };

  return await instance(requestConfig);
};

export const selectAll = (
  check,
  entity,
  prop,
  id,
  arr,
  base,
  dispatch,
  type,
  translate,
  callback,
  handleLoaderCallback
) => {
  const checked = check;
  let data = [];
  // console.log("arr=====", arr);

  // if(type !== 'vehicle'){
  if (checked) {
    arr.map((u) => {
      if (type === "attribute") {
        if (u.category === 0 && !u.check) {
          data.push(u);
        }
      } else if (!u.check) {
        data.push(u);
      }
      return null;
    });
  } else {
    if (type === "attribute") {
      arr.map((u) => {
        if (u.category === 0) {
          data.push(u);
        }
        return null;
      });
    } else {
      data = arr;
    }
  }
  // }

  toggleEntity(
    type === "access" || type === "deviceAlt" ? 2 : type === "vehicle" ? 3 : 1,
    // checked ? 'POST' : 'DELETE',
    type === "vehicle" ? checked : checked ? "POST" : "DELETE",
    entity,
    prop,
    type === "vehicle" ? id : parseInt(id),
    data.map((d) => d.id)
  )
    .then((res) => {
      // const res = response
      if (type !== "vehicle") {
        if (callback) {
          callback(res);
        }
      } else {
        if (handleLoaderCallback) handleLoaderCallback(res);
      }

      // if (
      //   response.status === 200 &&
      //   (res.status === 'success' || res.status === 'ambiguous')
      // ) {
      if (type === "driver") {
        const message = checked ? "alldriversAssgined" : "alldriversUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          driverDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "binder") {
        const message = checked
          ? "alltrailersAssgined"
          : "alltrailersUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          trailerDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "dashboard") {
        const message = checked
          ? "alldashboardsAssgined"
          : "alldashboardsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          dashboardDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "widget") {
        const message = checked ? "allwidgetsAssgined" : "allwidgetsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          widgetDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "vehicle") {
        const message = checked
          ? "allvehiclesAssgined"
          : "allvehiclesUnassgined";
        updateData(
          checked,
          arr,
          dispatch,
          vehicleDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "geofence") {
        const message = checked
          ? "allgeofencesAssgined"
          : "allgeofencesUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          geofenceDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "notification") {
        const message = checked
          ? "allnotificationsAssgined"
          : "allnotificationsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          notificationDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "command") {
        const message = checked
          ? "allcommandsAssgined"
          : "allcommandsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          commandsDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "maintenance") {
        const message = checked
          ? "allmaintenancesAssgined"
          : "allmaintenancesUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          maintenanceDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "group") {
        const message = checked ? "allgroupsAssgined" : "allgroupsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          groupDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "device" || type === "deviceAlt") {
        const message = checked ? "alldevicesAssgined" : "alldevicesUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          deviceUserWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "attribute") {
        const message = checked
          ? "allattributesAssgined"
          : "allattributesUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          computedAttributeDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "access") {
        const message = checked
          ? entity === "userId"
            ? "allusersAssgined"
            : entity === "deviceId"
            ? "alldevicesAssgined"
            : "allgroupsAssgined"
          : entity === "userId"
          ? "allusersUnassgined"
          : entity === "deviceId"
          ? "alldevicesUnassgined"
          : "allgroupsUnassgined";
        updateData(
          checked,
          base,
          dispatch,
          userDeviceWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "template") {
        const message = checked
          ? entity === "userId"
            ? "alltemplatesAssgined"
            : entity === "deviceId"
            ? "alldevicesAssgined"
            : "allgroupsAssgined"
          : entity === "userId"
          ? "alltemplatesUnassgined"
          : entity === "deviceId"
          ? "alldevicesUnassgined"
          : "allgroupsUnassgined";
        updateData(
          checked,
          arr,
          dispatch,
          templateUserWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "garage") {
        const message = checked ? "allGaragesAssgined" : "allGaragesUnassgined";
        updateData(
          checked,
          arr,
          dispatch,
          garageUsersWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "area") {
        const message = checked ? "allAreasAssgined" : "allAreasUnassgined";
        updateData(
          checked,
          arr,
          dispatch,
          areaUsersWiseSelectedList,
          translate(message),
          res
        );
      } else if (type === "service") {
        const message = checked
          ? "allServicesAssgined"
          : "allServicesUnassgined";
        updateData(
          checked,
          arr,
          dispatch,
          serviceUsersWiseSelectedList,
          translate(message),
          res
        );
      }
      // }
    })
    .catch((error) => {
      console.log("eror ==", error);
      errorHandler(error, dispatch, callback);
    });
  // errorHandler(error,dispatch )})
};

const updateData = (check, base, dispatch, func, message, res) => {
  const allData = base.map((n) => {
    if (res.status === "ambiguous") {
      if (res && res.data && res.data.length && !res.data.includes(n.id)) {
        n.check = check;
      }
    } else {
      n.check = check;
    }
    return n;
  });
  dispatch(notifySuccess(message));
  dispatch(func(allData));
};

export const PaginationConfig = {
  page: 1,
  pagesize: 10,
  itemSearch: "",
  hasNext: true,
};
export function checkedDevelopmentType() {
  let result = `${window.location.host}`;
  const serverType = window.location.host.includes("localhost");
  if (serverType) {
    result = "";
  }
  return result;
}

export const validEmailPattern =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i;
export const validNumberPattern = /^\(?(\d{4})\)?[- ]?(\d{3})[- ]?(\d{7})$/;

export const selectStyles = (themecolors) => ({
  option: (base, state) => ({
    ...base,
    padding: "10px 14px",
    color:
      state.isFocused || state.isSelected
        ? themecolors.themeLightInverse
        : themecolors.textColor,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: "10px 14px",
  }),
  multiValue: (base) => ({
    ...base,
    margin: "2px",
    background: themecolors.themeLightColor,
    color: themecolors.themeInverse,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: themecolors.textColor,
  }),
  placeholder: (base) => ({
    ...base,
    color: themecolors.textColor,
    opacity: 0.5,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: themecolors.textColor,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    color: themecolors.textColor,
    padding: 4,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: themecolors.textColor,
  }),
  input: (base) => ({
    ...base,
    color: themecolors.textColor,
  }),
  container: (base) => ({
    ...base,
    margin: "8px 0 4px",
  }),
  control: (base) => ({
    ...base,
    boxShadow:
      "0px 2px 4px 0px rgba(20, 20, 20, 0.15), 0px 2px 4px 0px rgba(20, 20, 20, 0.15) inset",
    background: "none",
    borderColor: themecolors.themeLightColor,
    minHeight: 26,
    "&:hover": {
      borderColor: themecolors.themeLightColor,
    },
  }),
  menuList: (base) => ({
    ...base,
    background: themecolors.backgroundColor,
    color: themecolors.textColor,
  }),
  singleValue: (base) => ({
    ...base,
    color: themecolors.textColor,
  }),
});

export const ReportColumns = {
  // latlng
  startLat: { width: 120 },
  startLon: { width: 120 },
  endLat: { width: 120 },
  endLon: { width: 120 },
  latitude: { width: 120 },
  longitude: { width: 120 },
  entranceLat: { width: 120 },
  entranceLon: { width: 120 },
  exitLat: { width: 120 },
  exitLon: { width: 120 },

  // time
  startTime: { width: 180 },
  endTime: { width: 180 },
  serverTime: { width: 180 },
  eventTime: { width: 180 },
  entranceTime: { width: 180 },
  exitTime: { width: 180 },

  // duration
  duration: { width: 110 },
  engineHours: { width: 110 },
  movementDuration: { width: 110 },
  idleDuration: { width: 110 },
  date: { width: 110 },
  startOdometer: { width: 110 },
  endOdometer: { width: 110 },
  type: { width: 110 },
  distance: { width: 110 },

  // other numeric columns
  tripCount: { width: 80 },
  stopCount: { width: 80 },
  fillCount: { width: 80 },
  drainCount: { width: 80 },
  idleDurationPercentage: { width: 80 },
  movementDurationPercentage: { width: 80 },
  fillVolume: { width: 80 },
  drainVolume: { width: 80 },
  startFuelLevel: { width: 80 },
  endFuelLevel: { width: 80 },
  fuelConsumption: { width: 80 },
  spentFuel: { width: 80 },
  penalty: { width: 80 },
  periodCount: { width: 80 },
  eventCount: { width: 80 },

  // event summery columns
  deviceMoving: { width: 100 },
  deviceOffline: { width: 100 },
  deviceOnline: { width: 100 },
  deviceStopped: { width: 100 },
  deviceUnknown: { width: 100 },
  ignitionOff: { width: 100 },
  ignitionOn: { width: 100 },

  averageSpeed: { width: 140 },
  maxSpeed: { width: 140 },
  // address
  coordinate: { width: 250 },
  startAddress: { width: 250 },
  endAddress: { width: 250 },
  address: { width: 250 },
  entranceAddress: { width: 250 },
  exitAddress: { width: 250 },

  name: { width: 200 },
  driverName: { width: 200 },
  geofenceName: { width: 200 },
  maintenanceName: { width: 200 },
  geofenceId: { width: 200 },
  visitCount: { width: 200 },
  comments: { width: 200 },
  violationCount: { width: 200 },
};

// Alarm
export const alarmList = [
  "alarmMovement",
  "alarmOverspeed",
  "alarmFallDown",
  "alarmLowBattery",
  "alarmLowPower",
  "alarmFault",
  "alarmPowerOff",
  "alarmPowerOn",
  "alarmDoor",
  "alarmGeofence",
  "alarmGeofenceEnter",
  "alarmGeofenceExit",
  "alarmGpsAntennaCut",
  "alarmAccident",
  "alarmTow",
  "alarmIdle",
  "alarmHardAcceleration",
  "alarmHardBraking",
  "alarmFatigueDriving",
  "alarmPowerCut",
  "alarmPowerRestored",
  "alarmJamming",
  "alarmTemperature",
  "alarmParking",
  "alarmShock",
  "alarmBonnet",
  "alarmFootBrake",
  "alarmFuelLeak",
  "alarmTampering",
  "alarmRemoving",
];

export const fonts = {
  "ff-1": "Poppins",
  "ff-2": "PT Sans",
  "ff-3": "Raleway",
  "ff-4": "Roboto",
  "ff-5": "Nunito",
};
const fontIds = Object.keys(fonts);
const fontFamilyArr = [];
fontIds.map((k) => fontFamilyArr.push({ value: k, label: fonts[k] }));
export const fontFamilies = fontFamilyArr;

export const postionAttributes = [
  "date",
  "index",
  "hdop",
  "vdop",
  "pdop",
  "sat",
  "satVisible",
  "rssi",
  "gps",
  "roaming",
  "event",
  "alarm",
  "status",
  "odometer",
  "serviceOdometer",
  "tripOdometer",
  "hours",
  "stopTime",
  "steps",
  "power",
  "input",
  "output",
  "battery",
  "batteryLevel",
  "fuel",
  "fuelConsumption",
  "approximate",
  "rpm",
  "throttle",
  "armed",
  "acceleration",
  "deviceTemp",
  "obdSpeed",
  "obdOdometer",
  "distance",
  "totalDistance",
  "commandResult",
  "dtcs",
  "blocked",
  "operator",
  "deviceCommand",
  "sharedGeofences",
  "selectedGeofence",
  "motion",
  "vin",
  "deviceTotalDistance",
  "rfid",
  "versionFw",
  "versionHw",
  "ignition",
  "flags",
  "charge",
  "ip",
  "archive",
  "sharedType",
  "type",
  "driverUniqueId",
  "trailerUniqueId",
  "raw",
  "geofence",
  "mileageAccuracy",
  "parking",
  "custom",
  "boardPower",
  "altitude",
  "direction",
  "location",
  "trackerUpdated",
  "driver",
  "id",
  "gpsUpdated",
  "accel",
  "accelBrake",
  "accelTurn",
  "accelBump",
  "fuelTotal",
  "lastUpdated",
  "binUniqueId",
  "passengerUniqueId",
];
// this postion att user for attribute type comparasion
export const postionAttributesTypeComp = [
  {
    key: "raw",
    valueType: "string",
  },
  {
    key: "index",
    valueType: "number",
  },
  {
    key: "hdop",
    valueType: "number",
  },
  {
    key: "vdop",
    valueType: "number",
  },
  {
    key: "pdop",
    valueType: "number",
  },
  {
    key: "sat",
    valueType: "number",
  },
  {
    key: "satVisible",
    valueType: "number",
  },
  {
    key: "rssi",
    valueType: "number",
  },
  {
    key: "gps",
    valueType: "number",
  },
  {
    key: "roaming",
    valueType: "boolean",
  },
  {
    key: "event",
    valueType: "string",
  },
  {
    key: "alarm",
    valueType: "string",
  },
  {
    key: "status",
    valueType: "string",
  },
  {
    key: "odometer",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "serviceOdometer",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "tripOdometer",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "hours",
    valueType: "number",
    dataType: "hours",
  },
  {
    key: "steps",
    valueType: "number",
  },
  {
    key: "input",
    valueType: "string",
  },
  {
    key: "output",
    valueType: "string",
  },
  {
    key: "power",
    valueType: "number",
    dataType: "voltage",
  },
  {
    key: "battery",
    valueType: "number",
    dataType: "voltage",
  },
  {
    key: "batteryLevel",
    valueType: "number",
    dataType: "percentage",
  },
  {
    key: "fuel",
    valueType: "number",
    dataType: "volume",
  },
  {
    key: "fuelConsumption",
    valueType: "number",
    dataType: "consumption",
  },
  {
    key: "versionFw",
    valueType: "string",
  },
  {
    key: "versionHw",
    valueType: "string",
  },
  {
    key: "type",
    valueType: "string",
  },
  /* {
    key: 'ignition',
    valueType: 'boolean'
  }, */ {
    key: "flags",
    valueType: "string",
  },
  {
    key: "charge",
    valueType: "boolean",
  },
  {
    key: "ip",
    valueType: "string",
  },
  {
    key: "archive",
    valueType: "boolean",
  },
  {
    key: "distance",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "totalDistance",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "rpm",
    valueType: "number",
  },
  {
    key: "vin",
    valueType: "string",
  },
  {
    key: "approximate",
    valueType: "boolean",
  },
  {
    key: "throttle",
    valueType: "number",
  },
  {
    key: "motion",
    valueType: "boolean",
  },
  {
    key: "armed",
    valueType: "number",
  },
  {
    key: "geofence",
    valueType: "string",
  },
  {
    key: "acceleration",
    valueType: "number",
  },
  {
    key: "deviceTemp",
    valueType: "number",
    dataType: "temperature",
  },
  {
    key: "operator",
    valueType: "string",
  },
  {
    key: "command",
    valueType: "string",
  },
  {
    key: "blocked",
    valueType: "boolean",
  },
  {
    key: "dtcs",
    valueType: "string",
  },
  {
    key: "obdSpeed",
    valueType: "number",
    dataType: "speed",
  },
  {
    key: "obdOdometer",
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "result",
    valueType: "string",
  },
  {
    key: "driverUniqueId",
    valueType: "string",
    dataType: "driverUniqueId",
  },
];

export const updateNotifications = ({ devices, positions }) => {
  let idling = [];
  let moving = [];
  const overSpeed = [];
  let towing = [];
  let online = [];
  let offline = [];
  let statusParking = [];
  let lowBattery = [];
  let stop = [];
  let gpsNotUpdated = [];
  let notRegistered = [];

  if (devices && devices.length) {
    devices.map((device) => {
      const id = device.id;

      const position = positions[id] ? positions[id] : null;

      if (
        position &&
        !moment(device.lastUpdate).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            "minutes"
          )
        )
      ) {
        if (
          position &&
          !moment(position.serverTime).isBefore(
            moment().subtract(
              parseInt(device.attributes.storeTime || storeTime),
              "minutes"
            )
          )
        ) {
          online = [...new Set([...online, id])];
        } else {
          gpsNotUpdated = [...new Set([...gpsNotUpdated, id])];
        }
      } else if (
        position &&
        moment(device.lastUpdate).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            "minutes"
          )
        )
      ) {
        offline = [...new Set([...offline, id])];
      } else {
        notRegistered = [...new Set([...notRegistered, id])];
      }

      if (
        position &&
        position.attributes &&
        position.attributes.batteryLevel &&
        position.attributes.batteryLevel <= 15
      ) {
        lowBattery = [...new Set([...lowBattery, id])];
      }

      if (
        position &&
        !moment(position.serverTime).isBefore(
          moment().subtract(
            parseInt(device.attributes.storeTime || storeTime),
            "minutes"
          )
        ) &&
        device
      ) {
        const { ignition, motion, parking, stopTime } = position.attributes;
        // idling

        if (
          ignition &&
          device &&
          device.attributes &&
          parseInt(stopTime) >
            parseInt(device.attributes.minimalParkingDuration) * 1000
        ) {
          idling = [...new Set([...idling, id])];
        } else if (
          (ignition && motion) ||
          (position.protocol === "osmand" && motion)
        ) {
          // moving
          moving = [...new Set([...moving, id])].sort();
        } else if (ignition !== true && motion) {
          // towing
          towing = [...new Set([...towing, id])].sort();
        } else if (parking) {
          statusParking = [...new Set([...statusParking, id])].sort();
        } else if (
          device &&
          device.attributes &&
          parseInt(stopTime) <
            parseInt(device.attributes.minimalParkingDuration) * 1000
        ) {
          stop = [...new Set([...stop, id])].sort();
        }
      }
      return null;
    });
  }

  return {
    overSpeed: overSpeed,
    Idling: idling,
    harshBreak: [],
    Moving: moving,
    Towing: towing,
    statusOnline: online,
    statusOffline: offline,
    statusParking: statusParking,
    lowBattery: lowBattery,
    gpsNotUpdated: gpsNotUpdated,
    notRegistered: notRegistered,
    stop: stop,
  };
};

export const PrepareAddressHTML = React.memo(
  ({ copyToClipboard, lat, lng, value, index }) => (
    <Tooltip
      classes={{
        popper: "menu-popper",
        tooltip: "menu-popper-tooltip",
      }}
      placement="top-start"
      title="Right Click for more"
    >
      <div>
        {/* <ContextMenuTrigger id={"menuitem"+index}>
      <Link
        target='_blank'
        to={'/maps/' + lat + '/' + lng}
      >
        {value ? parse(value) : null}
      </Link>
    </ContextMenuTrigger>
    <ContextMenu id={"menuitem"+index} style={{zIndex: 9}}>
      <MenuItem onClick={e => window.open('/maps/' + lat + '/' + lng, '_blank')}>View on Map</MenuItem>
      <MenuItem onClick={e => copyToClipboard({text: lat+','+ lng})}>Copy coordinates</MenuItem>
      <MenuItem onClick={e => copyToClipboard({text: parse(value)})}>Copy Address</MenuItem>
    </ContextMenu> */}
      </div>
    </Tooltip>
  )
);

export const PrepareAddress = (props) => {
  const dispatch = useDispatch();

  const copyToClipboard = (obj) => {
    const el = document.createElement("textarea");
    el.value = obj.text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    dispatch(notifySuccess("Copied to clipboard!"));
  };
  const { columnName, start, end, original, index, alternate } = props;
  let row = {};
  const p = original;
  p.map((obj) => {
    row[obj.f] = obj;
    return null;
  });

  if (row[start] && row[end]) {
    return (
      <PrepareAddressHTML
        copyToClipboard={copyToClipboard}
        lat={row[start].v}
        lng={row[end].v}
        value={row[columnName].v}
        index={columnName + "_" + index}
      />
    );
  } else if (row[alternate]) {
    const val = ("" + row[alternate].v).split(",") || [];

    if (val && val.length > 1)
      return (
        <PrepareAddressHTML
          copyToClipboard={copyToClipboard}
          lat={val[0]}
          lng={val[1]}
          value={row[columnName].v}
          index={columnName + "_" + index}
        />
      );
  } else {
    return null;
  }
  return null;
};
export const prepareCalendar = (data) => {
  const {
    name,
    slots,
    weeklySlots,
    after,
    interval,
    allDay,
    freq,
    count,
    until,
    byDay,
    byMonth,
    byMonthDay,
  } = data;

  const cal = {
    name,
  };

  if (freq === "YEARLY" && byMonth.length && byMonthDay.length) {
    byMonth.sort((a, b) => a - b);
    byMonthDay.sort((a, b) => a - b);

    slots.map((slot) => {
      const startDate = moment.utc(
        moment().format("Y") + "-" + byMonth[0] + "-" + byMonthDay[0],
        "Y-M-D"
      );
      const startTime =
        startDate.format("YYYY-MM-DD") +
        " " +
        moment(slot.startTime, "YYYY-MM-DD HH:mm").format("HH:mm");

      const endDate = moment.utc(
        moment().format("Y") + "-" + byMonth[0] + "-" + byMonthDay[0],
        "Y-M-D"
      );

      const endTime =
        endDate.format("YYYY-MM-DD") +
        " " +
        moment(slot.endTime, "YYYY-MM-DD HH:mm").format("HH:mm");

      const e = {
        summary: name,
        start: allDay
          ? startDate.startOf("day")
          : moment(startTime, "YYYY-MM-DD HH:mm"),
        end: allDay
          ? endDate.add(1, "day").startOf("day")
          : moment(endTime, "YYYY-MM-DD HH:mm"),
        allDay,
      };

      const settings = {
        freq,
        count,
        interval,
      };

      if (after === "ON") settings.until = until;
      settings.byMonth = byMonth;
      settings.byMonthDay = byMonthDay;

      e && e.repeating && e.repeating(settings);
      return null;
    });
  } else if (freq === "WEEKLY") {
    byDay.map((d) => {
      const startTime =
        moment.utc().day(d).startOf("day").format("YYYY-MM-DD") +
        " " +
        moment
          .utc(weeklySlots[d].startTime, "YYYY-MM-DD HH:mm")
          .format("HH:mm");
      const endTime =
        moment.utc().day(d).startOf("day").format("YYYY-MM-DD") +
        " " +
        moment.utc(weeklySlots[d].endTime, "YYYY-MM-DD HH:mm").format("HH:mm");

      const e = {
        summary: name,
        start: weeklySlots[d].allDay
          ? moment.utc().day(d).startOf("day")
          : moment(startTime).toISOString(),
        end: weeklySlots[d].allDay
          ? moment.utc().day(d).startOf("day").add(1, "day")
          : moment(endTime).toISOString(),
        allDay: weeklySlots[d].allDay,
      };
      const settings = {
        freq,
        // count,
        // interval
      };

      if (after === "ON") settings.until = until;
      settings.byDay = [d];

      e && e.repeating && e.repeating(settings);
      return null;
    });
  }
  return cal.toString();
};

export const errorHandler = (error, dispatch, callback) => {
  if (error?.message?.includes("HINT")) {
    let message = error.message.split(":")[1];
    if (message.includes("Device already in used")) {
      dispatch(notifyError("Device is already attached"));
    } else if (message.includes("Data truncation")) {
      dispatch(notifyError("Data is  too long"));
    } else if (error?.message.includes("Device has no last position")) {
      dispatch(notifyError("Device has no last position"));
    } else if (message.includes("Duplicate entry")) {
      dispatch(notifyError("Duplicate entry"));
    } else if (error?.statusCode === "501") {
      let message = error.message.split(":")[1];
      dispatch(notifyError(message.replace(")", "")));
    } else {
      dispatch(notifyError(message.replace(")", "")));
    }
  } else if (error?.statusCode === "440") {
    dispatch(notifyError(error?.message));
    window.location.replace("/logout");
  } else if (error?.statusCode === "501") {
    let message = error.message.split(":")[1];
    dispatch(notifyError(message.replace(")", "")));
  } else {
    if (!axios.isCancel(error)) {
      dispatch(notifyError("somthingWentWrongMessage"));
    }
  }
  callback && typeof callback === "function" && callback(error);
};

export const categoriesList = [
  "default",
  "arrow",
  "car",
  "pickup",
  "doublecabinpickup",
  "threetonpickup",
  "van",
  "suv",
  "offroad",
  "minibus",
  "smallbus",
  "bus",
  "train",
  "tram",
  "trolleybus",
  "truck",
  "forklift",
  "tractor",
  "dumper",
  "roadroller",
  "excavator",
  "boomloader",
  "crane",
  "dumptruck",
  "tanker",
  "flatbedtruck",
  "container",
  "mixer",
  "bulldozer",
  "skidloader",
  "plane",
  "helicopter",
  "boat",
  "ship",
  "bicycle",
  "motorcycle",
  "scooter",
  "person",
  // 'shovel',
  "biggenerator",
  "generator",
  "animal",
  "tricycle",
];

export const widgetCategories = {
  1: <Translate i18nKey="statistics" />,
  2: <Translate i18nKey="graphs" />,
  3: <Translate i18nKey="sensors" />,
};

export const Api = "http://185.230.208.33:3003";

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export function setIconOnMap2(
  icon,
  status,
  statusType,
  userViewType,
  iconColor
) {
  let width = "50px",
    height = "50px";
  // let color = "#00BF4E";
  // if (status === "online") color = "#00BF4E";
  // else if (status === "offline") color = "#FF0000";
  // else if (status === "idling") color = "#ffc107";
  // else if (status === "stop") color = "#87CEFA";
  // else if (status === "parking") color = "#1E90FF";
  // // else if (status === "stop") color = "#000";#ffc107
  // else if (status === "gpsNotUpdated") color = "#1976d2";
  // else if (status === "not-updated") color = "#1976d2";
  // else color = "#00BF4E";

  const viewUserType = userViewType; // Assuming you have the value of viewUserType
  let color;

  if (viewUserType === "unitStatus") {
    // Set color based on device.status
    switch (status) {
      case "online":
        color = "#00BF4E"; // Green
        break;
      case "offline":
        color = "#FF0000"; // Red
        break;
      case "gpsNotUpdated":
        color = "#1976d2"; // Grey
        break;
      default:
        color = "#00BF4E"; // Default to Green if status is not recognized
    }
  } else if (viewUserType === "motionStatus") {
    const pp = statusType?.label || "";
    switch (pp) {
      case "online":
      case "moving":
        color = "#00BF4E"; // Green
        break;
      case "parking":
        color = "#1E90FF"; // Blue
        break;
      case "idling":
        color = "#ffc107"; // Yellow
        break;
      case "stop":
        color = "#87CEFA"; // Light Blue
        break;
      case "not-updated":
        color = "#1976d2"; // Grey
        break;
      default:
        color = "#FF0000"; // Default to red if motion status is not recognized
    }
  } else {
    color = iconColor;
    // Set color based on pp (position.icons.playicon.label)
  }

  switch (icon) {
    case "car":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <g>
       <g>
         <path style="fill:${color}" d="M16.8,9.1L16.3,9c0.1-3.5,0.1-6.1,0.1-6.5c0,0,0,0,0-0.1C15.4,1.6,13.8,1,12,1S8.6,1.6,7.6,2.5l0,0
           c0,0.3,0,3.1,0,6.5L7.2,9.1C7.1,9.1,7,9.3,7.1,9.5c0,0,0,0.1,0,0.1l0.5-0.2C7.7,14,7.9,19.8,8.2,22.5l0,0c0,0.2,0.1,0.3,0.3,0.3
           C8.6,23,8.7,23,8.7,23h0.7c0.1,0,0.1,0,0.1-0.1c0,0,1.6,0.1,2.5,0.1c0.9,0,2.3-0.1,2.3-0.1c0,0.1,0.1,0.1,0.1,0.1h0.7
           c0.1,0,0.1,0,0.1-0.1c0.1,0,0.3-0.2,0.3-0.4c0.2-2.9,0.3-8.6,0.5-13.1l0.6,0.2c0,0,0,0,0-0.1C17,9.4,17,9.2,16.8,9.1z"/>
         <path style="fill:#000;" d="M8.3,10.4c0,0,0,0,0,0.1c0,0.9,0,4.9,0,4.9s0,0.1,0,0.1c0,0,0,0,0,0c0-0.1,0.2-0.4,0.3-0.5
           c0-0.1,0-0.2,0-0.2s0-3.7,0-3.7s0-0.1-0.1-0.2C8.5,10.7,8.4,10.4,8.3,10.4L8.3,10.4L8.3,10.4z"/>
         <path style="fill:#000;" d="M8.6,16.2C8.6,16.2,8.6,16.2,8.6,16.2c-0.1,0.1-0.3,0.3-0.3,0.4s0,0.9,0,1c0,0.1,0,0.1,0,0.1s0,0,0.1,0
           c0.1-0.1,0.3-0.6,0.3-0.7c0-0.1,0-0.4,0-0.6C8.7,16.2,8.7,16.2,8.6,16.2L8.6,16.2z"/>
         <path style="fill:#000;" d="M15.8,10.4L15.8,10.4c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.1-0.1,0.1-0.1,0.2s0,3.7,0,3.7s0,0.1,0,0.2
           s0.2,0.4,0.3,0.5c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0-4,0-4.9C15.8,10.4,15.8,10.4,15.8,10.4L15.8,10.4z"/>
         <path style="fill:#000;" d="M15.4,16.2c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.4,0,0.6s0.3,0.7,0.3,0.7c0,0,0,0,0.1,0c0,0,0,0,0-0.1
           c0-0.1,0-1,0-1C15.8,16.5,15.6,16.3,15.4,16.2C15.5,16.2,15.4,16.2,15.4,16.2L15.4,16.2z"/>
       </g>
       <path style="fill:#000;" d="M10.6,9.7c0.4-0.1,0.9-0.1,1.5-0.1c1.9,0,3.1,0.7,3.1,0.7s0.5-0.6,0.5-1.3c0-0.7-1.2-1.4-2.7-1.5
         C11.8,7.8,10.8,8.6,10.6,9.7z"/>
       <path style="fill:#000;" d="M13,7.4c-0.3,0-0.6,0-0.9,0c-2,0-3.7,0.7-3.7,1.6c0,0.6,0.5,1.2,0.5,1.2s0.6-0.3,1.7-0.5
         C10.8,8.6,11.8,7.8,13,7.4z"/>
       <path style="fill:#000;" d="M12,19c1.2,0,2-0.1,2.2-0.2c0.2,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.8-0.1-0.8c0-0.1-0.2-0.3-0.4-0.3h-2.5
         c-0.4,0.4-0.6,1-0.7,1.7C11.3,19,11.7,19,12,19z"/>
       <path style="fill:#000;" d="M9.3,17.7l-0.1,0.8c0,0.1,0.3,0.2,0.5,0.3c0.1,0,0.6,0.1,1.3,0.1c0-0.6,0.3-1.2,0.7-1.7H9.6
         C9.4,17.4,9.3,17.5,9.3,17.7z"/>
       <path style="fill:${color}" d="M10.3,6.5c0,0,0-0.1,0-0.3V2.9c0-0.2,0-0.3,0-0.3l0,0c0,0,0,0.1,0,0.3v3.3C10.4,6.4,10.4,6.5,10.3,6.5
         L10.3,6.5z"/>
       <path style="fill:${color}" d="M13.6,6.5c0,0,0-0.1,0-0.3V2.9c0-0.2,0-0.3,0-0.3l0,0c0,0,0,0.1,0,0.3v3.2C13.7,6.4,13.7,6.5,13.6,6.5
         L13.6,6.5z"/>
       <path style="fill:${color}" d="M9.7,16.6c0,0,0-0.2,0-0.5v-5c0-0.3,0-0.5,0-0.5l0,0c0,0,0,0.2,0,0.5v5C9.7,16.4,9.7,16.6,9.7,16.6L9.7,16.6z
         "/>
       <path style="fill:${color}" d="M14.4,16.6c0,0,0-0.2,0-0.5v-5c0-0.3,0-0.5,0-0.5l0,0c0,0,0,0.2,0,0.5v5C14.5,16.4,14.4,16.6,14.4,16.6
         L14.4,16.6z"/>
     </g>
   </g>
   </svg>`;
    case "van":
      return `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve" style="width:${width}; height:${height}">
        <g>
          <path style="fill:${color}" d="M16.1,21.3c0-1.3,0-15.1,0-15.5c0-0.8,0-2.2-0.3-2.7S15,1.3,14.4,1.2c-0.6-0.1-4.4-0.3-5,0
            C8.6,1.6,8.3,2.4,8.1,3C7.9,3.6,7.9,6.6,7.9,7.2c0,0.1,0,13.2,0,13.9L16.1,21.3z"/>
        </g>
        <path style="fill:#000000;" d="M10,7.9c0,0-1.2-1.7-0.9-2c0.3-0.3,4.4-0.8,5.7,0C15,6.1,14.1,8,14.1,8L10,7.9L10,7.9z"/>
        <path style="fill:#000000;" d="M9.7,8.8V8c0,0-0.9-1.6-1.1-1.4C8.4,6.9,8.4,8,8.4,8.7L9.7,8.8L9.7,8.8z"/>
        <path style="fill:#000000;" d="M15.5,8.8c0-0.9-0.1-1.9-0.1-2.1c-0.1-0.2-0.2-0.4-0.4-0.3c-0.2,0-0.6,1.3-0.7,1.8c0,0.1,0,0.3,0,0.6
          C14.3,8.8,15.5,8.8,15.5,8.8z"/>
        <g>
          <path style="fill:${color}" d="M15.7,5.1v0.8l1.4,0.1c0-0.1,0-0.1,0-0.2C17.1,5.4,15.9,5,15.7,5.1z"/>
        </g>
        <g>
          <path style="fill:${color}" d="M8.3,5.1v0.8L6.9,5.9c0-0.1,0-0.1,0-0.2C6.9,5.4,8.1,5,8.3,5.1z"/>
        </g>
        <path style="fill:${color}" d="M7.9,21.1c0,0.2-0.1,0.2,0.1,0.5c0.3,0.3,0.8,1.3,1.4,1.4c0.6,0.1,4.5,0.1,5.1,0c0.8-0.2,1.2-0.8,1.4-1.3
          c0.1-0.1,0.1-0.2,0.1-0.5h-0.3C15.7,21.1,7.9,21.1,7.9,21.1z"/>
        <path style="fill:#000000;" d="M8.4,20.2c0,0.2,0,0.4,0,0.5c0.1,0.5,0.8,1,1.4,1.2c0.5,0.2,3.6,0.2,4.2,0.1c0.6-0.1,1.1-0.6,1.4-1
          c0.2-0.2,0.1-0.5,0.1-0.8L8.4,20.2L8.4,20.2z"/>
        <path style="fill:${color}" d="M10.1,22.4c0,0.1-0.1,0.2-0.2,0.2H9.3c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h0.6
          C10.1,22.2,10.1,22.3,10.1,22.4L10.1,22.4z"/>
        <path style="fill:${color}" d="M14.8,22.4c0,0.1-0.1,0.2-0.2,0.2h-0.6c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h0.6
          C14.8,22.2,14.8,22.3,14.8,22.4L14.8,22.4z"/>
        </svg>`;
    case "helicopter":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
     <g>
       <path style="fill:#000;" d="M15.8,12.3L15.8,12.3c0.2,0,0.3-0.1,0.3-0.3V7.5c0-0.2-0.1-0.3-0.3-0.3l0,0c-0.2,0-0.3,0.1-0.3,0.3V12
         C15.4,12.1,15.6,12.3,15.8,12.3z"/>
       <path style="fill:#000;" d="M8,12.3L8,12.3c0.2,0,0.3-0.1,0.3-0.3V7.5c0-0.2-0.1-0.3-0.3-0.3l0,0c-0.2,0-0.3,0.1-0.3,0.3V12
         C7.6,12.1,7.8,12.3,8,12.3z"/>
       <path style="fill:#000;" d="M13.2,1.2c0.9,0.3,1.9,0.7,2.4,1.8c0,0,0.4,1.4-3.4,1.4C12.2,4.5,8.5,5,8.1,3c0,0,0.3-1.3,2.5-1.8
         C11.4,0.9,12.4,0.9,13.2,1.2z"/>
       <path style="fill:#000;" d="M12.5,18.5h2.1c0,0,0.5,0,0.6,0.5c0,0,0,0.5-0.5,0.5h-2.2C12.5,19.5,12.5,18.5,12.5,18.5z"/>
       <path style="fill:${color}" d="M15.6,3.1c0,0-0.2,0.9-1.5,0.8c0,0-0.2,0-0.9-0.4c-0.3-0.1-0.6-0.3-0.9-0.3s-0.6,0-0.9,0
         c-0.4,0-0.7,0.2-1.1,0.4C10,3.9,9.4,4.1,8.6,3.5L8.1,3c0,0-1,1.6,0,4.4c0,0,0.8,2.6,2.2,4.2c0,0,0.1,0.3,0.2,1.8
         c0,0,0.5,6.2,1.1,8.4c0,0,0.4,0.7,0.6,0c0,0,0.9-2.8,1.2-9.9c0,0,1.1-0.5,2.4-4.7C15.8,7.1,16.5,4.8,15.6,3.1z"/>
       <path style="fill:#000;" d="M14.3,18.2c0,0,0-2.2-0.3-3.2c0,0-0.2,0.3-0.2,3.2c0,0,0,1.6,0,2.2c0,0,0,1.4,0.2,2.6c0,0,0.2-0.1,0.2-2.6
         C14.3,20.3,14.3,18.5,14.3,18.2z"/>
       <path style="fill:#000;" d="M12,5.5c0,0,7.2,1.7,10.3,2.6c0,0,0.7,0.1,0.5,0.9c0,0-0.1,0.5-1,0.3c0,0-8-2.8-9.9-3.4c0,0-7.5-1.9-10.5-2.7
         c0,0-0.5-0.3-0.2-1c0,0,0.2-0.3,0.8-0.2C2,2,11.3,5.3,12,5.5z"/>
       <ellipse style="fill:#000;" cx="11.9" cy="5.6" rx="1.1" ry="1.1"/>
     </g>
     </svg>`;
    case "tractor":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 30 30" style="width:${width}; height:${height}" xml:space="preserve" >
     <g>
       <path style="fill:#000000;" d="M16,13.1H9l-1.5,0.5v6.9l9.2-0.3C16.7,20.3,17.2,13.7,16,13.1z"/>
       <g>
         <g>
           <path style="fill:${color}" d="M7.2,21.9C7.2,23.1,7.8,23,7.8,23h8.2c0.8-0.1,0.8-1.2,0.8-1.2l-0.1-0.3c0-0.9,0-0.9,0-0.9v-4.5
             c0-0.7,0-1.4,0-1.4l0.1-0.3v-0.7c0-0.5-0.5-0.8-0.5-0.8h-0.5L15.1,2.3C15,1.3,14.5,1,14.5,1h-1.4l0,0h-2.3l-0.1,0.1L9.4,1.1
             c-0.5,0.2-0.5,1-0.5,1L8.5,7.3l-0.3,5.6L7.6,13c-0.4,0.2-0.5,0.7-0.5,0.7v0.7 M8.5,19H7.8v-4.4c0.2,0.3,0.7,0.9,0.7,0.9V19z
              M16.1,19h-0.7v-3.5c0,0,0.4-0.4,0.7-0.9V19z M15.6,14.9H8.4c-0.2-0.3-0.6-1-0.6-1c4.8-1.5,8.2,0,8.2,0
             C15.8,14.3,15.6,14.9,15.6,14.9z"/>
           <rect x="7.8" y="6.5" style="fill:#000000;" width="1.2" height="0.7"/>
           <rect x="15" y="6.5" style="fill:#000000;" width="1.2" height="0.7"/>
           <path style="fill:#000000;" d="M7.7,10.2L7.7,10.2c-0.3,0-0.5-0.3-0.5-0.7V4.2c0-0.4,0.2-0.7,0.5-0.7l0,0c0.3,0,0.5,0.3,0.5,0.7v5.3
             C8.2,9.9,8,10.2,7.7,10.2z"/>
           <path style="fill:#000000;" d="M16.3,10.2L16.3,10.2c-0.3,0-0.5-0.3-0.5-0.7V4.2c0-0.4,0.2-0.7,0.5-0.7l0,0c0.3,0,0.5,0.3,0.5,0.7v5.3
             C16.9,9.9,16.7,10.2,16.3,10.2z"/>
         </g>
         <path style="fill:#000000;" d="M6.8,22L6.8,22C6.4,22,6,21.5,6,20.9v-5.5c0-0.6,0.4-1.1,0.8-1.1l0,0c0.4,0,0.8,0.5,0.8,1.1v5.5
           C7.5,21.5,7.3,22,6.8,22z"/>
         <path style="fill:#000000;" d="M17.2,22L17.2,22c-0.4,0-0.8-0.5-0.8-1.1v-5.5c0-0.6,0.4-1.1,0.8-1.1l0,0c0.4,0,0.8,0.5,0.8,1.1v5.5
           C18,21.5,17.6,22,17.2,22z"/>
       </g>
     </g>
     </svg>`;
    case "bicycle":
      return `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve" style="width:${width}; height:${height}">
    <g>
      <path style="fill:${color}" d="M17.2,9.3c-0.1-0.8-0.8-2.1-0.8-2.1c0.7,0,0.4-0.4,0.4-0.4C16.2,6.7,16.3,6,16.3,6c-0.5-0.3-1.4-0.1-1.4-0.1
        c-0.1,0.4-0.5,0-0.5,0c-0.4-0.2-2.1-0.2-2.1-0.2V1.6c-0.5-1.4-1,0-1,0l-0.1,4.1c-1.4,0-2,0.2-2,0.2C9.1,5.6,8.4,5.7,8.4,5.7
        C7.6,5.9,7.7,6.5,7.7,6.5C7.1,6.6,7,6.9,7,6.9c0,0.4,0.7,0.4,0.7,0.4c-1.2,1.9-1.2,4.5-1.2,4.5c0.4,0.6,0.8,1.6,0.8,1.6
        c0.3,1.7,1.1,2.9,1.1,2.9c1.1,1.6,3,1.3,3,1.3v5c0.1,0.4,0.6,0.5,0.6,0.5c0.4,0,0.4-0.4,0.4-0.4l0.2-5.3h1.4
        c1.9-0.4,2.7-3.4,2.7-3.4c0.1-0.6,0.3-0.9,0.3-0.9c0.4-0.5,0.6-1.8,0.6-1.8C17.6,10.5,17.2,9.3,17.2,9.3z M11.5,8.5
        c0,0-0.6,0-1,0.4c0,0-0.3-1.1-0.9-1c0,0-0.7-0.1-0.8,0.7c0,0,0.1,2,0.4,3.9c0,0,0.1,0.3-0.4-0.4c0,0-0.3-0.4-0.6-0.7
        c0,0-0.1-3,0.1-3.5c0,0,0.4-0.8,1.6-1.6c0,0,0.8,0.2,1.7,0.2V8.5L11.5,8.5z M15.8,11.5c0,0-0.6,0.8-1,1.2c0,0,0.4-1.2,0.4-1.6
        c0,0-0.2-1.2-1.2-0.9c0,0-0.2-1.5-1.6-1.7V6.5h1.4c0,0,0.6-0.3,1,0.3c0,0,1,0.4,1.1,1.9C15.9,8.6,16.1,10.8,15.8,11.5z"/>
      <path style="fill:#000000;" d="M13,13.4c0.5-0.2,1.1-0.6,1.6-1.4c0.2-0.4,0.4-0.9,0.3-1.4c0-0.6-0.4-1.4-1.3-2.1C13,8.2,12.3,8,11.7,8.2
        c-0.8,0.1-1.7,0.6-2.1,2c0,0-0.6,2.7,1.9,3.3C12,13.6,12.5,13.6,13,13.4z"/>
      <path style="fill:#FFCB88;" d="M13.8,8.8c0,0-0.3-0.7-1.2-1.1c-0.4-0.2-1-0.2-1.4,0.1c-0.3,0.2-0.6,0.6-1,1.1C10.2,8.9,11.7,7.9,13.8,8.8z"/>
    </g>
    </svg>`;
    case "animal":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
    <path style="fill:${color}" d="M11.7,1c0,0,1.2,0,1.1,0.5c0,0-0.3,1.3,0.4,1.8c0,0-0.1,0.1,1-0.3c0,0,0.1,0.3-0.7,1.2c0,0,0.2,1.1-0.2,1.4
      c0,0-0.1,0.1,0.2,0.5c0,0,0.8,1.2,1.7,3.6c0,0-0.8,3.1,0.5,5.4c0,0,1.6,3.4-2.8,3.9c0,0-2.6,0.7-4.4-1.3c0,0-0.9-1.1-0.2-2.8
      c0,0,0.8-2.9,0.4-5.3c0,0,0-0.7,0.9-2.2c0,0,0.4-0.9,0.6-1.3c0,0-0.6-0.5-0.3-1.8c0,0-0.4-0.2-0.8-1.2c0,0,0.2,0,1,0.3
      c0,0,0.2-0.1,0.3-0.2c0,0,0.2-0.2,0.2-0.4c0,0,0.1-0.5-0.1-1.2C10.6,1.5,10.4,1.1,11.7,1z"/>
    <path style="fill:#000;" d="M12.2,18.1c0.1,0,0.2,0,0.3,0.2c0,0,0.6,0.4,0.5,1.4c0,0,0,0.7-0.4,1.1c0,0-0.6,0.6-0.2,1.5c0,0,0.2,0.3,0,0.7
      c0,0-0.3-0.2-1-0.3c0,0-1.2-0.3-0.7-1.7c0,0,0-0.5,1-1.1c0,0,0.3-0.2,0.4-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3
      C12,18.3,12.1,18.1,12.2,18.1z"/>
    <path style="fill:#000;" d="M12.3,3.4c0,0,0.6-0.7-0.7-0.7c0,0-1,0.1-0.8,0.7c0,0,0.5,0.7,0.6,1.2c0,0,0.3,0.5-0.3,1.1c0,0-0.3,0.4,0.1,0.9
      c0,0,0.3,0.1-0.4,1.4c0,0-0.4,0.6,0.3,0.8c0,0,0.8,0.1,0.4,0.7c0,0-0.1,0.5,0.5,0.5c0,0,1.1,0.1,1.1-0.2c0,0-0.4-0.7,0.1-0.7
      c0,0,0.2,0.1,0.1-0.4c0,0,0-0.9-0.7-1.4c0,0-0.2-0.4,0.3-1.1c0,0,0.3-0.3-0.2-0.7c0,0-0.6-0.1,0-0.9C12.7,4.7,13.3,3.9,12.3,3.4z"/>
    <path style="fill:#000;" d="M10.7,1.7c0,0-0.3-0.1,1.1-0.1c0,0,1.1-0.1,1.1,0.1c0,0,0.2,0.1-1.1,0.1C11.7,1.7,10.7,1.8,10.7,1.7z"/>
    </svg>`;
    case "motorcycle":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve" style="width:${width}; height:${height}">
   <path style="fill:${color}" d="M42.5,20.2c-0.4-0.7-1.2-0.9-1.2-0.9c-0.4-2.2-2.4-1.2-2.4-1.2c-0.2,0.2-0.2,0.2-0.2,0.2c-0.7-0.3-2.8-1-2.8-1
     c-0.6-4.5-3.6-6.3-3.6-6.3V7.6C32.1,5.2,30,5,30,5c-2,0.2-2.2,2.6-2.2,2.6v3.2c-3.2,2.3-3.5,6.3-3.5,6.3c-2.1,0.6-2.8,1.1-2.8,1.1
     c-0.4-0.7-1.5-0.4-1.5-0.4c-1,0.3-1.2,1.5-1.2,1.5c-0.4,0.2-0.9,0.5-0.9,0.5c-0.5,0.9-0.1,1.7-0.1,1.7c0.7,0.8,1.3,0.2,1.3,0.2
     c0.1,5.1,1.2,8.4,1.2,8.4c0.8,2.6,2.1,3.7,2.1,3.7c0.7,2.7,1.7,5.7,1.7,5.7c-0.2,0.3-0.6,1.4-0.6,1.4c-0.8,2.3-0.7,5.7-0.7,5.7
     c0,2.5,0.4,3.7,0.4,3.7c0.9,2.8,3.2,4.1,3.2,4.1c1.5,0.8,3.1,0.7,3.7,0.7c0.2,0,0.4,0,0.6,0c5.2,0.2,6.6-5,6.6-5
     c0.4-1.2,0.3-4.2,0.3-4.2c0.2-3.9-1.2-6.3-1.2-6.3l1.7-5.7c1.6-1.5,2.4-4.8,2.4-4.8c0.7-3.6,0.7-7.2,0.7-7.2
     c0.8,0.7,1.3-0.2,1.3-0.2C42.6,21.2,42.5,20.2,42.5,20.2z M25.8,29.6c0,0-1.6,0.1-2.4-2c0,0-0.5-0.4-1.7-6.6c0,0,1,0,0.9-1
     c0,0,2.5-1.1,3.2-1.1V29.6z M36.7,27.6c-0.9,2.1-2.4,2-2.4,2V18.9C35,19,37.5,20,37.5,20c0,1.1,0.9,1,0.9,1
     C37.3,27.2,36.7,27.6,36.7,27.6z"/>
   <path style="fill:#000;" d="M30,21c0,0,1.9-0.2,3.3,2.3c0,0,1,1.6,0.9,4v3.2c0,0-0.1,2.8-2.9,3.2c0,0-1,0.1-2.9,0c0,0-2.4-0.5-2.7-3.2
     c0,0-0.2-3.2,0.2-5.2C25.9,25.3,26.6,21.3,30,21z"/>
   <path style="fill:${color}" d="M33.8,27.6c0,0-1.5-1.4-3.7-1.3c0,0-1.9-0.2-3.8,1.3c0,0,0-2.2,1.7-4c0,0,2.1-1.7,4,0
     C31.9,23.5,33.5,30.6,33.8,27.6z"/>
   <path style="fill:#000;" d="M34.3,43v5.7c0,0-0.2,2.5-1.8,3.6c0,0-0.7,0.7-2.4,0.7c0,0-1.4,0.1-2.2-0.5c0,0-1.9-1.2-2-3.8v-5.5c0,0,0.6,0.7,1.7,0.7
     l4.9,0.1C32.4,43.8,33.6,43.8,34.3,43z"/>
   <path style="fill:#000;" d="M30,10.1c0,0,2.2-0.1,4,2.5c0,0,1.6,2.1,1.8,4.9c0,0,0.4,1.8-2.2,1.3c0,0-1.5-0.4-3.7-0.4c0,0-2.8,0.1-4,0.5
     c0,0-1.9,0.4-1.8-1.4c0,0,0.2-2.7,1.8-5C26,12.5,27.6,10.1,30,10.1z"/>
   </svg>`;
    case "generator":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <polygon style="fill:#000;" points="2.2,4.5 21.3,4.5 21.9,19.4 2.2,19.5"/>
     <path style="fill:${color}" d="M22.4,4.5H21v1.9h-1V4.5H4v10.4H3V4.5H1.5C1,4.5,1,5,1,5v14c0,0.5,0.6,0.6,0.6,0.6h1.5v-1.9h1v1.9H20V9.1h1
       v10.4h1.5C23,19.5,23,19,23,19V5.1C22.9,4.5,22.4,4.5,22.4,4.5z M10.4,17.1H9.8l1.2-4.8H9.2l0.4-5.1c0,0,0.1-0.4,0.4-0.4h3.7
       c0,0,0.3-0.1,0.2,0.4l-1.2,3.1h2.1L10.4,17.1z"/>
   </g>
   </svg>`;
    case "biggenerator":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
      <path style="fill:${color}" d="M1,17.1c0-3.8,0-7.7,0-11.6c7.3,0,14.6,0,21.9,0c0,3.9,0,7.7,0,11.6c-0.1,0-0.2,0-0.3,0c-7.1,0-14.3,0-21.4,0
        C1.2,17.1,1.1,17.1,1,17.1z M16.5,11.3c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5s2,4.5,4.5,4.5S16.5,13.8,16.5,11.3z"/>
      <path style="fill:#000;" d="M1,18.4c0.1,0,0.2,0,0.3,0c7.1,0,14.3,0,21.4,0c0.1,0,0.2,0,0.3,0c0,1.3,0,2.6,0,3.8c-0.1,0-0.2,0-0.2,0c-1.5,0-2.9,0-4.4,0
        c-0.2,0-0.3-0.1-0.3-0.2c-0.3-0.3-0.7-0.7-1-1c-0.1-0.1-0.2-0.1-0.3-0.1c-3.1,0-6.3,0-9.4,0C7.1,20.9,7.1,21,7,21
        c-0.3,0.3-0.7,0.7-1,1c-0.1,0.1-0.2,0.2-0.4,0.2c-1.5,0-3.1,0-4.6,0C1,21,1,19.7,1,18.4z"/>
      <path style="fill:#000;" d="M1,1.7c2.6,0,5.2,0,7.7,0c0,0.4,0,0.8,0,1.3c-0.4,0-0.8,0-1.3,0c0,0.4,0,0.8,0,1.3c-1.7,0-3.5,0-5.2,0c0-0.4,0-0.8,0-1.3
        c-0.4,0-0.9,0-1.3,0C1,2.5,1,2.1,1,1.7z"/>
      <path style="fill:#000;" d="M15.2,11.3c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2C8.8,9.5,10.2,8,12,8C13.8,8.1,15.2,9.6,15.2,11.3z M12.6,13.5
        c0.3-0.9,0.6-1.9,0.9-2.8c-0.4,0-0.9,0-1.3,0c0.1-0.4,0.2-0.7,0.3-1.1c-0.4-0.2-0.8-0.3-1.2-0.4C11,10.1,10.8,11,10.4,12
        c0.4,0,0.9,0,1.3,0c-0.1,0.4-0.2,0.7-0.3,1.1C11.8,13.2,12.2,13.3,12.6,13.5z"/>
      </svg>`;
    case "tricycle":
      return `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
     <style type="text/css">
       .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#F27419;}
       .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#0698D8;}
       .st2{fill-rule:evenodd;clip-rule:evenodd;}
     </style>
     <g>
       <polygon class="st0" points="35.7,15.6 35.7,17 51.9,17 51.9,15.6 35.7,15.6 	"/>
       <path class="st1" d="M45.9,38.8c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3C43.6,39.9,44.6,38.8,45.9,38.8
         L45.9,38.8z"/>
       <path class="st1" d="M52.4,40.8l-14.8,0.3v-5.7c0-5.1,4.1-9.2,9.2-9.2h5.6V40.8L52.4,40.8z"/>
       <polygon class="st0" points="52.4,16.7 51,16.7 51,41.2 52.4,41.2 52.4,16.7 	"/>
       <path class="st0" d="M54.4,13c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H27.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7
         H54.4L54.4,13z"/>
       <path class="st1" d="M37.6,40.3H34v-2c0-0.9,0.8-1.7,1.7-1.7h2V40.3L37.6,40.3z"/>
       <path class="st0" d="M16.5,28.1c0.1-0.3-0.1-0.7-0.5-0.8c-0.3-0.1-0.7,0.1-0.8,0.5l-3,13.5c-0.1,0.3,0.1,0.7,0.5,0.8
         c0.3,0.1,0.7-0.1,0.8-0.5L16.5,28.1L16.5,28.1z"/>
       <path class="st1" d="M18.1,28c0-0.4-0.3-0.6-0.6-0.6h-1.6c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6h1.6
         C17.9,28.6,18.1,28.3,18.1,28L18.1,28z"/>
       <path class="st2" d="M18.3,26.8c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C17.2,27.3,17.7,26.8,18.3,26.8
         L18.3,26.8z"/>
       <path class="st2" d="M18.3,27.2c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7
         C17.6,27.5,17.9,27.2,18.3,27.2L18.3,27.2z"/>
       <polygon class="st0" points="28.6,33.4 18.2,33.4 18.2,34.7 28.6,34.7 28.6,33.4 	"/>
       <path class="st0" d="M29.8,32c0.1-0.3-0.2-0.7-0.5-0.7c-0.3-0.1-0.7,0.2-0.7,0.5l-1.5,8.3c-0.1,0.3,0.2,0.7,0.5,0.7
         c0.3,0.1,0.7-0.2,0.7-0.5L29.8,32L29.8,32z"/>
       <path class="st0" d="M15.9,30.1c0,0,6.5,8.5,7.6,10c0.1,0.1,0.3,0.2,0.4,0.2c2.8,0,27.8,0,27.8,0V42c0,0-23.4,0-27.9,0
         c-0.4,0-0.9-0.2-1.1-0.6c-1.6-2.2-7.1-9.6-7.1-9.6L15.9,30.1L15.9,30.1z"/>
       <path class="st1" d="M27.7,29.9c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C26.7,30.3,27.1,29.9,27.7,29.9L27.7,29.9z"/>
       <path class="st1" d="M31,30c0.6,0,1.2,0.5,1.2,1.2c0,0.6-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2C29.8,30.5,30.4,30,31,30L31,30z"/>
       <path class="st1" d="M31.1,30l-3.4-0.1l0,1.9c1-0.3,2-0.3,3.1,0.5L31.1,30L31.1,30z"/>
       <path class="st2" d="M13,39.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C11.4,40.3,12.1,39.6,13,39.6
         L13,39.6z M13,40.8c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4C12.6,40.9,12.8,40.8,13,40.8L13,40.8z"/>
       <path class="st2" d="M13,34.2c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9S6,45,6,41.2S9.1,34.2,13,34.2L13,34.2z M13,35.5
         c3.1,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6S9.9,35.5,13,35.5L13,35.5z"/>
       <path class="st2" d="M45.9,34.2c3.8,0,6.9,3.1,6.9,6.9s-3.1,6.9-6.9,6.9c-3.8,0-6.9-3.1-6.9-6.9S42,34.2,45.9,34.2L45.9,34.2z
          M45.9,35.5c3.1,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6S42.8,35.5,45.9,35.5L45.9,35.5z"/>
       <path class="st2" d="M26,38.9c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3C23.7,39.9,24.7,38.9,26,38.9L26,38.9z
         "/>
       <path class="st0" d="M25.9,41.2c-0.2-0.2-0.4-0.2-0.6,0l-2.2,2.1c-0.2,0.2-0.2,0.4,0,0.6c0.2,0.2,0.4,0.2,0.6,0l2.2-2.1
         C26.1,41.7,26.1,41.4,25.9,41.2L25.9,41.2z"/>
       <path class="st1" d="M26,40.6c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6C25.4,40.9,25.7,40.6,26,40.6L26,40.6z
         "/>
       <path class="st2" d="M24.9,43.6c0-0.2-0.2-0.4-0.4-0.4h-2.1c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h2.1
         C24.8,43.9,24.9,43.8,24.9,43.6L24.9,43.6z"/>
       <path class="st2" d="M45.9,39.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C44.3,40.3,45,39.6,45.9,39.6
         L45.9,39.6z"/>
       <polygon class="st0" points="32.1,11.7 31.2,12.5 35.7,17 36.6,16.2 32.1,11.7 	"/>
       <polygon class="st0" points="52.3,18.9 51.1,18.9 51.1,12.5 52.3,12.5 52.3,18.9 	"/>
     </g>
     </svg>`;
    case "boat":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <path style="fill:${color}" d="M12.2,1c0,0,5,5.3,4.7,11c0,0-0.1,6.9-1.4,11H8.3c0,0-1.2-4.4-1.3-9.8c0-1.7,0.2-3.4,0.8-5
       C8.6,6.2,9.9,3.4,12.2,1z"/>
     <path style="fill: #000;" d="M8.1,13.3c0,0-0.7-2.6,0.8-5.2c0,0,1.2-2.2,3.3-2.3c0,0,1.6,0,3,2c0,0,1.4,1.7,0.9,5.6c0,0,0-1.7-1.5-3.4
       c0,0-1-1.1-2.5-1.1c0,0-1.5,0-2.6,1.4C9.4,10.4,8.2,11.8,8.1,13.3z"/>
     <path style="fill: #000;" d="M9.5,14.5c0,0-0.1-1,1.3-1.1h2.7c0,0,1.1-0.1,1,1.1v5.9l-5-0.1L9.5,14.5z"/>
   </g>
   </svg>
   `;
    case "boomloader":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <polyline points="14.4,4.1 15,4.2 9,4.2 9.7,4.1 "/>
   <path style="fill:${color}" d="M16.6,4.6l0,0.2H7.4c0,0,0-0.1,0-0.2c0-0.1,0.3-0.4,0.7-0.4"/>
   <polygon style="fill:${color}" points="16.7,22 7.3,22 7.3,9.4 16.7,9.4 "/>
   <rect x="7.4" y="16.1" style="fill:${color}" width="3.3" height="2.7"/>
   <polygon style="fill:${color}" points="7.4,18.9 7.9,19.1 11.1,19.1 10.7,18.9 "/>
   <polygon style="fill:${color}" points="11.1,15.6 7.8,15.6 7.4,16.1 10.7,16.1 "/>
   <polygon style="fill:${color}" points="10.7,18.9 10.7,16.1 11.1,15.6 11.1,19.1 "/>
   <path style="fill:${color}" d="M15.9,23H8.1c-0.2,0-0.3-0.1-0.3-0.3v-1.9c0-0.1,0.2-0.3,0.3-0.3h7.8c0.2,0,0.3,0.1,0.3,0.3v1.9
     C16.3,22.9,16.1,23,15.9,23z"/>
   <polygon points="16.9,11 7.1,11 7.2,4.8 16.8,4.8 "/>
   <rect x="9.2" y="7.4" style="fill:#000;" width="1.2" height="0.7"/>
   <rect x="13.6" y="7.4" style="fill:#000;" width="1.2" height="0.7"/>
   <polygon style="fill:#000;" points="15.1,7.2 8.9,7.2 8.4,4.8 15.6,4.8 "/>
   <polygon style="fill:${color}" points="15.8,4.2 8.2,4.2 7.2,4.8 16.9,4.8 "/>
   <polygon style="fill:#000;" points="7.6,4.6 8.2,4.3 15.8,4.3 16.4,4.6 "/>
   <polygon style="fill:#000;" points="15.2,8.7 8.8,8.7 8.4,11 15.6,11 "/>
   <rect x="11.1" y="2.6" width="2.9" height="20.4"/>
   <rect x="11.1" y="20.5" style="fill:#000;" width="2.9" height="0.9"/>
   <rect x="10.9" y="2" width="3.2" height="0.7"/>
   <rect x="14.1" y="2.2" style="fill:#000;" width="0.2" height="0.4"/>
   <rect x="10.7" y="2.2" style="fill:#000;" width="0.2" height="0.4"/>
   <rect x="11.5" y="1.6" style="fill:#000;" width="2.2" height="0.4"/>
   <rect x="12.2" y="1" width="0.7" height="1"/>
   <g>
     <rect x="12.4" y="1" style="fill:#000;" width="0.3" height="19.7"/>
   </g>
   </svg>
   `;
    case "bulldozer":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <path style="fill:#000;" d="M11.3,10.6l-2,1.5c0,0-1.1,0.1-1,1.4l0,3.4l7.4-0.3v-3.6C15.7,12.7,16.3,12.4,11.3,10.6z"/>
   <path style="fill:${color}" d="M8,17.3c0.1,0.7,0.6,0.7,0.6,0.7h6.8c0.7,0,0.7-0.7,0.7-0.7L16,17.1c0-0.5,0-0.5,0-0.5v-2.6c0-0.4,0-0.8,0-0.8
     l0.1-0.2v-0.4c0-0.3-0.5-0.5-0.5-0.5h-0.5l-0.5-6.3C14.5,5.2,14.1,5,14.1,5h-1.2V4.7H11L11,5H9.9C9.4,5.2,9.4,5.6,9.4,5.6l-0.3,3
     L8.8,12l-0.5,0.1c-0.3,0.1-0.4,0.4-0.4,0.4v0.4 M9.1,15.6H8.6V13c0.1,0.1,0.5,0.5,0.5,0.5V15.6z M15.4,15.6h-0.6v-2.1
     c0,0,0.3-0.2,0.6-0.6V15.6z M15,13.2H9c-0.1-0.2-0.5-0.6-0.5-0.6c4-0.9,6.9,0,6.9,0C15.2,12.7,15,13.2,15,13.2z"/>
   <rect x="8.5" y="8.2" style="fill:#000;" width="1" height="0.4"/>
   <rect x="14.5" y="8.2" style="fill:#000;" width="1" height="0.4"/>
   <path style="fill:#000;" d="M8.4,10.4L8.4,10.4C8.1,10.4,8,10.2,8,9.9V6.9c0-0.2,0.2-0.5,0.4-0.5h0c0.2,0,0.4,0.2,0.4,0.5v3.1
     C8.8,10.3,8.7,10.4,8.4,10.4z"/>
   <path style="fill:#000;" d="M15.7,10.4L15.7,10.4c-0.3,0-0.4-0.2-0.4-0.5V6.9c0-0.2,0.2-0.5,0.4-0.5l0,0c0.2,0,0.4,0.2,0.4,0.5v3.1
     C16.1,10.3,15.9,10.4,15.7,10.4z"/>
   <path style="fill:#000;" d="M7.7,17.4L7.7,17.4C7.3,17.4,7,17,7,16.7v-3.1c0-0.4,0.3-0.7,0.6-0.7h0c0.3,0,0.6,0.3,0.6,0.7v3.1
     C8.3,17,8,17.4,7.7,17.4z"/>
   <path style="fill:#000;" d="M16.4,17.4L16.4,17.4c-0.4,0-0.7-0.3-0.7-0.7v-3.1c0-0.4,0.3-0.7,0.6-0.7h0c0.3,0,0.6,0.3,0.6,0.7v3.1
     C17,17,16.7,17.4,16.4,17.4z"/>
   <polygon style="fill:#000;" points="7.8,1.8 16.1,1.8 16.9,4.7 7.1,4.7 "/>
   <polygon style="fill:#000;" points="8.2,1.8 8.1,1 7.9,1.8 "/>
   <polygon style="fill:#000;" points="9.2,1.8 9.4,1 9.5,1.8 "/>
   <polygon style="fill:#000;" points="10.5,1.8 10.7,1 10.8,1.8 "/>
   <polygon style="fill:#000;" points="11.9,1.8 12,1 12.1,1.8 "/>
   <polygon style="fill:#000;" points="13.2,1.8 13.3,1 13.5,1.8 "/>
   <polygon style="fill:#000;" points="14.5,1.8 14.6,1 14.8,1.8 "/>
   <polygon style="fill:#000;" points="15.8,1.8 15.9,1 16.1,1.8 "/>
   <path style="fill:#000;" d="M9.5,22.7l0.2-2.3c0-0.2,0.3-0.3,0.6-0.3h3.2c0.3,0,0.6,0,0.6,0.3l0.2,2.3c0,0.2-0.3,0.3-0.6,0.3h-3.6
     C9.8,23,9.5,23,9.5,22.7z"/>
   <path style="fill:${color}" d="M11.3,22.5c-0.3,0-0.8-1-0.8-1.5c0,0,0.3-0.7,0.7-0.7h1.4c0.5,0,0.7,0.7,0.7,0.7c0,0.4-0.5,1.5-0.8,1.5H11.3z"
     />
   <polygon points="10.5,21 10,16.5 13.9,16.5 13.3,21 "/>
   </svg>
   `;
    case "bus":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <path style="fill:#000;" d="M16.2,1.9l0.2-0.6c0,0-1-0.4-2.1,0h-4L9.9,1.4L9.7,1.3c0,0-0.7-0.4-2.1,0v1L6.9,7.6l0.4,13.4l0.4,1.8l0.7,0l1,0l0.7-0.3
       h3.7l0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1h0.4c0.3,0,0.7,0,1-0.2l0.2-0.1l0.4-2.2V3.9L16.2,1.9z"/>
     <path style="fill:${color}" d="M17.1,3.3c-0.3-1.1-0.5-2.1-0.5-2.1C16.1,1,15.3,1,15.3,1c-1.1,0-1.1,0.2-1.1,0.2c-2.5-0.2-4.4,0-4.4,0
       C9.8,1.1,9.3,1,9.3,1C9.1,1,7.9,1,7.9,1C7.7,1,7.5,1.2,7.5,1.2c0,0.2-0.2,1.1-0.2,1.1C7.1,2.6,7,3.1,7,3.1v17.7
       c0,0.3,0.1,0.4,0.1,0.4c0.3,0.7,0.4,1.5,0.4,1.5C7.6,22.9,8,23,8,23h1.3c0.5,0,0.5-0.2,0.5-0.2c2.7,0.2,4.4,0,4.4,0
       c0.4,0.2,1.1,0.1,1.1,0.1c1.1,0,1.2-0.2,1.2-0.2c0.3-1.3,0.4-1.5,0.4-1.5c0.2-0.3,0.2-0.6,0.2-0.6L17.1,3.3L17.1,3.3z M15.3,1.2
       c0.5,0,0.9,0.1,0.9,0.1c0,0-0.4,0.2-0.9,0.2c-0.4,0-0.9-0.1-0.9-0.1C14.4,1.2,14.8,1.2,15.3,1.2z M8.7,1.2c0.5,0,0.9,0.1,0.9,0.1
       c0,0-0.4,0.2-0.9,0.2c-0.5,0-0.9-0.1-0.9-0.1C7.9,1.2,8.3,1.2,8.7,1.2z M8.7,23c-0.5,0-0.9-0.1-0.9-0.1c0-0.1,0.4-0.1,0.9-0.1
       c0.5,0,0.9,0.1,0.9,0.1C9.6,22.9,9.2,23,8.7,23z M15.3,23c-0.5,0-0.9-0.1-0.9-0.1c0-0.1,0.4-0.1,0.9-0.1s0.9,0.1,0.9,0.1
       C16.2,22.9,15.8,23,15.3,23z M14.2,22.5c-1.1,0.1-4.2,0-4.2,0c-2.6-0.3-2.5-1.9-2.5-1.9c1.5,0.4,4.4,0.5,4.4,0.5
       c3.1,0,4.6-0.5,4.6-0.5C16.3,22.3,14.2,22.5,14.2,22.5z M8.3,6.3V9H7.5V6.3C7.5,6.3,8.3,6.3,8.3,6.3z M7.5,6.1V3.8l0.8-0.2v2.6H7.5
       z M8.3,9.2v2.8H7.5V9.2H8.3z M8.3,12.1v2.8H7.5v-2.8C7.5,12.1,8.3,12.1,8.3,12.1z M8.3,15.1v2.8H7.5v-2.8L8.3,15.1L8.3,15.1z
        M8.3,18v2.6l-0.8-0.2V18C7.5,18,8.3,18,8.3,18z M13.2,5.6V4.3h0.6v1.3h1v1h-1v1.3h-0.6V6.6h-2.3v1.3h-0.7V6.6h-1v-1h1V4.3h0.6v1.3
       H13.2L13.2,5.6z M10.2,17.6v-1.3h0.6v1.3h2.4v-1.3h0.5v1.3h1.1v1h-1.1v1.3h-0.6v-1.3h-2.4v1.3h-0.6v-1.3h-1v-1H10.2L10.2,17.6z
        M16.5,20.4l-0.8,0.2V18h0.8V20.4z M16.5,17.8h-0.8v-2.8h0.8V17.8z M16.5,14.9h-0.8v-2.8h0.8V14.9z M16.5,12h-0.8V9.2h0.8V12z
        M16.5,9.1h-0.8V6.3h0.8V9.1z M16.5,6.1h-0.8V3.6l0.8,0.1V6.1z M12,3c0,0-2.1-0.1-4.5,0.5c0,0,0-0.7,0.8-1.4c0,0,0.7-0.5,2-0.6h3.5
       c0,0,0.5,0,1.3,0.2c0.5,0.2,1,0.5,1.3,0.9c0.1,0.2,0.2,0.5,0.3,0.8C16.5,3.5,14.9,3,12,3z"/>
   </g>
   </svg>`;
    case "container":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <g>
       <g>
         <g>
           <rect x="15.8" y="10.2" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
         <g>
           <rect x="7.2" y="10.2" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
       </g>
       <g>
         <g>
           <rect x="15.8" y="12.2" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
         <g>
           <rect x="7.2" y="12.3" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
       </g>
     </g>
     <g>
       <g>
         <g>
           <rect x="15.8" y="17.5" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
         <g>
           <rect x="7.2" y="17.5" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
       </g>
       <g>
         <g>
           <rect x="15.8" y="19.6" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
         <g>
           <rect x="7.2" y="19.6" style="fill:#000;" width="1.1" height="1.9"/>
         </g>
       </g>
     </g>
     <path style="fill:${color}" d="M14.7,23H9.3c-1,0-1.8-0.8-1.8-1.7V9.6c0-0.4,0.4-0.8,0.8-0.8h7.5c0.5,0,0.8,0.3,0.8,0.8v11.6
       C16.6,22.2,15.7,23,14.7,23z"/>
     <g>
       <rect x="9.6" y="8.2" style="fill:#000;" width="4.8" height="0.7"/>
     </g>
     <g>
       <path style="fill:#000;" d="M15.5,2.2H8.5C8.1,2.2,7.9,1.9,8,1.7C8.4,1.3,8.9,1,9.6,1h0v0.8h4.6V1.1h0c0.7,0,1.3,0.2,1.6,0.6
         C16.2,1.9,15.9,2.2,15.5,2.2z"/>
       <path d="M15.9,8.3H8.1C8,8.3,7.9,8.2,7.9,8.1V2.7c0-0.8,0.7-1.5,1.5-1.5h5.1c0.8,0,1.5,0.7,1.5,1.5v5.4
         C16.1,8.2,16,8.3,15.9,8.3z"/>
       <path style="fill:#000;" d="M15.6,5.2H8.4V4.5c0-1.2,1-2.1,2.2-2.1h2.9c1.2,0,2.2,0.9,2.2,2.1L15.6,5.2L15.6,5.2z"/>
       <path style="fill:#000;" d="M14.4,1.2H9.7l0,0C9.7,1,9.8,1,9.9,1h4.2C14.3,1,14.4,1.1,14.4,1.2L14.4,1.2z"/>
       <g>
         <path style="fill:#000;" d="M7.9,4.5H7.1c0,0-0.1,0-0.1,0.1v0.1c0,0,0,0.1,0.1,0.1h0.9c0,0,0.1,0,0.1-0.1V4.6C8.1,4.5,8,4.5,7.9,4.5z"/>
       </g>
       <g>
         <path style="fill:#000;" d="M16.1,4.5h0.9c0,0,0.1,0,0.1,0.1v0.1c0,0,0,0.1-0.1,0.1h-0.9c0,0-0.1,0-0.1-0.1V4.6C16,4.5,16,4.5,16.1,4.5z
           "/>
       </g>
     </g>
   </g>
   </svg>`;
    case "crane":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <path style="fill:#000;" d="M18.9,21.7L4.8,21.6c0,0-2,0-1.8-0.6v-2.6c0,0,0.4-0.7,2.4-0.7L19,17.8c0,0,2.1,0.1,2.1,0.6l0,2.6
       C21,21.1,21,21.7,18.9,21.7z"/>
     <path style="fill:${color}" d="M13.1,8.5L9.4,0.2c0,0-0.6-0.4-1.1,0L4.7,8.7c0,0-0.2,0.8-0.2,1l0.1,14c0,0,0.1,0.2,0.8,0.3h7.1
       c0,0,0.7,0,0.8-0.4V9.3C13.4,9.3,13.3,9,13.1,8.5z M6.3,9.6h3.8l-3.8,2.6V9.6z M6.3,14.6h3.8l-3.8,2.3V14.6z M6.3,19.4h3.8L6.3,22
       V19.4z M11.4,23H7.5l3.8-2.3V23z M11.4,18.3H7.5l3.8-2.4V18.3z M11.4,13.3H7.5l3.8-2.4V13.3z M6.8,8.4L8.9,3l2.5,5.4H6.8z"/>
     <path style="fill:${color}" d="M15.4,23l-0.1-6.4c0,0,0-0.9,0.6-0.8l2.8,0c0,0,0.7,0.1,0.8,1l0.1,6.2c0,0-0.1,1-0.6,1h-2.9
       C16.1,30,15.4,30,15.4,23z"/>
   </g>
   </svg>
   `;
    case "doublecabinpickup":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
   <g>
     <path style="fill:${color}" d="M15.7,5.1c0,0-0.2-1.7-0.5-3c0,0-0.1-1-1.7-1c0,0-2.3,0-3.7,0.1c0,0-1-0.2-1.2,1.4c0,0-0.4,1.2-0.5,2.5
       c0,0-1.3,0.4-1.3,1.3c0,0,1-0.2,1.3-0.5c0,0,0.1-0.1,0.1,0.4c0,0-0.1,2.7,0,4.8c0,0,0.3,9.4,0.4,10.9c0,0,0.1,0.9,0.7,0.9
       c0,0,0.9,0,1.2,0c0,0,0.2-0.1,0.5-0.1l2.4,0c0,0,0.1-0.1,0.2,0.1c0,0,0.7,0.1,1.2,0c0,0,0.6,0.1,0.7-1c0,0,0.2-7.8,0.3-10.5
       c0-0.3,0-0.7,0-1c0-1.2-0.1-3.4-0.1-4.1c0,0,0-0.2,0.5,0c0,0,0.8,0.2,0.9,0.2c0,0,0.1-0.5-0.7-1C16.5,5.5,16,5.2,15.7,5.1z"/>
     <path style="fill:#000;" d="M15.4,10.5h-0.7c0,0-0.2-1.9,0.1-2.6c0,0,0.3-0.8,0.6-1C15.4,6.9,15.4,10.1,15.4,10.5z"/>
     <path style="fill:#000;" d="M9.4,10.5c0,0-0.6,0.1-0.8,0c0,0-0.1-3.1,0-3.6c0,0,0-0.6,0.8,1.4C9.4,8.3,9.4,10.2,9.4,10.5z"/>
     <path style="fill:#000;" d="M9.6,7.8c0,0,0,0.2,0.5,0c0,0,0.7-0.1,1-0.1c0,0,0-0.3,0.6-0.4c0,0,0.8-0.1,1.1,0.1c0,0,0.2,0,0.2,0.3
       c0,0,0.6,0,1.3,0.2c0,0,0.3,0.1,0.3-0.3c0,0,0.3-1.5,0.6-2c0,0,0.1-0.4-0.6-0.6c0,0-2.7-0.8-5.7,0.1c0,0-0.3,0.2,0,0.9
       C9,6,9.5,7.4,9.6,7.8z"/>
     <path style="fill:#000;" d="M14.6,21.9c0,0,0.5,0.1,0.5-0.9c0,0,0.3-5.5,0.2-6.1c0,0-5.4-0.2-6.6,0c0,0,0.3,4.8,0.3,6.2c0,0,0,0.8,0.7,0.7
       C9.7,21.9,12.5,22.2,14.6,21.9z"/>
   </g>
   <path style="fill:#000;" d="M15.4,11h-0.7c0,0-0.2,1.9,0.1,2.6c0,0,0.3,0.8,0.6,1C15.4,14.5,15.4,11.3,15.4,11z"/>
   <path style="fill:#000;" d="M9.4,11c0,0-0.6-0.1-0.8,0c0,0-0.1,3.1,0,3.6c0,0,0,0.6,0.8-1.4C9.4,13.1,9.4,11.2,9.4,11z"/>
   </svg>
   `;
    case "dumper":
      return `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <polygon points="13.3,5 14.5,5 14.5,3.8 13.4,3.8 		"/>
     <g>
       <rect x="15.3" y="2.3" width="0.1" height="4.2"/>
       <g>
         <path style="fill: #000;" d="M16.4,1.9h-0.7c-0.1,0-0.3,0.2-0.3,0.4v4.1c0,0.2,0.1,0.4,0.3,0.4h0.7c0.1,0,0.3-0.2,0.3-0.4V2.3
           C16.6,2.1,16.5,1.9,16.4,1.9z"/>
         <g>
           <path style="fill: #000;" d="M15.4,2.3h0.3V2.1h-0.3C15.4,2.2,15.4,2.2,15.4,2.3z"/>
           <rect x="15.4" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="3.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="16.3" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.8" y="2.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="3.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.8" y="2.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="3.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="16.3" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="16.3" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.4" y="2.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="3.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
       <g>
         <path style="fill:${color}" d="M15,1.9h-0.7c-0.1,0-0.3,0.2-0.3,0.4v4.1c0,0.2,0.1,0.4,0.3,0.4H15c0.1,0,0.3-0.2,0.3-0.4V2.3C15.3,2.1,15.1,1.9,15,1.9
           z"/>
         <g>
           <path style="fill: #000;" d="M14,2.3h0.3V2.1h-0.3C14,2.2,14,2.2,14,2.3z"/>
           <rect x="14" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="3.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <path style="fill: #000;" d="M15,2.1v0.3h0.3c0-0.1,0-0.2-0.1-0.3H15z"/>
           <rect x="15" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="3.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14.5" y="2.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="3.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14.5" y="2.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="3.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="4.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="4.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="5.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="6.2" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15" y="2.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="3.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14" y="2.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="3.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="3.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="4.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="5.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="5.9" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
     </g>
   </g>
   <g>
     <polygon points="10.6,5.1 9.5,5.1 9.5,3.9 10.6,3.9 		"/>
     <g>
       <rect x="8.6" y="2.4" width="0.1" height="4.2"/>
       <g>
         <path style="fill: #000;" d="M7.6,2h0.7c0.1,0,0.3,0.2,0.3,0.4v4.1C8.6,6.8,8.5,7,8.3,7H7.6C7.4,7,7.2,6.8,7.2,6.5V2.4
           C7.3,2.2,7.4,2,7.6,2z"/>
         <g>
           <path style="fill: #000;" d="M8.6,2.4H8.2V2.2h0.3C8.6,2.2,8.6,2.3,8.6,2.4z"/>
           <rect x="8.3" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="3.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.3" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.7" y="2.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="3.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.7" y="2.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="3.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.3" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.3" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="8.3" y="2.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="3.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
       <g>
         <path style="fill:${color}" d="M8.9,2h0.7C9.8,2,10,2.2,10,2.4v4.1C10,6.8,9.9,7,9.7,7H8.9C8.8,7,8.6,6.8,8.6,6.5V2.4C8.7,2.2,8.8,2,8.9,2z"/>
         <g>
           <path style="fill: #000;" d="M9.9,2.4H9.6V2.2h0.3C9.9,2.2,9.9,2.3,9.9,2.4z"/>
           <rect x="9.6" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="3.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <path style="fill: #000;" d="M9,2.2v0.3H8.7c0-0.1,0-0.2,0.1-0.3C8.8,2.2,9,2.2,9,2.2z"/>
           <rect x="8.7" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="3.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.1" y="2.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="3.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.1" y="2.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="2.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="3.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="4.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="4.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="5.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="6.3" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="8.7" y="2.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="3.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.6" y="2.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="3.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="3.9" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="4.6" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="5.3" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="6" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
     </g>
   </g>
   <polygon points="7.8,1.7 8.3,1.1 8.7,1.3 7.9,1.8 	"/>
   <polygon points="16.1,1.7 15.6,1.1 15.3,1.3 16,1.8 	"/>
   <path style="fill:${color}" d="M9,1h6c0.2,0,1.3,0.8,1.3,1.2V3H7.7c0,0,0-0.3,0-0.8S8.8,1,9,1z"/>
   <path style="fill:${color}" d="M9,1.1h6c0.2,0,1.3,0.8,1.3,1.2v0.8H7.7c0,0,0-0.3,0-0.8C7.7,1.9,8.8,1.1,9,1.1z"/>
   <rect x="7.7" y="2.6" width="8.7" height="0.4"/>
   <path style="fill: #000;" d="M9.8,1.8h4.3c0.2,0,0.5,0.1,0.6,0.3v0.8H9.2V2.2C9.3,2,9.6,1.8,9.8,1.8z"/>
   <g>
     <polygon points="10.6,19 9.5,19 9.5,17.8 10.6,17.8 		"/>
     <g>
       <rect x="8.6" y="16.3" width="0.1" height="4.2"/>
       <g>
         <path style="fill: #000;" d="M7.6,15.9h0.7c0.1,0,0.3,0.2,0.3,0.4v4.1c0,0.2-0.1,0.4-0.3,0.4H7.6c-0.1,0-0.3-0.2-0.3-0.4v-4.1
           C7.3,16,7.4,15.9,7.6,15.9z"/>
         <g>
           <path style="fill: #000;" d="M8.6,16.3H8.2V16h0.3C8.6,16.1,8.6,16.2,8.6,16.3z"/>
           <rect x="8.3" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.7" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="7.7" y="16" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="7.7" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="8.3" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.3" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
       <g>
         <path style="fill:${color}" d="M8.9,15.9h0.7c0.1,0,0.3,0.2,0.3,0.4v4.1c0,0.2-0.1,0.4-0.3,0.4H8.9c-0.1,0-0.3-0.2-0.3-0.4v-4.1
           C8.7,16,8.8,15.9,8.9,15.9z"/>
         <g>
           <path style="fill: #000;" d="M9.9,16.3H9.6V16h0.3C9.9,16.1,9.9,16.2,9.9,16.3z"/>
           <rect x="9.6" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <path style="fill: #000;" d="M9,16v0.3H8.7c0-0.1,0-0.2,0.1-0.3L9,16L9,16z"/>
           <rect x="8.7" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.1" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.1" y="16" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.1" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="8.7" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="8.7" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="9.6" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="9.6" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
     </g>
   </g>
   <g>
     <polygon points="13.3,19 14.5,19 14.5,17.8 13.4,17.8 		"/>
     <g>
       <rect x="15.3" y="16.3" width="0.1" height="4.2"/>
       <g>
         <path style="fill: #000;" d="M16.4,15.9h-0.7c-0.1,0-0.3,0.2-0.3,0.4v4.1c0,0.2,0.1,0.4,0.3,0.4h0.7c0.1,0,0.3-0.2,0.3-0.4v-4.1
           C16.6,16,16.5,15.9,16.4,15.9z"/>
         <g>
           <path style="fill: #000;" d="M15.4,16.3h0.3V16h-0.3C15.4,16.1,15.4,16.2,15.4,16.3z"/>
           <rect x="15.4" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.8" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.8" y="16" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.8" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15.4" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15.4" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
       <g>
         <path style="fill:${color}" d="M15,15.9h-0.7c-0.1,0-0.3,0.2-0.3,0.4v4.1c0,0.2,0.1,0.4,0.3,0.4H15c0.1,0,0.3-0.2,0.3-0.4v-4.1
           C15.3,16,15.1,15.9,15,15.9z"/>
         <g>
           <path style="fill: #000;" d="M14,16.3h0.3V16h-0.3C14,16.1,14,16.2,14,16.3z"/>
           <rect x="14" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <path style="fill: #000;" d="M15,16v0.3h0.3c0-0.1,0-0.2-0.1-0.3L15,16L15,16z"/>
           <rect x="15" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14.5" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14.5" y="16" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="16.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="17.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="18.1" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="18.7" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="19.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14.5" y="20.1" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="15" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="15" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
         <g>
           <rect x="14" y="16.4" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="17" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="17.8" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="18.5" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="19.2" style="fill: #000;" width="0.3" height="0.3"/>
           <rect x="14" y="19.8" style="fill: #000;" width="0.3" height="0.3"/>
         </g>
       </g>
     </g>
   </g>
   <rect x="7.6" y="7.5" style="fill: #78909C;" width="8.7" height="15.5"/>
   <polygon points="7.2,6.5 16.8,6.5 16.3,7.5 7.6,7.5 	"/>
   <rect x="7.2" y="3.8" width="9.6" height="2.8"/>
   <polygon points="7.7,2.9 16.3,2.9 16.8,3.8 7.2,3.8 	"/>
   <polygon style="fill: #000;" points="7.1,4.1 7.2,3.8 7.2,6.5 7.1,6.2 	"/>
   <polygon style="fill: #000;" points="16.9,4.1 16.8,3.8 16.8,6.5 16.9,6.2 	"/>
   <path style="fill: #000;" d="M8.7,6.2H7.9L8.1,7v0.8H7.9v14.7c0,0,0.2-1.1,0.3-1.5c1-3,0.5-12.8,0.5-12.8h6.3c0,0-0.4,9.8,0.5,12.8
     c0.1,0.4,0.3,1.5,0.3,1.5V7.9h-0.2V7.1L16,6.3h-0.8l0,0L8.7,6.2L8.7,6.2z"/>
   <polygon points="15.8,7.9 16,7.5 16.4,6.5 16.4,6.2 16,6.2 15.8,7.1 	"/>
   <polygon points="8.1,7.9 7.9,7.5 7.6,6.5 7.6,6.2 7.9,6.2 8.1,7.1 	"/>
   <g>
     <polygon style="fill: #000;" points="7.6,6.2 7.5,5.9 7.3,5.1 7.3,4.9 7.5,4.9 7.6,5.6 		"/>
     <polygon style="fill: #000;" points="7.5,4.9 7.3,3.8 7.3,4.9 		"/>
   </g>
   <g>
     <polygon style="fill: #000;" points="16.4,6.2 16.5,5.9 16.7,5.1 16.7,4.9 16.5,4.9 16.4,5.6 		"/>
     <polygon style="fill: #000;" points="16.5,4.9 16.7,3.8 16.7,4.9 		"/>
   </g>
 </g>
  </svg>`;
    case "dumptruck":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <rect x="8.1" y="5.7" style="fill:#000;" width="7.9" height="1.1"/>
     <g>
       <path style="fill:#000;" d="M8.3,0.8C8.1,0.9,8,1,8,1.2v0.5c0,0.1,0.1,0.1,0.1,0.1H16c0.1,0,0.1-0.1,0.1-0.1V1.2c0-0.2-0.1-0.3-0.3-0.3
         C13.2,0.6,10.8,0.6,8.3,0.8z"/>
       <path style="fill:${color}" d="M8.5,6.6h7c0.3,0,0.5-0.2,0.5-0.5V1.3c0-0.2-0.1-0.3-0.4-0.3c-2.4-0.3-4.8-0.3-7.2,0C8.3,1,8.1,1.1,8.1,1.3
         v4.8C8.1,6.4,8.3,6.6,8.5,6.6z"/>
       <path style="fill:#000;" d="M8.6,1.2c-0.2,0-0.4,0.3-0.4,0.7l0,1c2.4-0.4,4.9-0.4,7.4,0h0.1V1.8c0-0.3-0.1-0.6-0.4-0.6
         C13.2,0.8,10.8,0.8,8.6,1.2z"/>
       <g>
         <g>
           <path style="fill:#000;" d="M15.8,2.3h0.9c0,0,0.1,0,0.1,0.1c0,0,0,0.1-0.1,0.1h-0.9C15.8,2.5,15.7,2.4,15.8,2.3
             C15.7,2.3,15.8,2.3,15.8,2.3z"/>
           <path style="fill:#000;" d="M16.2,2.3h0.4c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0h-0.5C16.1,2.4,16.1,2.4,16.2,2.3C16.1,2.3,16.2,2.3,16.2,2.3
             z"/>
         </g>
         <g>
           <path style="fill:#000;" d="M8.2,2.3H7.3c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1h0.9C8.2,2.5,8.3,2.4,8.2,2.3C8.3,2.3,8.2,2.3,8.2,2.3z"
             />
           <path style="fill:#000;" d="M7.8,2.3H7.4c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0h0.5C7.9,2.4,7.9,2.4,7.8,2.3C7.9,2.3,7.8,2.3,7.8,2.3z"/>
         </g>
       </g>
     </g>
     <g>
       <rect x="7.7" y="7.3" style="fill:#9E9E9E;" width="8.6" height="14.9"/>
       <rect x="7.7" y="19.6" width="8.6" height="2.5"/>
       <rect x="7.4" y="9.3" style="fill:#000;" width="9.2" height="0.6"/>
       <rect x="7.4" y="11.9" style="fill:#000;" width="9.2" height="0.6"/>
       <rect x="7.4" y="14.6" style="fill:#000;" width="9.2" height="0.6"/>
       <rect x="7.4" y="17.2" style="fill:#000;" width="9.2" height="0.6"/>
       <rect x="7.4" y="19" style="fill:#000;" width="9.2" height="0.6"/>
       <rect x="7.4" y="19.6" width="0.3" height="2.4"/>
       <rect x="16.3" y="19.6" width="0.3" height="2.4"/>
       <rect x="7.4" y="6.7" style="fill:#000;" width="9.2" height="0.6"/>
     </g>
   </g>
 </g>
 <rect x="8" y="21.4" style="fill:#000;" width="8.1" height="1.2"/>
 </svg>`;
    case "excavator":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:#000;" d="M9.6,23H7.4c-0.1,0-0.3-0.1-0.3-0.3V9.3C7.1,9.2,7.2,9,7.4,9h2.2c0.1,0,0.3,0.1,0.3,0.3v13.3
     C9.9,22.9,9.7,23,9.6,23z"/>
   <path style="fill:#000;" d="M16.7,23h-2c-0.1,0-0.3-0.1-0.3-0.3V9.3c0-0.2,0.1-0.3,0.3-0.3h2C16.8,9,17,9.2,17,9.3v13.3
     C17,22.9,16.9,23,16.7,23z"/>
   <path style="fill:${color}" d="M8,12.7c0,0-0.4,0-0.3,0.5v7.2l2,1.9h0.7v-0.5h3.3v0.5h0.7l1.9-1.9v-5.7l-2.6-0.1l0-1.7c0,0-0.1-0.2-0.3-0.2
     C13.3,12.7,8,12.7,8,12.7z"/>
   <ellipse style="fill:#000;" cx="10.7" cy="18.5" rx="1.7" ry="1.7"/>
   <path style="fill:#000;" d="M13.8,1.5l-0.1,4.1c0,0.4-0.2,0.5-0.5,0.5h-2.7c-0.3,0-0.5-0.1-0.5-0.5L9.9,1.5c0-0.4,0.2-0.5,0.5-0.5h3
     C13.6,1,13.8,1.1,13.8,1.5z"/>
   <path style="fill:${color}" d="M12.4,1.9c0.3,0,0.7,1.9,0.7,2.8c0,0-0.2,1.2-0.6,1.2h-1.1c-0.3,0-0.6-1.2-0.6-1.2c0-0.8,0.3-2.8,0.7-2.8
     C11.3,1.9,12.4,1.9,12.4,1.9z"/>
   <polygon points="13,4.6 13.4,12.7 10.3,12.7 10.7,4.6 	"/>
   <polygon style="fill:#000;" points="13,5.2 13,4.6 10.7,4.6 10.7,5.2 	"/>
 </g>
 </svg>`;
    case "flatbedtruck":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <rect x="7.9" y="14.4" style="fill:#000" width="8.4" height="1.4"/>
     <rect x="7.9" y="20.2" style="fill:#000" width="8.4" height="1.4"/>
     <g>
       <path style="fill:#000" d="M8.1,22.5H7.4c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L8.1,22.5
         C8.2,22.4,8.2,22.5,8.1,22.5z"/>
     </g>
     <g>
       <path style="fill:#000" d="M9.1,22.4H8.4c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L9.1,22.4
         C9.2,22.4,9.2,22.4,9.1,22.4z"/>
     </g>
     <g>
       <path style="fill:#000" d="M15.7,22.5h-0.7c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L15.7,22.5
         C15.7,22.4,15.7,22.5,15.7,22.5z"/>
     </g>
     <g>
       <path style="fill:#000" d="M16.6,22.4h-0.7c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L16.6,22.4
         C16.6,22.4,16.6,22.4,16.6,22.4z"/>
     </g>
     <g>
       <path style="fill:#000" d="M8.1,16.6H7.4c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L8.1,16.6
         C8.2,16.6,8.2,16.6,8.1,16.6z"/>
     </g>
     <g>
       <path style="fill:#000" d="M9.1,16.6H8.4c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L9.1,16.6
         C9.2,16.6,9.2,16.6,9.1,16.6z"/>
     </g>
     <g>
       <path style="fill:#000" d="M15.7,16.6h-0.7c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L15.7,16.6
         C15.7,16.6,15.7,16.6,15.7,16.6z"/>
     </g>
     <g>
       <path style="fill:#000" d="M16.6,16.6h-0.7c0,0-0.1,0-0.1-0.1v-2.8c0,0,0-0.1,0.1-0.1h0.7c0,0,0.1,0,0.1,0.1L16.6,16.6
         C16.6,16.6,16.6,16.6,16.6,16.6z"/>
     </g>
   </g>
   <polygon style="fill:${color}" points="14.7,23 12.9,23 12.8,22.8 11.2,22.8 11.1,23 9.3,23 9.3,12.6 14.7,12.6 	"/>
   <path style="fill:#000" d="M11.4,19.1h0.1c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.5,0.1-0.7c0-0.1,0-0.1-0.1-0.1l0,0c-0.1,0-0.1,0-0.1-0.1v-0.2
     c0,0,0-0.1,0.1-0.1h0.1h0.4h0.1c0.1,0,0.1,0,0.1,0.1V18c0,0,0,0.1-0.1,0.1l0,0c-0.1,0-0.1,0.1-0.1,0.1c0,0.2,0.1,0.6,0.1,0.7
     c0,0,0,0.1,0.1,0.1h0.1c0.1,0,0.1,0,0.1-0.1v-1.7c0,0,0-0.1-0.1-0.1h-0.3h-0.4h-0.3c-0.1,0-0.1,0-0.1,0.1v1.7
     C11.3,19.1,11.4,19.1,11.4,19.1z"/>
   <polygon style="fill:#000" points="9.3,23 11.1,23 11.2,22.8 12.8,22.8 12.9,23 14.7,23 14.7,22.7 9.3,22.7 	"/>
   <g>
     <path style="fill:${color}" d="M9.8,23c-0.1,0-0.1,0-0.1-0.1s0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1S9.9,23,9.8,23z"/>
     <path style="fill:${color}" d="M10.1,23c-0.1,0-0.1,0-0.1-0.1s0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1S10.2,23,10.1,23z"/>
     <g>
       <path style="fill:${color}" d="M9.5,23c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1C9.6,22.7,9.6,22.8,9.5,23C9.6,22.9,9.5,23,9.5,23z"/>
     </g>
   </g>
   <g>
     <path style="fill:${color}" d="M14.3,22.7c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1C14.1,22.8,14.2,22.7,14.3,22.7z"/>
     <path style="fill:${color}" d="M13.9,22.7c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1C13.8,22.8,13.9,22.7,13.9,22.7z"/>
     <g>
       <path style="fill:${color}" d="M14.6,22.7c0.1,0,0.1,0,0.1,0.1c0,0-0.1,0.1-0.1,0.1C14.5,23,14.5,22.9,14.6,22.7C14.5,22.8,14.5,22.7,14.6,22.7z"/>
     </g>
   </g>
   <g>
     <g>
       <path style="fill:#000" d="M14.8,12.6h1l0,0c0,0,0-0.1-0.1-0.1s-0.1,0-0.1-0.1v-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0h-1V12.6z"
         />
       <path style="fill:#000" d="M9.2,12.2h-1l0,0c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1v0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1l0,0h1V12.2z
         "/>
       <rect x="9.2" y="12.6" style="fill:#000" width="5.6" height="0.3"/>
       <rect x="9.2" y="11.9" style="fill:#000" width="5.6" height="0.3"/>
       <rect x="9.2" y="12.2" width="5.6" height="0.3"/>
       <rect x="9.2" y="12.2" style="fill:#000" width="5.6" height="0.3"/>
     </g>
     <g>
       <g>
         <path style="fill:#000" d="M16.1,8.9h0.5c0,0,0.1,0,0.1-0.1V4.9c0,0,0-0.1-0.1-0.1h-0.5c0,0-0.1,0-0.1,0.1L16.1,8.9
           C16.1,8.8,16.1,8.9,16.1,8.9z"/>
         <path style="fill:#000" d="M7.9,8.9H7.4c0,0-0.1,0-0.1-0.1V4.9c0,0,0-0.1,0.1-0.1h0.5c0,0,0.1,0,0.1,0.1L7.9,8.9
           C7.9,8.8,7.9,8.9,7.9,8.9z"/>
         <path style="fill:${color}" d="M7.9,11.8L7.9,11.8C8,12,8.3,12,8.5,12H12h3.6c0.1,0,0.3-0.1,0.5-0.3l0,0c0.1-0.2,0.3-0.6,0.3-0.9l0-7.5
           c0-0.2,0-0.4-0.1-0.6l-0.1-0.3C16.1,2.1,16,2,15.9,1.9l-1-0.7C14.7,1,14.7,1,14.5,1H12L9.5,1c-0.1,0-0.2,0-0.3,0.1l-1,0.7
           C8,2.1,7.9,2.2,7.8,2.4L7.7,2.7C7.6,2.9,7.6,3.1,7.6,3.4v7.5C7.7,11.3,7.8,11.6,7.9,11.8z"/>
         <path style="fill:#000" d="M7.8,3l0.1-0.3C7.9,2.4,8,2.4,8.2,2.2l1-0.7c0.1-0.1,0.2-0.1,0.3-0.1H12l2.5,0c0.1,0,0.2,0,0.3,0.1l1,0.7
           c0.1,0.1,0.3,0.2,0.3,0.5l0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.6V3.4c0-0.2,0-0.4-0.1-0.6l-0.1-0.3c0-0.2-0.1-0.4-0.3-0.5l-1-0.7
           C14.8,1.1,14.7,1,14.6,1H12l-2.5,0c-0.1,0-0.2,0-0.3,0.1l-1,0.7C8,2.1,7.9,2.2,7.8,2.4L7.7,2.7C7.6,2.9,7.6,3.1,7.6,3.4v0.2
           C7.6,3.4,7.7,3.2,7.8,3z"/>
         <path style="fill:${color}" d="M16.1,2.7c-0.1-0.2-0.2-0.3-0.3-0.4l-1-0.7c0-0.1-0.1-0.1-0.2-0.1l-2.6,0l-2.5,0c-0.1,0-0.2,0-0.3,0.1
           l-1,0.7C8,2.4,7.9,2.5,7.8,2.7L7.7,3.1c0,0.2,0,0.3,0,0.6l0,7.1c0,0.3,0.1,0.7,0.3,0.9C8,11.9,8.2,12,8.4,12H12h3.6
           c0.1,0,0.3-0.1,0.5-0.3c0.1-0.2,0.3-0.6,0.3-0.9V3.6c0-0.2,0-0.4-0.1-0.6L16.1,2.7z"/>
         <path style="fill:#000" d="M7.7,9.2c0,0-0.1-0.1-0.1-2.2s0.1-2.7,0.1-2.7l0,2.7L7.7,9.2z"/>
         <g>
           <polygon style="fill:#000" points="8.1,6.3 7.8,6.3 7.8,5.1 8,5.2 					"/>
           <path style="fill:#000" d="M7.3,5.7l0.6-0.4c0,0,0.1,0,0.1,0.4c0,0.4-0.1,0.6-0.1,0.6L7,6.6V6.5C7,6.2,7.1,5.8,7.3,5.7z"/>
           <path style="fill:#000" d="M7,6.5C7,6.5,7,6.5,7,6.5v0.2l0.8-0.3c0,0,0.1,0,0.1-0.2L7,6.5z"/>
         </g>
         <g>
           <g>
             <path style="fill:#000" d="M14.3,1.2L12,1c0,0,0,0,0,0c0,0,0,0,0,0L9.6,1.1c-0.1,0-0.1,0.1-0.1,0.1v0.1c0,0.1,0.1,0.1,0.1,0.1h2.3
               l0,0l0,0h2.3c0.1,0,0.1-0.1,0.1-0.1l0,0C14.4,1.3,14.4,1.2,14.3,1.2z"/>
             <path style="fill:#000" d="M14.3,1.6H12l0,0l0,0l0,0l0,0H9.7c-0.1,0-0.1-0.1-0.1-0.1v0c0-0.1,0.1-0.1,0.1-0.1L12,1.1l0,0l0,0l0,0
               l0,0l2.3,0.1c0.1,0,0.1,0.1,0.1,0.1L14.3,1.6C14.4,1.5,14.4,1.6,14.3,1.6z M12,1.6L12,1.6L12,1.6h2.3c0.1,0,0.1-0.1,0.1-0.1v0
               c0-0.1-0.1-0.1-0.1-0.1L12,1l0,0l0,0l0,0l0,0L9.7,1.2c-0.1,0-0.1,0.1-0.1,0.1v0.1c0,0.1,0.1,0.1,0.1,0.1L12,1.6L12,1.6L12,1.6
               z"/>
           </g>
           <g>
             <g>
               <path style="fill:${color}" d="M11.9,1.5L11.9,1.5l-2.3,0l0,0l0,0l0,0L11.9,1.5C11.9,1.4,11.9,1.4,11.9,1.5L11.9,1.5z"/>
             </g>
             <g>
               <path style="fill:${color}" d="M12,1.1L12,1.1L9.6,1.3l0,0l0,0L12,1.1L12,1.1L12,1.1z"/>
             </g>
             <g>
               <path style="fill:${color}" d="M12,1.3L12,1.3L9.6,1.4l0,0l0,0L12,1.3C12,1.3,12,1.3,12,1.3L12,1.3z"/>
             </g>
           </g>
           <g>
             <g>
               <path style="fill:${color}" d="M14.4,1.5L14.4,1.5L14.4,1.5H12l0,0l0,0l0,0H14.4L14.4,1.5z"/>
             </g>
             <g>
               <path style="fill:${color}" d="M14.4,1.3L14.4,1.3L14.4,1.3L12,1.1l0,0l0,0L14.4,1.3L14.4,1.3z"/>
             </g>
             <g>
               <path style="fill:${color}" d="M14.4,1.4L14.4,1.4L14.4,1.4l-2.4,0l0,0l0,0L14.4,1.4L14.4,1.4z"/>
             </g>
           </g>
         </g>
         <g>
           <path style="fill:#000" d="M13.4,3.7l-1.3-0.1c0,0,0,0,0,0V3.4c0,0,0,0,0.1,0l1.4,0.2L13.4,3.7C13.4,3.7,13.4,3.7,13.4,3.7z"/>
           <g>
             <path style="fill:#000" d="M10.6,3.7l1.3-0.1c0,0,0,0,0,0V3.4c0,0,0,0-0.1,0l-1.4,0.2L10.6,3.7C10.6,3.7,10.6,3.7,10.6,3.7z"/>
           </g>
         </g>
         <polygon points="7.8,6.5 8.1,6.4 8.1,8.4 8.1,11 7.8,10.6 				"/>
         <polygon style="fill:#000" points="7.8,6.5 8.1,6.4 8.1,8.4 8.1,11 7.8,10.6 				"/>
         <g>
           <path style="fill:${color}" d="M7.9,3.7l0,0.5c0,0,0.1,0,0.1-0.1V3.8C7.9,3.7,7.9,3.7,7.9,3.7z"/>
           <path style="fill:${color}" d="M7.9,3.7L7.9,3.7c0,0,0,0.1,0,0.1v0.2c0,0,0,0,0,0.1l0,0c0,0,0,0,0-0.1L7.9,3.7C7.9,3.7,7.9,3.7,7.9,3.7z"/>
         </g>
         <g>
           <path style="fill:${color}" d="M9.2,1.2c-0.3,0-0.7,0.1-0.9,0.4C8.1,1.8,8.1,1.9,8.1,2c0.2,0,0.5-0.1,0.7-0.3C9.1,1.6,9.2,1.4,9.2,1.2z"/>
           <path style="fill:${color}" d="M9.2,1.2c-0.3,0-0.7,0.1-0.9,0.4C8.1,1.8,8.1,1.9,8.1,2c0.2,0,0.5-0.1,0.7-0.3C9.1,1.6,9.2,1.4,9.2,1.2z"/>
         </g>
         <path style="fill:#000" d="M16.3,9.2c0,0,0.1-0.1,0.1-2.2s-0.1-2.6-0.1-2.6l0,2.7L16.3,9.2z"/>
         <g>
           <polygon style="fill:#000" points="15.9,6.3 16.3,6.3 16.2,5.1 16,5.2 					"/>
           <path style="fill:#000" d="M16.7,5.7l-0.6-0.4c0,0-0.1,0-0.1,0.4c0,0.4,0.1,0.6,0.1,0.6L17,6.6V6.5C17,6.2,16.9,5.8,16.7,5.7z"/>
           <path style="fill:#000" d="M17,6.5C17,6.5,17,6.5,17,6.5v0.2l-0.8-0.3c0,0-0.1,0-0.1-0.2L17,6.5z"/>
         </g>
         <polygon points="16.3,6.5 15.9,6.4 15.9,8.4 15.9,11 16.3,10.6 				"/>
         <polygon style="fill:#000" points="16.3,6.5 15.9,6.4 15.9,8.4 15.9,11 16.3,10.6 				"/>
         <g>
           <path style="fill:${color}" d="M16.1,3.7l0,0.5c0,0-0.1,0-0.1-0.1V3.8C16.1,3.7,16.1,3.7,16.1,3.7z"/>
           <path style="fill:${color}" d="M16.1,3.7L16.1,3.7c0,0,0,0.1,0,0.1v0.2c0,0,0,0,0,0.1l0,0c0,0,0,0,0-0.1L16.1,3.7C16.1,3.7,16.1,3.7,16.1,3.7z"/>
         </g>
         <g>
           <path style="fill:${color}" d="M14.9,1.2c0.3,0,0.7,0.1,0.9,0.4c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.5-0.1-0.7-0.3C14.9,1.6,14.8,1.4,14.9,1.2z"/>
           <path style="fill:${color}" d="M14.9,1.2c0.3,0,0.7,0.1,0.9,0.4c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0-0.5-0.1-0.7-0.3C14.9,1.6,14.8,1.4,14.9,1.2z"/>
         </g>
         <g>
           <g>
             <path style="fill:#000" d="M11.9,3.8c0,0-1.2,0.1-1.7,0.2C9.5,4.2,8.5,4.5,8.5,4.5c0,0-0.1,0-0.1,0.1L8.3,5.8c0,0.1,0,0.2,0.1,0.2
               l3.5-0.3c0,0,0.1-0.1,0.1-0.1V4.1C11.9,3.9,11.9,3.8,11.9,3.8z"/>
             <path style="fill:#000" d="M11.9,4v1.4c0,0.1,0,0.2-0.1,0.2L8.3,6c0,0,0,0-0.1-0.1c0,0,0-0.1,0-0.1l0.1-1.2c0-0.1,0-0.1,0.1-0.1
               c0,0,1.1-0.3,1.7-0.4c0.6-0.1,1.7-0.2,1.7-0.2l0,0C11.9,3.8,11.9,3.9,11.9,4z M8.3,5.8C8.3,5.8,8.3,5.8,8.3,5.8
               c0,0.1,0,0.1,0,0.1l3.5-0.3c0,0,0,0,0-0.1V4c0,0,0-0.1,0-0.1c0,0-1.2,0.1-1.7,0.2C9.5,4.2,8.4,4.5,8.4,4.5c0,0,0,0,0,0.1
               L8.3,5.8L8.3,5.8z"/>
           </g>
         </g>
         <g>
           <g>
             <path style="fill:#000" d="M12.1,3.8c0,0,1.2,0.1,1.7,0.2c0.6,0.1,1.7,0.4,1.7,0.4c0,0,0.1,0,0.1,0.1l0.1,1.2c0,0.1,0,0.2-0.1,0.2
               l-3.5-0.3c0,0-0.1-0.1-0.1-0.1V4.1C12.1,3.9,12.1,3.8,12.1,3.8z"/>
             <path style="fill:#000" d="M12.1,3.8L12.1,3.8c0,0,1.2,0.1,1.7,0.2c0.6,0.1,1.7,0.4,1.7,0.4c0,0,0.1,0.1,0.1,0.1l0.1,1.2
               c0,0.1,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1l-3.5-0.3c0,0-0.1-0.1-0.1-0.2V4C12.1,3.9,12.1,3.8,12.1,3.8z M15.7,5.8l-0.1-1.2
               c0,0,0-0.1,0-0.1s-1.1-0.3-1.7-0.4c-0.6-0.1-1.7-0.2-1.7-0.2c0,0,0,0,0,0.1v1.4c0,0,0,0.1,0,0.1l3.5,0.3
               C15.7,5.9,15.7,5.9,15.7,5.8C15.7,5.9,15.7,5.8,15.7,5.8L15.7,5.8z"/>
           </g>
         </g>
       </g>
     </g>
   </g>
 </g>
 </svg>`;
    case "forklift":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <path style="fill:${color}" d="M10.1,9.1H9.2H8.9c0,0-0.1-0.2-0.5-0.2H7.5c0,0-0.6,0-0.6,0.5v2.7c0,0,0,0.2,0.3,0.4v9.1c0,0,0.1,0.1,0.1,0.1
       v0.6c0,0,0.1,0.3,0.6,0.4h0.5c0,0,0.4-0.1,0.4-0.2c0,0,1.3,0.5,2.8,0.5c0,0,0.4,0,0.7,0c0,0,1.9-0.2,2.6-0.5c0,0,0.1,0.2,0.4,0.2
       c0,0,0.3,0,0.5,0c0,0,0.5,0,0.5-0.4v-0.5c0,0,0.1,0,0.1-0.2v-8.9v-0.1c0,0,0.3-0.1,0.3-0.4V9.5c0,0,0.1-0.5-0.7-0.6h-0.9
       c0,0-0.4,0-0.5,0.2h-1.2"/>
     <path style="fill:#000;" d="M9.3,19.3v-2.8c0-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.4-0.6,0.9-0.6h2.9c0,0,0.8,0.1,0.9,1.2v3.2c0,0-0.1,1-0.9,1h-2.8
       c0,0-0.8,0.1-1.1-0.8C9.3,19.6,9.3,19.5,9.3,19.3z"/>
   </g>
   <path style="fill:#000;" d="M13.8,10.1V8.5h2.6V8h-1.2V1.5c0,0,0-0.4-0.5-0.5c0,0-0.4,0-0.4,0.5v6.5H9.6V1.6c0,0,0-0.6-0.4-0.6c0,0-0.4,0-0.5,0.6V8
     H7.6v0.6h2.5v1.6"/>
 </g>
 </svg>`;
    case "minibus":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <g>
       <path style="fill:${color}" d="M16.9,15.9V4c0-1.7-0.1-1.8-0.1-1.9c0,0.2-0.1,0.5-0.3,0.5c-0.3,0.1-0.8-0.3-1-1c0,0,0,0,0-0.1
         C14.7,1.3,13.6,1,12.1,1S9.4,1.3,8.6,1.5c0,0,0,0,0,0.1c-0.2,0.7-0.7,1.1-1,1C7.4,2.5,7.3,2.3,7.3,2C7.2,2.2,7,2.3,7,4v11.9H16.9
         L16.9,15.9z"/>
       <path style="fill:${color}" d="M7.1,13.1v8.7c0,1,1.6,1.2,1.6,1.2h7c0,0,1.4-0.2,1.4-1.2v-8.7H7.1z"/>
       <path style="fill:#000;" d="M7.6,2.6c0.3,0.1,0.8-0.3,1-1c0,0,0,0,0-0.1c-0.6,0.2-1,0.3-1,0.3c-0.1,0-0.3,0-0.3,0.1
         C7.3,2.3,7.4,2.5,7.6,2.6z"/>
       <path style="fill:#000;" d="M16.5,2.6c0.1-0.1,0.3-0.2,0.3-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c0,0-0.3-0.1-0.9-0.3c0,0,0,0,0,0.1
         C15.7,2.3,16.2,2.7,16.5,2.6z"/>
     </g>
     <path style="fill:${color}" d="M16.6,14.3V5.1c0-0.2-0.2-0.5-0.5-0.5c0,0-1.9-2.1-4.2-2.1S8,4.6,8,4.6c-0.3,0-0.5,0.2-0.5,0.5v9.2H16.6z"/>
     <path style="fill:${color}" d="M7.4,13.1v8.4c0,1.4,2.2,1.2,2.2,1.2h4.8c0,0,2.2-0.1,2.2-1.2v-8.4H7.4z"/>
     <path style="fill:#000;" d="M8.5,8.4C8.6,6.5,7.4,5,7.4,5v3.4C7.4,8.4,7.7,8.4,8.5,8.4z"/>
     <rect x="7.4" y="10.8" style="fill:#000;" width="1.1" height="2.9"/>
     <rect x="7.4" y="13.9" class="st3" width="1.1" height="0.3"/>
     <polygon style="fill:#000;" points="7.4,19 7.4,21.7 8.5,21.3 8.5,19 		"/>
     <rect x="7.4" y="8.9" style="fill:#000;" width="1.1" height="1.6"/>
     <path style="fill:#000;" d="M15.5,8.4C15.3,6.5,16.6,5,16.6,5v3.4C16.6,8.4,16.3,8.4,15.5,8.4z"/>
     <rect x="15.5" y="10.8" style="fill:#000;" width="1.1" height="2.9"/>
     <rect x="15.5" y="13.9" class="st3" width="1.1" height="0.3"/>
     <rect x="7.4" y="13.1" style="fill:#000;" width="1.1" height="1.2"/>
     <rect x="15.5" y="13.1" style="fill:#000;" width="1.1" height="1.2"/>
     <rect x="7.4" y="14.5" style="fill:#000;" width="1.1" height="4.3"/>
     <rect x="15.5" y="14.5" style="fill:#000;" width="1.1" height="4.3"/>
     <polygon style="fill:#000;" points="16.6,19 16.6,21.7 15.5,21.3 15.5,19 		"/>
     <rect x="15.5" y="8.9" style="fill:#000;" width="1.1" height="1.6"/>
     <path style="fill:#000;" d="M7.8,4.7l1,2l6.6,0.1l0.7-2.1c0,0-0.5-2-4-2.1C8.6,2.5,7.8,4.7,7.8,4.7z"/>
     <path style="fill:#000;" d="M7.8,22c0.3-0.2,0.9-0.7,0.9-0.7h6.7c0,0,0.3,0.3,0.6,0.6C16.4,22.7,7.7,22.9,7.8,22z"/>
   </g>
 </g>
 </svg>
 `;
    case "mixer":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <rect x="9" y="6.4" style="fill:#000;" width="6" height="14.7"/>
     <path style="fill:#000;" d="M8.3,1.3c-0.2,0-0.3,0.2-0.3,0.3V3c0,0.1,0.1,0.1,0.1,0.1h7.9c0.1,0,0.1-0.1,0.1-0.1V1.6
       c0-0.2-0.1-0.3-0.3-0.3C13.2,0.9,10.8,0.9,8.3,1.3z"/>
     <path style="fill:${color}" d="M8.5,7.3h6.9c0.3,0,0.4,0.1,0.5-0.2V2.6c0-1.1-0.1-1.3-0.3-1.3c-2.4-0.2-4.8-0.2-7.2,0C8.2,1.4,8,1.5,8,2.6V7
       C8.1,7.4,8.3,7.3,8.5,7.3z"/>
     <path style="fill:#000;" d="M8.6,1.4c-0.2,0-0.4,0.2-0.3,0.3l0,1.5c2.4-0.2,4.9-0.2,7.4,0h0.1V1.8c0-0.2-0.1-0.3-0.3-0.3
       C13.1,1.2,10.8,1.2,8.6,1.4z"/>
     <g>
       <g>
         <path style="fill:#000;" d="M15.8,3.4h0.9c0,0,0.1,0,0.1,0s0,0.1,0,0.1h-0.9C15.7,3.6,15.7,3.6,15.8,3.4C15.8,3.5,15.8,3.4,15.8,3.4z"
           />
         <path style="fill:#000;" d="M16.6,3.5H17c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0h-0.5C16.5,3.7,16.5,3.7,16.6,3.5C16.5,3.6,16.5,3.5,16.6,3.5z"
           />
       </g>
       <g>
         <path style="fill:#000;" d="M8.2,3.4H7.3c0,0-0.1,0-0.1,0s0,0,0.1,0L8.2,3.4C8.3,3.6,8.3,3.6,8.2,3.4C8.2,3.5,8.2,3.4,8.2,3.4z"/>
         <path style="fill:#000;" d="M7.4,3.5H7c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0h0.5C7.5,3.7,7.5,3.7,7.4,3.5C7.5,3.6,7.5,3.5,7.4,3.5z"/>
       </g>
     </g>
   </g>
   <path style="fill:${color}" d="M7.9,9.7l-0.4,0.9c-0.4,0.9-0.7,1.8-0.7,2.7v2c0,1.4,0.2,2.9,0.7,4.2L8.5,22c0.2,0.6,0.9,1,1.6,1h3.7
     c0.7,0,1.4-0.4,1.6-1l0.9-2.5c0.5-1.4,0.7-2.8,0.7-4.2v-2c0-1-0.2-1.8-0.7-2.7l-0.4-0.9C15.6,9,14.9,8.6,14,8.6H9.8
     C9,8.6,8.2,9,7.9,9.7z"/>
   <path style="fill:${color}" d="M10.1,22.4l-1-2.7c-0.5-1.5-0.8-3-0.8-4.5v-2c0-1,0.2-2,0.7-3l0.4-0.9C9.6,9,9.9,8.7,10.2,8.6H9.9
     C9,8.6,8.3,9,7.9,9.7l-0.4,0.9c-0.4,0.9-0.7,1.8-0.7,2.7v2c0,1.4,0.2,2.9,0.7,4.2L8.5,22c0.2,0.6,0.9,1,1.6,1h0.4
     C10.3,22.8,10.2,22.7,10.1,22.4z"/>
   <path style="fill:${color}" d="M13.9,22.4l1-2.7c0.5-1.5,0.8-3,0.8-4.5v-2c0-1-0.2-2-0.7-3l-0.4-0.9c-0.1-0.3-0.4-0.5-0.7-0.7h0.3
     c0.9,0,1.6,0.4,1.9,1.1l0.4,0.9c0.4,0.9,0.7,1.8,0.7,2.7v2c0,1.4-0.2,2.9-0.7,4.2L15.5,22c-0.2,0.6-0.9,1-1.6,1h-0.4
     C13.7,22.8,13.8,22.7,13.9,22.4z"/>
   <path style="fill:${color}" class="st4" d="M14.7,22.8c0-0.1-1.3-0.3-2.8-0.3c-1.3,0-2.4,0.1-2.8,0.2c0.3,0.1,0.6,0.2,1,0.2h3.7
     C14.2,23,14.5,22.9,14.7,22.8z"/>
   <ellipse style="fill:#000;" cx="12" cy="22.8" rx="2" ry="0.1"/>
 </g>
 </svg>`;
    case "person":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill: #78909C;" d="M16.9,16.3c0,0-0.1,2.5-0.5,3.9H14c0,0-0.4-2.1-0.7-3.4C13.4,16.7,15.9,15.5,16.9,16.3z"/>
   <path style="fill: #78909C;" d="M10.8,4.6L8.3,4.8c0,0-0.6,2.5-0.8,4c0,0,3.4,3.6,3.8,0.6C11.2,9.4,11,6.4,10.8,4.6z"/>
   <path style="fill:${color}" d="M19.6,6.6c0.1-0.1,0.6-0.1,1.3-0.2c0.3,0,0.6,0.1,0.7,0.5c0.4,0.9,1,2.8,1.4,4.3l0,0c0,0.1,0.2,1.3-0.2,2.3
     l0,0c-0.1,0.2-1.4,1.8-4,2.6c0,0-6.2,1.4-10.9,0.4c0,0-1.9-0.4-2.8-0.7c-0.1-0.1-0.4,0.1-0.4,0.3c-0.1,0.3-0.1,0.9-0.1,2
     c0,0.1-0.1,0.3-0.3,0.4c-0.3,0-1,0.1-1.4,0.2c-0.1,0.1-0.3-0.1-0.3-0.2c-0.3-0.9-1.1-3.7-1.7-6.3C1,12,1,12,1,12c0-0.3,0-1.8,1-2.3
     c0,0,1-0.7,3.2-0.7c0,0,3.8-0.4,13.8,0c0.1,0,0.3-0.1,0.3-0.4c0-0.4,0-1.2,0.1-1.7C19.4,6.7,19.5,6.6,19.6,6.6z"/>
   <path style="fill:#000;" d="M8.3,2.8c0,0,0.1-1.8,1.5-1.8c0,0,0.8,0.1,1.1,1.5V5L8.3,5V2.8z"/>
   <polygon style="fill:#000;" points="13.9,23 16.4,23 16.4,20 14,20 	"/>
   <path style="fill:#000;" d="M12.8,4.7c0,0,1.7-0.1,2.8,3c0,0,1.2,3.6,0,7c0,0-1.5,2.8-3.7,2.6c0,0-2.1,0.4-3.7-2.9c0,0-1.3-2.8,0.3-6.2
     c0,0,0.7-1.7,2.3-1.7C10.8,6.6,11.2,4.7,12.8,4.7z"/>
   <path style="fill:#000;" d="M19.9,6c0,0-0.3-0.6-0.5,0.1c0,0-0.1,0.6,0,1c0,0,2.1,0.4,2.1-0.4c0,0-0.3-1.3-1-1.8C20.4,4.8,19.8,4.7,19.9,6
     z"/>
   <path style="fill:#000;" d="M4.1,18.9c0,0,0.4,0.6,0.5-0.1c0,0,0.1-0.6,0-1c0,0-2.1-0.4-2.1,0.4c0,0,0.3,1.3,1,1.8
     C3.5,20.1,4.2,20.2,4.1,18.9z"/>
 </g>
 </svg>`;
    case "pickup":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M15.8,6.5c0,0-0.3-3.2-0.6-4.5c0,0-0.1-1-1.7-1c0,0-2.4,0-3.8,0.1c0,0-1-0.2-1.2,1.4c0,0-0.3,2.6-0.4,4
     c0,0-1.4,0.4-1.4,1.3c0,0,1.1-0.2,1.3-0.5c0,0,0.1-0.1,0.1,0.4c0,0-0.1,3.8,0,6c0,0,0.3,6.8,0.3,8.4c0,0,0.1,0.9,0.7,0.9
     c0,0,0.9,0,1.2,0c0,0,0.2-0.1,0.5-0.1l2.4,0c0,0,0.1-0.1,0.2,0.1c0,0,0.7,0.1,1.2,0c0,0,0.6,0.1,0.7-1c0,0,0.3-5.3,0.4-8
     c0-0.3,0-0.7,0-1c0-1.2-0.1-4.5-0.1-5.3c0,0,0-0.2,0.5,0c0,0,0.9,0.2,0.9,0.2c0,0,0.1-0.5-0.7-1C16.7,6.9,16.1,6.6,15.8,6.5z"/>
   <path style="fill:#000;" d="M15.6,13.1h-0.7c0,0-0.2-2.9,0.1-3.7c0,0,0.3-0.8,0.6-1C15.6,8.3,15.6,12.6,15.6,13.1z"/>
   <path style="fill:#000;" d="M9.4,13.1c0,0-0.6,0.1-0.8,0c0,0-0.1-4.2,0-4.7c0,0,0-0.5,0.8,1.4C9.4,9.7,9.5,12.7,9.4,13.1z"/>
   <path style="fill:#000;" d="M9.6,9.3c0,0,0,0.2,0.5,0c0,0,0.7-0.1,1-0.1c0,0,0-0.3,0.6-0.4c0,0,0.8-0.1,1.2,0.1c0,0,0.2,0,0.2,0.3
     c0,0,0.6,0,1.3,0.2c0,0,0.3,0.1,0.4-0.3c0,0,0.4-1.4,0.6-2c0,0,0.1-0.4-0.6-0.6c0,0-2.7-0.7-5.8,0.1c0,0-0.3,0.2,0,0.9
     C9,7.4,9.5,8.8,9.6,9.3z"/>
   <path style="fill: #78909C;" d="M14.7,22c0,0,0.5,0.1,0.5-0.9c0,0,0.4-6.6,0.3-7.2c0,0-5.4-0.2-6.7,0c0,0,0.2,5.9,0.3,7.4c0,0,0,0.8,0.7,0.7
     C9.7,22,12.5,22.3,14.7,22z"/>
 </g>
 </svg>`;
    case "plane":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:#000;" d="M2.4,10.8C2.4,10.3,2.6,9.4,2.8,9c0.1-0.2,0.3-0.4,0.6-0.4C3.7,8.6,4,8.7,4.1,9c0,0,0,0.1,0,0.2
     c0,0.3,0,0.9,0,1.4c0,0,0,1-1.8,0.7C2.3,11.3,2.3,11,2.4,10.8z"/>
   <path style="fill:#000;" d="M4.9,10.1C5,9.7,5.2,8.8,5.4,8.3C5.5,8.1,5.7,7.9,6,7.9c0.2,0,0.5,0.1,0.6,0.4c0,0,0,0.1,0,0.2
     c0,0.3,0,0.9,0,1.4c0,0,0,1-1.8,0.7C4.7,10.6,4.8,10.3,4.9,10.1z"/>
   <path style="fill:#000;" d="M22.1,11c0-0.4-0.2-1.3-0.4-1.8C21.6,9,21.3,8.8,21,8.8c-0.2,0-0.5,0.1-0.6,0.4c0,0,0,0.1,0,0.2
     c0,0.2-0.1,0.9-0.1,1.4c0,0,0,1,1.8,0.8C22.1,11.5,22.1,11.2,22.1,11z"/>
   <path style="fill:#000;" d="M19.5,10.1c0-0.4-0.2-1.3-0.4-1.8c-0.1-0.2-0.3-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.6,0.4c0,0,0,0.1,0,0.2
     c0,0.2-0.1,0.9-0.1,1.4c0,0,0,1,1.8,0.8C19.6,10.6,19.5,10.3,19.5,10.1z"/>
   <path style="fill:${color}" d="M9.2,22.5l-0.3-1.3l2-1.7c0,0-0.7-5.6-0.7-6.8c0,0,0-0.3-0.6-0.3c0,0-6.4,1.2-8.2,1.4c0,0-0.5-1.5,0.1-2.2
     c0,0,4.4-1.9,8.6-3.2c0,0,0-4.3,0.7-6.1c0,0,0.2-0.4,0.5-0.8c0.4-0.5,1.3-0.6,1.8,0c0.2,0.2,0.4,0.5,0.6,0.9c0,0,0.8,2.9,0.6,6.1
     c0,0,3.8,1.1,8.7,3.3c0,0,0.4,1.2,0,2.2c0,0-6.5-1.3-8.1-1.5c0,0-0.8,0-0.9,0.5c0,0-0.1,3.3-0.7,6.6c0,0,1.5,1.2,1.9,1.8l-0.2,1.1
     c0,0-2.4-0.5-2.6,0c0,0-0.2,0.6-0.4,0C11.8,22.5,11.7,22.1,9.2,22.5z"/>
   <path style="fill:#000;" d="M12.4,22.7L12.4,22.7c0,0,0-0.1,0-0.2l-0.1-2.9c0-0.3-0.5-0.4-0.6,0c0,0,0,0,0,0c0,0-0.1,1.8-0.1,2.9
     C11.5,23,12.1,23.2,12.4,22.7z"/>
   <path style="fill:#000;" d="M10.6,2.8c0,0,1.4-0.7,3.1,0c0,0-0.2-1.2-0.9-1.7c-0.4-0.3-0.9-0.2-1.2,0C11.2,1.3,10.8,1.8,10.6,2.8z"/>
   <path style="fill:#000;" d="M11.1,3.9c0,0,0.9-0.5,2,0c0,0,0,0,0.1,0c0.1,0,0.2-0.3,0-0.6c0,0,0,0-0.1-0.1c-0.2,0-0.7-0.2-1.4-0.1
     c0,0-0.1,0-0.2,0c0,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2C10.9,3.5,10.8,4,11.1,3.9z"/>
 </g>
 </svg>`;
    case "roadroller":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <polygon style="fill:#000;" points="8.4,16.4 9.4,15.7 13.9,15.7 15.5,16 15.8,17.9 15.6,20.8 8.4,21.2 "/>
 <path style="fill:${color}" d="M8.1,22.2C8.2,23,8.6,23,8.6,23h6.6c0.7-0.1,0.7-0.8,0.7-0.8l-0.1-0.3c0-0.7,0-0.7,0-0.7v-3.3c0-0.5,0-1,0-1
   l0.1-0.2v-0.5c0-0.4-0.5-0.6-0.5-0.6h-0.5l-0.5-7.7c-0.1-0.7-0.5-1-0.5-1h-1.1V6.5h-1.8l0,0.4L9.8,7C9.4,7.1,9.3,7.7,9.3,7.7
   l-0.3,3.8l-0.3,4.1l-0.5,0.1c-0.3,0.1-0.4,0.5-0.4,0.5v0.5 M9.2,20.1H8.7v-3.2c0.1,0.2,0.5,0.6,0.5,0.6V20.1z M15.3,20.1h-0.5v-2.5
   c0,0,0.3-0.3,0.5-0.7V20.1z M14.9,17.1H9.1c-0.1-0.2-0.5-0.7-0.5-0.7c3.9-1.1,6.6,0,6.6,0C15.1,16.6,14.9,17.1,14.9,17.1z"/>
 <rect x="8.6" y="11" style="fill:#000;" width="1" height="0.5"/>
 <rect x="14.4" y="11" style="fill:#000;" width="1" height="0.5"/>
 <path style="fill:#000;" d="M8.5,13.7L8.5,13.7c-0.2,0-0.4-0.2-0.4-0.5v-4C8,9,8.2,8.7,8.5,8.7l0,0c0.2,0,0.4,0.2,0.4,0.5v4
   C8.9,13.5,8.7,13.7,8.5,13.7z"/>
 <path style="fill:#000;" d="M15.5,13.7L15.5,13.7c-0.2,0-0.4-0.2-0.4-0.5v-4c0-0.3,0.2-0.5,0.4-0.5l0,0C15.7,8.7,16,9,16,9.2v4
   C16,13.5,15.7,13.7,15.5,13.7z"/>
 <path style="fill:#000;" d="M7.8,22.2L7.8,22.2c-0.4,0-0.7-0.3-0.7-0.7v-4.1c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7v4.1
   C8.4,21.9,8.2,22.2,7.8,22.2z"/>
 <path style="fill:#000;" d="M16.2,22.2L16.2,22.2c-0.4,0-0.7-0.3-0.7-0.7v-4.1c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7v4.1
   C16.8,21.9,16.5,22.2,16.2,22.2z"/>
 <rect x="10.2" y="6.2" width="3.6" height="0.6"/>
 <rect x="8.3" y="1.6" style="fill:#000;" width="7.4" height="4.1"/>
 <rect x="7.8" y="5.9" width="8.5" height="0.7"/>
 <rect x="7.8" y="1" width="8.5" height="0.4"/>
 <rect x="7.8" y="1.4" width="0.3" height="4.5"/>
 <rect x="16" y="1.4" width="0.3" height="4.5"/>
 <rect x="8" y="3.1" style="fill:#000;" width="0.3" height="1.1"/>
 <rect x="15.7" y="3.1" style="fill:#000;" width="0.3" height="1.1"/>
 </svg>`;
    case "scooter":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M11,3.1V1.8C11,1.8,11.1,1,12,1c0,0,1.1,0,1.1,0.7v1.4c0,0,1,0.2,1.8,1.3c0,0,0.4,2.2,0.6,3.3
     c0,0,0.2,1,0.1,1.5c0,0-0.2,0.2,0.4,0.2c0,0,1.5,0.1,1.6,0.7h-1l-0.1,0.5L15,10.2c0,0-0.1,2.5,0.1,2.6c0,0,0.1,2.6-0.2,4.4
     c0,0-0.6,3.3-1.7,4.9c0,0-0.6,0.7-1.2,0.8c0,0-0.7,0.1-1.6-1.2c0,0-1.2-2.5-1.3-4.2c0,0-0.4-3.3-0.3-4.8c0,0,0.3,0,0.2-2.6
     c0,0-0.8,0.1-1.5,0.2c0,0-0.2-0.1-0.1-0.5l-1,0c0,0,0.4-0.7,2-0.7c0,0,0.1,0,0-0.5c0,0,0.2-1.7,0.4-3C8.9,5.8,8.9,3.7,11,3.1z"/>
   <path style="fill:#000;" d="M10.3,4.6c0,0,0.1-1.5,1.7-1.7c0,0,1.8,0.3,1.6,1.7c0,0-0.1,0.1-0.1-0.2c0,0-0.6-0.9-1.5-0.9
     c0,0-0.9,0-1.5,0.9C10.5,4.4,10.5,4.8,10.3,4.6z"/>
   <path style="fill:#000;" d="M10.4,4.9c0,0,0.8-0.8,1.1-0.9c0,0,0.5-0.2,0.9,0c0,0,0.8,0.4,1.1,0.9v1.9h-3.1L10.4,4.9z"/>
   <path style="fill:#000;" d="M14.4,6.5c0,0-0.1,1.6,0.1,1.7c0,0,0.8,0.4,0.5-0.6C15,7.6,14.7,6.5,14.4,6.5z"/>
   <path style="fill:#000;" d="M9.6,6.5c0,0,0.1,1.6-0.1,1.7c0,0-0.8,0.4-0.5-0.6C9,7.6,9.3,6.5,9.6,6.5z"/>
   <path style="fill:#000;" d="M11.8,11.8c-0.3,0.1-0.6,0.4-0.8,1c0,0-0.4,0.7-0.6,2.3c0,0-0.1,2,0.1,3.5c0,0,0,1.3,0.8,2.6c0,0,0.6,0.7,1.3,0
     c0,0,0.6-0.7,0.8-2.3c0,0,0.1-1.5,0.1-2.3c0,0,0-2.1-0.3-3c0,0-0.2-1.2-0.8-1.7C12.3,11.7,12,11.7,11.8,11.8z"/>
 </g>
 </svg>`;
    case "ship":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}"> 
 <path style="fill:${color}" d="M15.1,22.9c0,0,1.7,0.2,1.6-1.1l0.2-13.5c0,0-1.1-4.1-3.7-6.8c0,0-1.2-1.4-2.4,0.1c0,0-2.6,3-3.8,6.3
   c0,0,0.3,12.2,0.3,14.4c0,0-0.1,0.5,1,0.6L15.1,22.9z"/>
 <path style="fill:#000;" d="M13.2,3c0,0,1.6,1.8,2.3,5.5c0,0-0.3,0.1-0.9-1.3C14.6,7.2,13.4,3.9,13.2,3z"/>
 <path style="fill:#000;" d="M10.7,3c0,0-1.6,1.8-2.2,5.6c0,0,0.3,0.1,0.9-1.3C9.3,7.3,10.6,3.9,10.7,3z"/>
 <path style="fill:#000;" d="M11.6,4.4c0,0-0.4,0-0.5,0.3l-0.2,0.5c0,0.1,0,0.2,0.2,0.3c0,0,0.1,0,0.2,0h1.5c0,0,0.3,0,0.2-0.2l-0.3-0.6
   C12.6,4.6,12.6,4.3,11.6,4.4z"/>
 <path style="fill:#000;" d="M11.6,6.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.6,0.2l-0.3,0.9c0,0-0.2,0.2,0.5,0.3l2.2,0c0,0,0.6,0.1,0.4-0.3
   l-0.3-0.8c0,0-0.1-0.3-0.5-0.3L11.6,6.1z"/>
 <path style="fill:#000;" d="M9.5,18.8c0,0-0.9,0-0.6,1.1l0.6,1.7c0,0,0,0.4,0.9,0.4h3.5c0,0,0.6,0.1,0.9-0.8l0.4-1.6c0,0,0-0.8-0.9-0.8
   L9.5,18.8L9.5,18.8z"/>
 <path style="fill:#000;" d="M10.5,12.3h3c0,0,0.5,0,0.3,0.6L13,15.7c0,0,0,0.4-0.5,0.4h-0.9c0,0-0.5,0-0.7-0.7l-0.7-2.5
   C10.2,12.9,9.8,12.3,10.5,12.3z"/>
 </svg>`;
    case "shovel":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M10.8,14.9l3.4,2.3c0,0-2,2.5-4.8,4.4c0,0-3.1,2.4-5,0.9c0,0-1.9-0.9-0.9-5.2c0,0,0.9-3.4,2.2-5.6l3.4,2.1
     l4.4-6.6c0,0-2.2-1.5-0.3-4.8c0,0,0.1-0.1-0.1-0.1L13,2.2L13.7,1c0,0,0-0.1,0.1,0l6.8,4.4c0,0,0.1,0.1,0.1,0.2l-0.7,1
     c0,0-0.1,0.1-0.1,0.1l-0.3-0.2c0,0-0.1-0.1-0.1,0.1c0,0-0.4,0.8-1.6,1.6c0,0-1.3,0.8-2.8,0.3L13,11.5L10.8,14.9z"/>
   <path style="fill:#000;" d="M13.6,7.3c0,0-2.2-1.5-0.3-4.8c0,0,0.1-0.1-0.1-0.1L13,2.2l0.8-1.1c0,0,0-0.1,0.1,0l6.8,4.4c0,0,0.1,0.1,0.1,0.2l-0.7,1
     c0,0-0.1,0.1-0.1,0.1l-0.3-0.2c0,0-0.1-0.1-0.1,0.1c0,0-0.4,0.8-1.6,1.6c0,0-1.3,0.8-2.8,0.3"/>
 </g>
 </svg>`;
    case "skidloader":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <g>
       <path style="fill:${color}" d="M10.1,6.8H9.3H8.9c0,0-0.1-0.2-0.5-0.3H7.6c0,0-0.6,0-0.6,0.6v3.1c0,0,0,0.3,0.3,0.5v10.6l0.1,0.2v0.7
         c0,0,0.1,0.3,0.6,0.5h0.5c0,0,0.3-0.1,0.4-0.3c0,0,1.3,0.6,2.8,0.6c0,0,0.4,0,0.7,0c0,0,1.9-0.2,2.6-0.6c0,0,0.1,0.3,0.4,0.3
         c0,0,0.3,0,0.5,0c0,0,0.5,0,0.5-0.5v-0.6c0,0,0.1,0,0.1-0.3V10.8v-0.1c0,0,0.3-0.1,0.3-0.5V7.2c0,0,0.1-0.6-0.6-0.7h-0.8
         c0,0-0.3,0-0.5,0.3h-1.2"/>
       <path style="fill:#000;" d="M9.3,18.7v-3.2c0-0.3,0.1-0.7,0.2-0.9c0.1-0.3,0.4-0.7,0.9-0.7h2.9c0,0,0.8,0.1,0.9,1.4V19c0,0-0.1,1.2-0.9,1.2h-2.8
         c0,0-0.8,0.1-1.1-0.9C9.3,19.1,9.3,18.9,9.3,18.7z"/>
     </g>
   </g>
   <rect x="10" y="6.1" style="fill:#000;" width="4" height="2.1"/>
   <rect x="7.5" y="5" style="fill:#000;" width="8.9" height="1"/>
   <polygon style="fill:#000;" points="7.7,1.9 16.2,1.9 17,5.2 7,5.2 	"/>
   <polygon style="fill:#000;" points="8.1,1.9 8,1 7.8,1.9 	"/>
   <polygon style="fill:#000;" points="9.1,1.9 9.3,1 9.5,1.9 	"/>
   <polygon style="fill:#000;" points="10.4,1.9 10.7,1 10.8,1.9 	"/>
   <polygon style="fill:#000;" points="11.8,1.9 11.9,1 12.1,1.9 	"/>
   <polygon style="fill:#000;" points="13.1,1.9 13.3,1 13.5,1.9 	"/>
   <polygon style="fill:#000;" points="14.5,1.9 14.6,1 14.8,1.9 	"/>
   <polygon style="fill:#000;" points="15.9,1.9 15.9,1 16.2,1.9 	"/>
 </g>
 </svg>`;
    case "smallbus":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}"  d="M15.5,1.2c-0.6-0.3-6.5-0.3-7.1,0s-1.4,4-1.4,4V23h9.8V5.3C16.9,5.3,16.2,1.6,15.5,1.2z"/>
   <path style="fill:#000;" d="M8.1,2.6C8,2.7,7.8,3.2,7.7,3.7C7.6,4.2,7.3,6,7.4,6s0.4,0.1,0.5,0C8,5.9,8.1,4.7,8.2,4.3
     c0.1-0.7,0.3-1.1,0.2-1.2C8.4,3.1,8.2,2.5,8.1,2.6z"/>
   <path style="fill:#000;" d="M7.9,9.1c0,0.1-0.1,0.2-0.3,0.2l0,0c-0.1,0-0.3-0.1-0.3-0.2V6.5c0-0.1,0.1-0.2,0.3-0.2l0,0
     c0.1,0,0.3,0.1,0.3,0.2V9.1z"/>
   <path style="fill:#000;" d="M7.9,12.5c0,0.1-0.1,0.2-0.3,0.2l0,0c-0.1,0-0.3-0.1-0.3-0.2V9.8c0-0.1,0.1-0.2,0.3-0.2l0,0
     c0.1,0,0.3,0.1,0.3,0.2V12.5z"/>
   <path style="fill:#000;" d="M7.9,15.8c0,0.1-0.1,0.2-0.3,0.2l0,0c-0.1,0-0.3-0.1-0.3-0.2v-2.7c0-0.1,0.1-0.2,0.3-0.2l0,0
     c0.1,0,0.3,0.1,0.3,0.2V15.8z"/>
   <path style="fill:#000;" d="M7.9,19.1c0,0.1-0.1,0.2-0.3,0.2l0,0c-0.1,0-0.3-0.1-0.3-0.2v-2.7c0-0.1,0.1-0.2,0.3-0.2l0,0
     c0.1,0,0.3,0.1,0.3,0.2V19.1z"/>
   <path style="fill:#000;" d="M7.9,22.5c0,0.1-0.1,0.2-0.3,0.2l0,0c-0.1,0-0.3-0.1-0.3-0.2v-2.7c0-0.1,0.1-0.2,0.3-0.2l0,0
     c0.1,0,0.3,0.1,0.3,0.2V22.5z"/>
   <g>
     <path style="fill:#000;" d="M15.9,2.5c0.1,0.1,0.3,0.7,0.4,1.1c0.1,0.5,0.4,2.2,0.3,2.3c-0.1,0-0.4,0.1-0.5,0c-0.1-0.1-0.2-1.3-0.3-1.7
       c-0.1-0.7-0.3-1.1-0.2-1.2C15.6,3,15.8,2.4,15.9,2.5z"/>
     <path style="fill:#000;" d="M16.1,9.1c0,0.1,0.1,0.2,0.3,0.2l0,0c0.1,0,0.3-0.1,0.3-0.2V6.4c0-0.1-0.1-0.2-0.3-0.2l0,0
       c-0.1,0-0.3,0.1-0.3,0.2V9.1z"/>
     <path style="fill:#000;" d="M16.1,12.4c0,0.1,0.1,0.2,0.3,0.2l0,0c0.1,0,0.3-0.1,0.3-0.2V9.7c0-0.1-0.1-0.2-0.3-0.2l0,0
       c-0.1,0-0.3,0.1-0.3,0.2V12.4z"/>
     <path style="fill:#000;" d="M16.1,15.7c0,0.1,0.1,0.2,0.3,0.2l0,0c0.1,0,0.3-0.1,0.3-0.2v-2.7c0-0.1-0.1-0.2-0.3-0.2l0,0
       c-0.1,0-0.3,0.1-0.3,0.2V15.7z"/>
     <path style="fill:#000;" d="M16.1,19c0,0.1,0.1,0.2,0.3,0.2l0,0c0.1,0,0.3-0.1,0.3-0.2v-2.7c0-0.1-0.1-0.2-0.3-0.2l0,0
       c-0.1,0-0.3,0.1-0.3,0.2V19z"/>
     <path style="fill:#000;" d="M16.1,22.4c0,0.1,0.1,0.2,0.3,0.2l0,0c0.1,0,0.3-0.1,0.3-0.2v-2.7c0-0.1-0.1-0.2-0.3-0.2l0,0
       c-0.1,0-0.3,0.1-0.3,0.2V22.4z"/>
   </g>
   <polygon style="fill:#000;" points="15.2,2 15.8,1.7 16.9,1.7 16.9,1.9 15.9,1.8 15.3,2.1 	"/>
   <polygon style="fill:#000;" points="8.8,2 8.2,1.7 7.1,1.7 7.1,1.9 8.1,1.8 8.7,2.1 	"/>
   <path style="fill:#000;" d="M8.5,1.8c-0.2,0.1,0.2,1,0.2,1s2-0.2,3-0.2c2.7,0,3.2,0.3,3.4,0.2c0.1-0.1,0.4-1,0.3-1.1
     c-0.2-0.2-1.6-0.3-3.4-0.3C10.1,1.5,8.9,1.7,8.5,1.8z"/>
   <path style="fill:#000;" d="M14.2,8.7c0,0.1-0.1,0.3-0.3,0.3h-3.8C9.9,9,9.7,8.9,9.7,8.7V6.2c0-0.1,0.1-0.3,0.3-0.3h3.8
     c0.2,0,0.3,0.1,0.3,0.3C14.2,6.2,14.2,8.7,14.2,8.7z"/>
   <path style="fill:#000;" d="M14.3,17.3c0,0.1-0.1,0.3-0.3,0.3h-3.8c-0.2,0-0.3-0.1-0.3-0.3v-2.5c0-0.1,0.1-0.3,0.3-0.3h3.8
     c0.2,0,0.3,0.1,0.3,0.3V17.3z"/>
   <g>
     <path style="fill:#000;" d="M9.9,1.1C9.9,1.2,9.9,1.2,9.9,1.1l-1.3,0c0,0-0.1,0-0.1,0l0,0c0,0,0,0,0.1,0L9.9,1.1C9.9,1.1,9.9,1.1,9.9,1.1
       L9.9,1.1z"/>
   </g>
   <g>
     <path style="fill:#000;" d="M15.4,1.1C15.4,1.2,15.4,1.2,15.4,1.1l-1.3,0c0,0-0.1,0-0.1,0l0,0c0,0,0,0,0.1,0L15.4,1.1
       C15.4,1.1,15.4,1.1,15.4,1.1L15.4,1.1z"/>
   </g>
 </g>
 </svg>`;
    case "suv":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:#000;" d="M9.9,1.4c0,0-1.1,0.3-1.5,1.9L8.2,8v7.7v2.5c0,0,0,2.5,0.6,3.7c0,0,1.5,0.7,3.2,0.7c0,0,2.3,0.3,3.2-0.7
     c0,0,0.6-2.5,0.6-3.7V9.5l-0.2-5.1V3.2c0,0-0.2-1.5-1.5-1.9L9.9,1.4L9.9,1.4z"/>
   <path style="fill:${color}" d="M16.6,8.8c0,0-0.2-0.2-0.5-0.2c0,0-0.1,0.1-0.2,0.3c0,0,0-4.1,0-5.1c0,0-0.4-2.8-2.3-2.8c0,0-2-0.1-2.6,0
     c0,0-2.3-0.2-2.7,3.4V9c0,0-0.1-0.6-0.6-0.3c0,0-0.6,0.4-0.6,1.2c0,0,1.1-0.6,1.2-0.5c0,0-0.1,7.2,0,9.4c0,0,0.1,2.5,0.6,3.4
     c0,0,0.1,0.6,2.1,0.8c0,0,2.3,0.3,3.8-0.3c0,0,0.4,0,0.6-0.8c0,0,0.4-1.2,0.5-3.6c0,0,0-7.8,0-8.9c0,0,0.6,0.2,1.2,0.6
     C17,10,17.2,9.4,16.6,8.8z M14.3,1.7c0.6,0.4,0.9,1.1,0.9,1.1c0,0.5-0.4,0.1-0.4,0.1c-0.4-0.3-0.8-1-0.8-1
     C13.9,1.4,14.3,1.7,14.3,1.7z M8.8,2.7c0.2-0.5,0.8-1,0.8-1c0.3-0.2,0.2,0.2,0.2,0.2c-0.2,0.4-0.8,1-0.8,1C8.6,3.2,8.8,2.7,8.8,2.7
     z M8.8,21.6C8.4,20.2,8.3,18,8.3,18l0-9.8C8.4,7.6,8.5,8,8.5,8c0.5,2.5,0.4,5.2,0.4,5.2c0,0.6,0,7.9,0,7.9
     C9,22.1,8.8,21.6,8.8,21.6z M14.6,21.7c-0.6,0.6-1.7,0.6-1.7,0.6c-0.9,0.1-1.7,0-1.7,0c-1.7-0.2-1.8-0.7-1.8-0.7v-0.7
     c1.6,0.2,2.6,0.1,2.6,0.1c1.3,0,2.5-0.1,2.5-0.1V21.7z M14.7,10.9c0,0-0.8-0.2-2.9-0.2c0,0-1.4,0-2.6,0.2c0,0-0.1-1.4-0.4-3.2
     c0,0-0.1-0.5,0.2-0.6c0,0,1.1-0.4,3-0.4c0,0,2.2,0,3,0.5c0,0,0.1,0.1,0.1,0.6c0,0-0.3,1.9-0.4,2.9C14.8,10.7,14.8,10.9,14.7,10.9z
      M15.5,19.6c0,0,0,0.9-0.4,2.2c0,0,0-5-0.1-5.7c0,0,0-4.8,0.1-5.4c0,0,0.4-2.9,0.5-2.8C15.6,7.8,15.7,18.5,15.5,19.6z"/>
 </g>
 </svg>
 `;
    case "threetonpickup":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <path style="fill:${color}" d="M16.1,10V2.1c-0.2-0.2-0.1-0.1-0.4-0.2l-1-0.4c-0.4-0.2-0.9-0.3-1.3-0.3c-0.9-0.1-1.8-0.1-2.6,0
       c-0.5,0-0.9,0.1-1.3,0.3l-1,0.4C8.1,2,8.2,1.9,8,2.1v7.8c0,1.3,0.3,1.6,0.6,1.6h6.9C15.8,11.5,16.1,11.3,16.1,10z"/>
     <path style="fill:#000;" d="M14.8,9.5C14.8,9.5,14.7,9.5,14.8,9.5c-0.1,0-0.1-0.1-0.1-0.1c0-0.8,0-1.3,0.1-1.5c0-0.2,0.4-0.8,1-1.6l0,0
       l0,0l0,0l0,3c0,0.1-0.1,0.1-0.1,0.1L14.8,9.5L14.8,9.5L14.8,9.5z"/>
     <path style="fill:#000;" d="M9.2,9.5L9.2,9.5L8.3,9.3c-0.1,0-0.1-0.1-0.1-0.1l0-2.9l0,0l0,0l0,0c0.6,0.8,1,1.4,1,1.6
       C9.2,8.2,9.3,8.6,9.2,9.5C9.3,9.4,9.3,9.4,9.2,9.5C9.3,9.5,9.2,9.5,9.2,9.5z"/>
     <path style="fill:#000;" d="M14.3,7.5L14.3,7.5c-0.9-0.1-1.7-0.1-2.5-0.1c-0.8,0-1.5,0-2.3,0.1h0c-0.1,0-0.2-0.1-0.3-0.1L8.3,5.2
       c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.1,0.3-0.2c0.9-0.2,1.9-0.2,3-0.2s2.2,0.1,3.4,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.2,0,0.3
       l-0.9,2.1C14.5,7.5,14.4,7.5,14.3,7.5z"/>
     <path style="fill:#000;" d="M14.3,7.4L14.3,7.4c-0.9-0.1-1.7-0.1-2.5-0.1c-0.8,0-1.5,0-2.3,0.1l0,0c0,0,0,0-0.1,0L8.5,5.2
       c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.9-0.2,1.9-0.2,3-0.2s2.2,0.1,3.4,0.2c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1L14.3,7.4
       C14.3,7.3,14.3,7.4,14.3,7.4z"/>
     <g>
       <path style="fill:#000;" d="M15.8,2.5v0.3l-0.9-0.4c-0.2-0.1-0.3-0.3-0.3-0.5V1.6L15.4,2C15.7,2.2,15.8,2.3,15.8,2.5z"/>
     </g>
     <g>
       <path style="fill:#000;" d="M8.1,2.5v0.3L9,2.4C9.2,2.3,9.4,2.2,9.4,2V1.6L8.5,2C8.3,2.2,8.1,2.3,8.1,2.5z"/>
     </g>
     <g>
       <path style="fill:#000;" d="M10.7,1.2c-0.2,0-0.4,0.1-0.5,0.3l-0.1,0.3C10,2,10.1,2,10.2,2c0.3,0,1-0.1,1.6-0.1c0.8,0,1.3,0.1,1.6,0.1
         c0.1,0,0.2-0.1,0.1-0.1l-0.1-0.3c-0.1-0.1-0.3-0.3-0.5-0.3"/>
     </g>
     <rect x="7.9" y="11.5" width="8.1" height="11.5"/>
     <rect x="8.1" y="11.7" width="7.7" height="11.1"/>
     <rect x="8.6" y="12.9" style="fill: #78909C;" width="6.8" height="9.1"/>
     <path style="fill:#000;" d="M8,11.3c0.1,0.2,0.3,0.3,0.5,0.3h6.9c0.2,0,0.5-0.1,0.5-0.3l0,0c-0.2,0.1-0.5,0.1-0.7,0.1H8.7
       C8.5,11.3,8.2,11.3,8,11.3L8,11.3z"/>
     <polygon style="fill:#000;" points="8.6,12.9 8.1,12.4 15.9,12.4 15.4,12.9 		"/>
   </g>
   <g>
     <path style="fill:${color}" d="M7.2,5.6l0.9-0.3V4.8L7.3,5.2C7.2,5.3,7.1,5.4,7.2,5.6C7,5.6,7.1,5.7,7.2,5.6z"/>
     <path style="fill:${color}" d="M16.8,5.6l-0.9-0.3V4.8l0.8,0.4C16.8,5.3,16.9,5.4,16.8,5.6C17,5.6,16.9,5.7,16.8,5.6z"/>
   </g>
 </g>
 </svg>`;
    case "tanker":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M8.7,6.5V5.6H8.2c0,0-0.1,0-0.1-0.1v-3c0,0,0.2-0.1,0-0.1c0,0-0.1,0-0.2,0c0,0-0.6,0.1-0.8,0
     c0,0-0.1-0.2,0.4-0.2c0,0,0.4,0,0.6,0.1c0,0-0.1-0.2-0.1-0.7C8,1.6,8,1,10.1,1c0,0,3.6-0.1,5.2,0.1c0,0,0.6,0.1,0.7,0.4
     c0,0,0,0.4-0.1,0.7c0,0,0.1,0.1,0.3,0c0,0,0.6-0.1,0.7,0.2c0,0-0.1,0-1-0.1c0,0,0,0.1,0.1,0.1l0,3c0,0,0,0.1-0.2,0.1h-0.4l0,0.9
     c0,0-0.5-0.4-1.1-0.7c0,0-0.5-0.2-1.6-0.2c0,0-1.9-0.1-2.5,0.1C10.1,5.8,9.5,5.8,8.7,6.5z"/>
   <path style="fill:#000;" d="M8.2,1.7c0,0,0-0.1,0.1-0.2c0,0,0.6-0.3,2.6-0.3c0,0,3.3-0.1,4.3,0.2c0,0,0.2,0,0.4,0.1c0,0,0.1,0,0,0.2
     c0,0-0.1,0.3-0.1,0.5c0,0-2.8-0.5-7.1,0C8.4,2.3,8.3,2,8.2,1.7z"/>
   <ellipse style="fill:#000;" cx="14.4" cy="4.6" rx="0.3" ry="0.2"/>
   <ellipse style="fill:#000;" cx="9.5" cy="4.6" rx="0.3" ry="0.2"/>
   <path style="fill:#000;" d="M9.3,20.6v1.3H8.1v0.6h0.2c0,0,0.1,0.5,0.3,0.4c0,0,0.3,0,0.3-0.4H9c0,0,0,0.4,0.2,0.4h0.2c0,0,0.1,0,0.2-0.5h0.1
     c0,0,0,0.4,0.2,0.5h0.3c0,0,0.2,0,0.2-0.4l3.4,0c0,0-0.1,0.5,0.4,0.4c0,0,0.2,0,0.2-0.4l0.1,0c0,0,0,0.5,0.2,0.5h0.2
     c0,0,0.2,0,0.2-0.4h0.1c0,0,0,0.5,0.2,0.4h0.2c0,0,0.2-0.1,0.2-0.4h0.2v-0.7h-1.3v-1.2c0,0-0.4,0.9-1.4,1.2c0,0-1,0.2-2.6,0
     C10.7,21.8,9.8,21.6,9.3,20.6z"/>
   <g>
     <path style="fill:${color}" d="M10.8,21.5c0,0-1.2,0-1.8-0.7l0,0c0,0-0.3-0.2-0.3-0.6V7.4l0,0c0,0,0.1-0.3,0.7-0.7l0,0c0,0,0.7-0.4,1.6-0.4
       h0.4l0,0c0.2,0,0.4,0,0.7,0c1,0,1.2,0,1.3,0h0h0c0.1,0,1,0,1.7,0.7c0.1,0.1,0.3,0.3,0.3,0.6l0,12.7c0,0.1-0.2,0.4-0.7,0.8
       c-0.1,0-0.8,0.4-1.6,0.4L10.8,21.5L10.8,21.5z"/>
     <path style="fill:${color}" d="M12,6.6c1,0,1.2,0,1.2,0l0.1,0h0.1c0,0,0.8,0,1.5,0.6c0,0,0.1,0.2,0.2,0.3l0,12.5c0,0.1-0.2,0.3-0.5,0.6
       c-0.1,0.1-0.7,0.3-1.4,0.3h-2.2c-0.3,0-1.1-0.1-1.6-0.6l0,0l0-0.1c0,0-0.1-0.2-0.2-0.3V7.5c0-0.1,0.1-0.2,0.4-0.5
       c0.1,0,0.7-0.4,1.4-0.4h0.8l0,0C11.7,6.6,11.9,6.6,12,6.6 M12,5.7c-0.6,0-1.1,0-1.1,0c-1,0-1.8,0.5-1.8,0.5
       C8.3,6.8,8.3,7.4,8.3,7.4v12.7c0.1,0.6,0.5,1,0.5,1c0.8,0.8,2.1,0.8,2.1,0.8h2.2c1,0,1.8-0.5,1.8-0.5c0.8-0.6,0.9-1.2,0.9-1.2
       l0-12.7c-0.1-0.5-0.4-1-0.4-1c-0.8-0.7-1.8-0.7-2-0.7h0C13.2,5.7,12.6,5.7,12,5.7L12,5.7z"/>
   </g>
 </g>
 <ellipse style="fill:#000;" cx="12.1" cy="10.2" rx="1.2" ry="1.3"/>
 <ellipse style="fill:#000;" cx="12.1" cy="18.2" rx="1.2" ry="1.3"/>
 </svg>`;
    case "tractor":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:#000;" d="M16,13.1H9l-1.5,0.5v6.9l9.2-0.3C16.7,20.3,17.2,13.7,16,13.1z"/>
   <g>
     <g>
       <path style="fill:${color}" d="M7.2,21.9C7.2,23.1,7.8,23,7.8,23h8.2c0.8-0.1,0.8-1.2,0.8-1.2l-0.1-0.3c0-0.9,0-0.9,0-0.9v-4.5
         c0-0.7,0-1.4,0-1.4l0.1-0.3v-0.7c0-0.5-0.5-0.8-0.5-0.8h-0.5L15.1,2.3C15,1.3,14.5,1,14.5,1h-1.4l0,0h-2.3l-0.1,0.1L9.4,1.1
         c-0.5,0.2-0.5,1-0.5,1L8.5,7.3l-0.3,5.6L7.6,13c-0.4,0.2-0.5,0.7-0.5,0.7v0.7 M8.5,19H7.8v-4.4c0.2,0.3,0.7,0.9,0.7,0.9V19z
          M16.1,19h-0.7v-3.5c0,0,0.4-0.4,0.7-0.9V19z M15.6,14.9H8.4c-0.2-0.3-0.6-1-0.6-1c4.8-1.5,8.2,0,8.2,0
         C15.8,14.3,15.6,14.9,15.6,14.9z"/>
       <rect x="7.8" y="6.5" style="fill:#000;" width="1.2" height="0.7"/>
       <rect x="15" y="6.5" style="fill:#000;" width="1.2" height="0.7"/>
       <path style="fill:#000;" d="M7.7,10.2L7.7,10.2c-0.3,0-0.5-0.3-0.5-0.7V4.2c0-0.4,0.2-0.7,0.5-0.7l0,0c0.3,0,0.5,0.3,0.5,0.7v5.3
         C8.2,9.9,8,10.2,7.7,10.2z"/>
       <path style="fill:#000;" d="M16.3,10.2L16.3,10.2c-0.3,0-0.5-0.3-0.5-0.7V4.2c0-0.4,0.2-0.7,0.5-0.7l0,0c0.3,0,0.5,0.3,0.5,0.7v5.3
         C16.9,9.9,16.7,10.2,16.3,10.2z"/>
     </g>
     <path style="fill:#000;" d="M6.8,22L6.8,22C6.4,22,6,21.5,6,20.9v-5.5c0-0.6,0.4-1.1,0.8-1.1l0,0c0.4,0,0.8,0.5,0.8,1.1v5.5
       C7.5,21.5,7.3,22,6.8,22z"/>
     <path style="fill:#000;" d="M17.2,22L17.2,22c-0.4,0-0.8-0.5-0.8-1.1v-5.5c0-0.6,0.4-1.1,0.8-1.1l0,0c0.4,0,0.8,0.5,0.8,1.1v5.5
       C18,21.5,17.6,22,17.2,22z"/>
   </g>
 </g>
 </svg>`;
    case "train":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <rect style="fill:#000;" x="11.2" y="9.5" width="1.6" height="14"/>
   <path style="fill:#000;" d="M12,0c0,0-1.1,0-2,0.3C9.4,0.4,9,0.7,8.9,1.1c-0.3,1-1.1,3.4-0.8,7.7l7.8-0.4c0,0,0.2-3.4-0.9-7.3
     c-0.1-0.4-0.4-0.6-0.9-0.8C13.6,0.2,13,0,12,0z"/>
   <path d="M15.8,11.9v9.4c0,0-0.2,2.4-3.7,2.7c0,0-3.4,0-4-2.7v-9.6c0,0-0.3-1.3,1.3-1.3c0.4,0,5,0.1,5.3,0.1
     C16,10.4,15.6,11.2,15.8,11.9z"/>
   <path style="fill:${color}" d="M15.4,9.9c0,0,0.6,0.2,0.6-0.6c0,0,0.3-3.8-1-6.7C14.8,2.2,14.5,2,14,1.9c-0.5-0.2-1.2-0.3-2.3-0.3
     c0,0-1,0-1.8,0.2C9.3,2,8.9,2.3,8.8,2.7c-0.2,0.8-0.6,3-0.7,4.6c0,0-0.1,2,0,2.4c0,0,0,0.4,0.5,0.2L15.4,9.9L15.4,9.9z"/>
   <path style="fill:#000;" d="M9.7,7.6V5.9c0-0.2,0.1-0.4,0.2-0.5c0.2-0.2,0.4-0.4,0.8-0.4h2.8c0,0,0.8,0,0.9,0.8v2c0,0-0.1,0.6-0.9,0.6h-2.7
     c0,0-0.8,0-1-0.5C9.7,7.8,9.7,7.7,9.7,7.6z"/>
   <path style="fill:#000;" d="M9.7,19.9v-1.7c0-0.2,0.1-0.4,0.2-0.5c0.2-0.2,0.4-0.4,0.8-0.4h2.8c0,0,0.8,0,0.9,0.8v2c0,0-0.1,0.6-0.9,0.6h-2.7
     c0,0-0.8,0-1-0.5C9.7,20.1,9.7,20,9.7,19.9z"/>
 </g>
 </svg>`;
    case "tram":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M7.1,7.3c0,0-0.3-6.3,4.2-6.3h1.4c0,0,4.1-0.4,4.3,5.8l-0.1,11.4c0,0-0.7,2-2.1,3.9H9.2c0,0-1.2-1.2-2.2-3.9
     L7.1,7.3L7.1,7.3z"/>
   <path style="fill: #000;" d="M7.8,6.4l1.6-2.3c0,0,0.2,0.1,0.4,2L8,8.1c0,0-0.2,0.2-0.2-0.1V6.4z"/>
   <polygon style="fill: #000;" points="10.3,5.9 13.8,5.9 14.4,3.9 9.9,3.9 	"/>
   <path style="fill: #000;" d="M16.4,6.4l-1.6-2.3c0,0-0.2,0.1-0.4,2l1.8,2.1c0,0,0.2,0.2,0.2-0.1L16.4,6.4L16.4,6.4z"/>
   <path style="fill: #000;" d="M7.4,5.7c0,0,0.5-3.9,2.9-4.3c0,0,1.8-0.4,3.5,0c0,0,2.4,0.3,2.8,4.3c0,0-1.1-2.4-1.8-3H9.2c0,0-1,1.2-1.6,2.8
     C7.5,5.7,7.4,5.9,7.4,5.7z"/>
 </g>
 <path style="fill: #000;" d="M10.3,11c0,0,0-0.3,0.4-0.3h2.6c0,0,0.4,0,0.4,0.4v2.8l0.2,1.5l-0.1,0.1l1.3,6.5h0.2V23h-0.8l0-1l0.2-0.1l-1.1-5.3l-3.2,0.1
   l-1.2,5.4l0.2,0.1V23H8.7v-1.1H9l1.2-6.3l-0.2-0.1l0.3-1.2L10.3,11L10.3,11z"/>
 </svg>`;
    case "trolleybus":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <path style="fill: #000;" d="M16.1,1.9l0.2-0.6c0,0-0.9-0.4-2,0h-3.9L10,1.4L9.7,1.3c0,0-0.7-0.4-2,0v1L7,7.6l0.3,13.4l0.4,1.8l0.7,0l0.9,0l0.7-0.3h3.6
   l0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.1h0.4c0.3,0,0.7,0,0.9-0.2l0.2-0.1l0.4-2.2V3.9L16.1,1.9z"/>
 <path style="fill:${color}" d="M17,3.3c-0.3-1.1-0.5-2.1-0.5-2.1C16,1,15.2,1,15.2,1c-1,0-1.1,0.2-1.1,0.2c-2.4-0.2-4.3,0-4.3,0
   C9.9,1.1,9.4,1,9.4,1C9.2,1,8,1,8,1C7.8,1,7.6,1.2,7.6,1.2c0,0.2-0.2,1.1-0.2,1.1C7.2,2.7,7.1,3.1,7.1,3.1v17.7
   c0,0.3,0.1,0.4,0.1,0.4c0.3,0.7,0.4,1.5,0.4,1.5C7.6,22.9,8,23,8,23h1.3c0.5,0,0.5-0.2,0.5-0.2c2.7,0.2,4.3,0,4.3,0
   c0.3,0.2,1,0.1,1,0.1c1.1,0,1.2-0.2,1.2-0.2c0.3-1.3,0.4-1.5,0.4-1.5c0.2-0.3,0.2-0.6,0.2-0.6L17,3.3L17,3.3z M15.3,1.2
   c0.5,0,0.9,0.1,0.9,0.1c0,0-0.4,0.2-0.9,0.2c-0.5,0-0.9-0.1-0.9-0.2C14.3,1.2,14.8,1.2,15.3,1.2z M8.8,1.2c0.5,0,0.9,0.1,0.9,0.1
   c0,0-0.4,0.2-0.9,0.2c-0.5,0-0.9-0.1-0.9-0.1C8,1.2,8.3,1.2,8.8,1.2z M8.7,23c-0.5,0-0.9-0.1-0.9-0.1c0-0.1,0.4-0.1,0.9-0.1
   c0.5,0,0.9,0.1,0.9,0.1C9.7,22.9,9.3,23,8.7,23z M15.3,23c-0.5,0-0.9-0.1-0.9-0.1c0-0.1,0.4-0.1,0.9-0.1c0.5,0,0.9,0.1,0.9,0.1
   C16.1,22.9,15.7,23,15.3,23z M14.2,22.5c-1.1,0.1-4.1,0-4.1,0c-2.5-0.3-2.4-1.9-2.4-1.9c1.5,0.4,4.3,0.5,4.3,0.5
   c3.1,0,4.5-0.5,4.5-0.5C16.2,22.3,14.2,22.5,14.2,22.5z M8.4,6.3V9H7.6V6.3C7.6,6.3,8.4,6.3,8.4,6.3z M7.6,6.1V3.7l0.8-0.2v2.6H7.6z
    M8.4,9.2V12H7.6V9.2C7.6,9.2,8.4,9.2,8.4,9.2z M8.4,12.1v2.8H7.6v-2.8H8.4z M8.4,15.1v2.8H7.6v-2.8H8.4z M8.4,18v2.6l-0.8-0.2V18
   C7.6,18,8.4,18,8.4,18z M16.4,20.4l-0.8,0.2V18h0.8V20.4z M16.4,17.8h-0.8v-2.8h0.8V17.8z M16.4,14.9h-0.8v-2.8h0.8V14.9z M16.4,12
   h-0.8V9.2h0.8V12z M16.4,9h-0.8V6.3h0.8V9z M16.4,6.1h-0.8V3.5l0.8,0.1V6.1z M12,3c0,0-2.1-0.1-4.4,0.5c0,0,0-0.7,0.8-1.4
   c0,0,0.7-0.5,1.9-0.6h3.4c0,0,0.5,0,1.2,0.2c0.5,0.2,1,0.5,1.2,0.9c0.1,0.2,0.2,0.5,0.3,0.8C16.4,3.5,14.8,3,12,3z"/>
 <path style="fill: #000;" d="M10.9,7.2c0,0,0-0.2,0.3-0.2h1.8c0,0,0.3,0,0.3,0.3v2.4l0.2,1.2l-0.1,0.1l0.9,5.3h0.2V17h-0.6l0-0.8l0.2-0.1l-0.7-4.4
   l-2.1,0.1l-0.8,4.5l0.2,0.1v0.7H9.8v-0.9H10l0.9-5.2l-0.2-0.1l0.2-1L10.9,7.2L10.9,7.2z"/>
 </svg>`;
    case "trolly":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M7.9,7.5c0,0-0.2-6.5,3.4-6.5h1.2c0,0,3.4-0.4,3.5,6l-0.1,11.9c0,0-0.5,2.1-1.7,4.1H9.6c0,0-1-1.3-1.8-4.1
     L7.9,7.5L7.9,7.5z"/>
   <path style="fill: #000;" d="M8.5,6.6l1.3-2.4c0,0,0.1,0.1,0.4,2.1L8.6,8.4c0,0-0.1,0.2-0.1-0.1V6.6z"/>
   <polygon style="fill: #000;" points="10.5,6.1 13.4,6.1 13.8,4 10.2,4 	"/>
   <path style="fill: #000;" d="M15.5,6.6l-1.3-2.4c0,0-0.1,0.1-0.4,2.1l1.5,2.1c0,0,0.1,0.2,0.1-0.1L15.5,6.6L15.5,6.6z"/>
   <path style="fill: #000;" d="M8.1,5.9c0,0,0.4-4.1,2.4-4.5c0,0,1.5-0.4,2.9,0c0,0,2,0.3,2.3,4.5c0,0-0.9-2.5-1.5-3.1H9.6c0,0-0.8,1.3-1.3,3
     C8.2,5.9,8.1,6.1,8.1,5.9z"/>
   <path style="fill: #000;" d="M14.7,18.4c0,0,1.4,1.6,1.6,3.6l0.6-0.1c0,0,0.3,0.2-0.1,0.5l-1.6,0.3c0,0-0.3-0.2,0.1-0.4l0.5-0.1c0,0-0.2-1.6-1.5-2.8
     C14.4,19.3,14.4,18.7,14.7,18.4z"/>
   <path style="fill: #000;" d="M9.3,18.4c0,0-1.4,1.6-1.6,3.6l-0.6-0.1c0,0-0.3,0.2,0.1,0.5l1.6,0.2c0,0,0.3-0.2-0.1-0.4l-0.5-0.1c0,0,0.2-1.6,1.5-2.8
     C9.6,19.3,9.6,18.7,9.3,18.4z"/>
   <path style="fill: #000;" d="M8.5,19l1.3,2.4c0,0,0.1-0.1,0.4-2.1l-1.6-2.1c0,0-0.1-0.2-0.1,0.1V19z"/>
   <polygon style="fill: #000;" points="10.5,19.6 13.4,19.6 13.8,21.7 10.2,21.7 	"/>
   <path style="fill: #000;" d="M15.5,19l-1.3,2.4c0,0-0.1-0.1-0.4-2.1l1.5-2.1c0,0,0.1-0.2,0.1,0.1L15.5,19L15.5,19z"/>
 </g>
 </svg>`;
    case "truck":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <path style="fill:#000;" d="M9.7,11h4.7c0.2,0,0.3,0.1,0.3,0.3l0,0c0,0.2-0.1,0.3-0.3,0.3H9.7c-0.2,0-0.3-0.1-0.3-0.3l0,0
       C9.3,11.1,9.5,11,9.7,11z"/>
     <rect x="9.6" y="10.7" style="fill:#000;" width="4.8" height="0.9"/>
     <rect x="10.1" y="10.7" style="fill:#000;" width="3.9" height="0.9"/>
   </g>
   <g>
     <path style="fill:#000;" d="M16.3,2.7H7.8c0.1-0.9,0.9-1.6,1.9-1.6h0v1h4.7v-1h0C15.4,1.1,16.1,1.8,16.3,2.7z"/>
     <path style="fill:${color}" d="M16.1,10.8H7.9V3c0-0.9,0.8-1.7,1.7-1.7h4.8c0.9,0,1.7,0.7,1.7,1.7V10.8z"/>
     <path style="fill:#000;" d="M15.7,7.2H8.3l0,0c0-1.6,1.3-2.8,2.9-2.8h1.5C14.3,4.3,15.7,5.6,15.7,7.2L15.7,7.2z"/>
     <path style="fill: #78909C;" d="M13.8,10.3h-3.6c-1,0-1.9-0.8-1.9-1.8l0,0C8.3,7.1,9.5,6,10.9,6h2.2c1.5,0,2.6,1.1,2.6,2.5l0,0
       C15.7,9.4,14.8,10.3,13.8,10.3z"/>
     <path style="fill:#000;" d="M14.4,1.4H9.6l0,0C9.6,1.2,9.8,1,10,1H14C14.2,1,14.4,1.1,14.4,1.4L14.4,1.4z"/>
     <g>
       <path style="fill:#000;" d="M8,5.7H7.2C7.1,5.7,7,5.8,7,5.9l0,0C7,6,7.1,6.1,7.2,6.1H8c0.1,0,0.2-0.1,0.2-0.2l0,0C8.2,5.8,8.1,5.7,8,5.7
         z"/>
     </g>
     <g>
       <path style="fill:#000;" d="M16,5.7h0.8c0.1,0,0.2,0.1,0.2,0.2l0,0c0,0.1-0.1,0.2-0.2,0.2H16c-0.1,0-0.2-0.1-0.2-0.2l0,0
         C15.9,5.8,15.9,5.7,16,5.7z"/>
     </g>
   </g>
   <path style="fill:${color}" d="M16.2,23H7.9c-0.2,0-0.4-0.2-0.4-0.4V8.1c0-0.2,0.2-0.4,0.4-0.4h8.4c0.2,0,0.4,0.2,0.4,0.4v14.4
     C16.6,22.8,16.4,23,16.2,23z"/>
 </g>
 </svg>`;
    case "offroad":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve" style="width:${width}; height:${height}">
      <style type="text/css">
        .st1{fill:#78909C;}
      </style>
      <g>
        <path style="fill:${color}" d="M20.3,10.9c0,0-0.7,0.2-0.2-0.7c0,0,0.4-2.1,5.1-2.7c0,0,6.9-0.6,10.7,0.3c0,0,3.6,0.5,4,2.9
          c0,0,0,0.3-0.3,0.3c0,0-0.1,0.2,0.2,0.7c0,0,0.1,3.9,0,5.9c0,0,0,1.5-1,3.1c-0.2,0.2-0.2,0.4-0.2,0.6c0.1,0.3,0.2,0.8,0.1,1.7
          c0,0.2,0.1,0.3,0.2,0.3s0.3,0,0.7,0c0,0,0.4,0,0.7,0.1c0,0,0.2-0.1,0.2,0.5c0,0,0.1,0.5-0.3,0.5c0,0-0.7,0.1-1.2,0
          c0,0-0.2-0.2-0.2,0.2v3.9c0,0,0.3-0.1,0.3,1c0,0,0.1,1.1-0.3,1.3l0.1,5.7c0,0,0.2,0.1,0.2,1c0,0,0.1,0.5-0.1,0.8
          c0,0,1.2,0.7,0.8,6.5v2.1c0,0,0.3-0.1,0.3,1.4c0,0,0.1,3-2.5,3h-1.7c0,0,0.3,1.5-0.7,1.5h-6.7c0,0-0.7,0.1-0.7-1.5c0,0,0,0.5,0-0.1
          h-5.9c0,0-2.1-0.1-2-3.4c0,0-0.1-0.9,0.2-1.1c0,0-0.1-5.1-0.1-6.1c0,0,0.2-1.4,0.7-2.4c0,0-0.1-1.8,0.2-1.8v-5.8
          c0,0-0.2,0.3-0.3-1.1c0,0,0-1.1,0.2-1.1v-4c0-0.2-0.2-0.2-0.2-0.1l0,0h-1.3c0,0-0.2,0-0.2-0.5c0,0-0.1-0.6,0.4-0.6h1.2
          c0,0,0.2,0.1,0.2,0.2c0,0.1,0.1,0,0.1-0.1c0-0.2,0-0.3,0-0.6c0,0,0-1.2,0.2-1.8c0,0-1.2-1.1-1.2-4.2C20.1,16.7,19.9,12.1,20.3,10.9
          z"/>
        <path d="M35.3,8.6c0,0,0.8-0.7,2.6,0.2C37.8,8.8,36.4,10.2,35.3,8.6z"/>
        <path d="M22.3,8.7c0,0,0.7-0.8,2.5-0.3C30.9,8.4,23.7,10.1,22.3,8.7z"/>
        <path d="M22.4,30.2c0,0-0.1,0.5,0.7,0.3c0,0,4.9-0.6,7.3-0.5c0,0,4,0,6.4,0.5c0,0,0.7,0.3,0.7-0.5c0,0,0.1-1.8,0.2-2.9
          c0,0-3.1-2.3-15.2-0.3C22.4,20.8,22,22.1,22.4,30.2z"/>
        <path d="M27.9,50.2c0,0-0.5,2.5,0.7,2.5h6.9c0,0,0.7,0.3,0.7-1.4v-1.2C36.2,50.2,32.4,49.8,27.9,50.2z"/>
        <path d="M37.4,31.8c0,0,0.1-0.3-0.7-0.2c0,0-4.9,0.3-7.3,0.3c0,0-4,0-6.4-0.3c0,0-0.7-0.2-0.7,0.3c0,0-0.1,0.8-0.2,1.3
          c0,0,3.1,1,15.2,0.2C37.4,33.4,37.8,32.7,37.4,31.8z"/>
        <path class="st1" d="M22.4,47.3c0,0-0.1,1.4,0.7,0.9c0,0,4.5-0.2,6.5-0.2c0,0,4.2,0,7.2,0.3c0,0,0.7,1,0.7-1.5
          c0,0-0.2-7.9-0.1-11.1c0,0-5.6,0.8-15,0C22.4,35.7,22,41,22.4,47.3z"/>
      </g>
      </svg>`;
    case "van":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <path style="fill:${color}" d="M16.1,21.2c0-1.3,0-15.1,0-15.5c0-0.8,0-2.2-0.3-2.7S15,1.2,14.4,1.1c-0.6-0.1-4.4-0.3-5,0
     C8.6,1.5,8.3,2.3,8.1,2.9C7.9,3.5,7.9,6.5,7.9,7.1c0,0.1,0,13.2,0,13.9L16.1,21.2z"/>
 </g>
 <path style="fill:#000;" d="M10,7.8c0,0-1.2-1.7-0.9-2c0.3-0.3,4.4-0.8,5.7,0C15,6,14.1,7.9,14.1,7.9L10,7.8L10,7.8z"/>
 <path style="fill:#000;" d="M9.7,8.7V7.9c0,0-0.9-1.6-1.1-1.4C8.4,6.8,8.4,7.9,8.4,8.6L9.7,8.7L9.7,8.7z"/>
 <path style="fill:#000;" d="M15.5,8.7c0-0.9-0.1-1.9-0.1-2.1c-0.1-0.2-0.2-0.4-0.4-0.3c-0.2,0-0.6,1.3-0.7,1.8c0,0.1,0,0.3,0,0.6H15.5z"/>
 <g>
   <path style="fill:${color}" d="M15.7,5v0.8l1.4,0.1c0-0.1,0-0.1,0-0.2C17.1,5.3,15.9,4.9,15.7,5z"/>
 </g>
 <g>
   <path style="fill:${color}" d="M8.3,5v0.8H6.9c0-0.1,0-0.1,0-0.2C6.9,5.3,8.1,4.9,8.3,5z"/>
 </g>
 <path style="fill:${color}" d="M7.9,21c0,0.2-0.1,0.2,0.1,0.5c0.3,0.3,0.8,1.3,1.4,1.4c0.6,0.1,4.5,0.1,5.1,0c0.8-0.2,1.2-0.8,1.4-1.3
   c0.1-0.1,0.1-0.2,0.1-0.5h-0.3C15.7,21,7.9,21,7.9,21z"/>
 <path style="fill:#000;" d="M8.4,20.1c0,0.2,0,0.4,0,0.5c0.1,0.5,0.8,1,1.4,1.2c0.5,0.2,3.6,0.2,4.2,0.1c0.6-0.1,1.1-0.6,1.4-1
   c0.2-0.2,0.1-0.5,0.1-0.8L8.4,20.1L8.4,20.1z"/>
 <path style="fill:#000;" d="M10.1,22.3c0,0.1-0.1,0.2-0.2,0.2H9.3c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h0.6
   C10.1,22.1,10.1,22.2,10.1,22.3L10.1,22.3z"/>
 <path style="fill:#000;" d="M14.8,22.3c0,0.1-0.1,0.2-0.2,0.2H14c-0.1,0-0.2-0.1-0.2-0.2l0,0c0-0.1,0.1-0.2,0.2-0.2h0.6
   C14.8,22.1,14.8,22.2,14.8,22.3L14.8,22.3z"/>
 </svg>`;
    case "arrow":
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 30 30" xml:space="preserve" style="width:${width}; height:${height}">
 <g>
   <g>
     <g>
       <polygon style="fill:${color}" points="12,1 18.9,14.3 12,11.3 5.1,14.3"/>
       <polygon style="fill:${color}" points="12,12.6 14.8,13.8 12,23 9.2,13.8"/>
     </g>
     <g>
       <polygon style="fill:${color}" points="12,11.3 5.1,14.3 12,1"/>
       <polygon style="fill:${color}" points="12,23 9.2,13.8 12,12.6"/>
     </g>
   </g>
 </g>
 </svg>`;
    default:
      return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve" style="width:${width}; height:${height}">
   <style type="text/css">
     .st0{display:none;}
     .st2{opacity:0.28;enable-background:new;}
     .st3{opacity:0.18;fill:url(#SVGID_1_);enable-background:new;}
     .st4{opacity:0.18;fill:url(#SVGID_2_);enable-background:new;}
     .st6{opacity:0.18;fill:url(#SVGID_3_);enable-background:new;}
     .st7{opacity:0.18;fill:url(#SVGID_4_);enable-background:new;}
   </style>
   <g>
     <g>
       <g>
         <polygon class="st0" style="fill:${color}" points="30,32.9 41.7,35.9 35.8,30.9 30,13.9 30.2,30.9 18.3,35.9 			"/>
         <polygon  style="fill:${color}" points="30.1,29.9 41.7,41.5 35.1,25.3 30,13.9 25.3,25.3 18.3,41.5 			"/>
         <polygon style="fill:${color}" class="st2" points="18.3,41.5 30.1,28.3 41.7,41.5 30.1,29.5 			"/>
         
           <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-284.0418" y1="1733.4528" x2="-272.3037" y2="1733.4528" gradientTransform="matrix(1 0 0 1 302.3848 -1705.7528)">
           <stop  offset="0" style="stop-color:#FFFFFF"/>
           <stop  offset="1" style="stop-color:#000000"/>
         </linearGradient>
         <polygon style="fill:${color}" class="st3" points="18.3,41.5 30.1,28.3 30,13.9"/>
           <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-272.3848" y1="1733.4528" x2="-260.7278" y2="1733.4528" gradientTransform="matrix(1 0 0 1 302.3848 -1705.7528)">
           <stop offset="0" style="stop-color:#FFFFFF"/>
           <stop offset="1" style="stop-color:#000000"/>
         </linearGradient>
         <polygon style="fill:${color}" class="st4" points="30,13.9 30.1,28.3 41.7,41.5 			"/>
         <polygon style="fill:${color}" points="30,26.1 36.2,32.7 32.3,25.2 30,20.4 27.8,25.2 23.8,32.7 			"/>
         <path style="fill:${color}" class="st2"  d="M33.3,31.6l7.9,9.1L40,38.5c0,0,0.1,0.5,0,0.5s-1.2-1.4-1.2-1.4L33.3,31.6z"/>
       </g>
     </g>
     <g>
       <g>
         <polygon class="st0" style="fill:${color}"points="30,42.8 41.7,43.9 35.8,39.7 30,35.4 30.2,39.7 18.3,43.9 			"/>
         <polygon style="fill:${color}" points="30.1,38.9 41.7,46.1 35.1,39.8 30,35.4 25.3,39.8 18.3,46.1 			"/>
         <polygon class="st2" style="fill:${color}" points="18.3,46.1 30.1,38.3 41.7,46.1 30.1,38.7 			"/>
         
           <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-284.0418" y1="1746.5028" x2="-272.3037" y2="1746.5028" gradientTransform="matrix(1 0 0 1 302.3848 -1705.7528)">
           <stop offset="0" style="stop-color:#FFFFFF"/>
           <stop offset="1" style="stop-color:#000000"/>
         </linearGradient>
         <polygon class="st6" style="fill:${color}" points="18.3,46.1 30.1,38.3 30,35.4"/>
           <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-272.3848" y1="1746.5028" x2="-260.7278" y2="1746.5028" gradientTransform="matrix(1 0 0 1 302.3848 -1705.7528)">
           <stop offset="0" style="stop-color:#FFFFFF"/>
           <stop offset="1" style="stop-color:#000000"/>
         </linearGradient>
         <polygon class="st7" style="fill:${color}" points="30,35.4 30.1,38.3 41.7,46.1 			"/>
         <polygon style="fill:${color}" points="30,37.5 37,42.2 32.9,38.8 30,36.3 27,39 23.3,42.1 			"/>
       </g>
     </g>
   </g>
   </svg>`;
  }
}
